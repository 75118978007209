import moment from "moment";
import React, { useCallback, useState } from "react";
import Input from "antd/lib/input";

interface Props {
  placeholder?: string;
  onChange?: (date: moment.Moment | null) => void;
  value?: moment.Moment | null;
  style?: React.CSSProperties;
}

export default function DateTextEdit({ placeholder, onChange, value, style }: Props): JSX.Element {
  const [text, setText] = useState(value?.toISOString().substring(0, 10) ?? "");
  const [lastValue, setLastValue] = useState(value?.toISOString().substring(0, 10) ?? "");

  const handleChange = useCallback(
    (changed: string | undefined) => {
      const newValue = getNewValue(lastValue, changed);
      setText(newValue);
      setLastValue(newValue);
    },
    [lastValue, setLastValue, setText]
  );

  return (
    <Input
      pattern="^[0-9]$"
      onBlur={() => {
        if (onChange == null) return;
        const date = moment(text, "DD.MM.YYYY");
        console.log({ valid: date.isValid(), date });
        onChange(date.isValid() ? date : null);
      }}
      style={{ ...style }}
      value={text}
      onChange={(evt) => handleChange(evt?.target?.value)}
      placeholder={placeholder != null ? `DD.MM.YYYY - ${placeholder}` : "TT.MM.JJJJ"}
    />
  );
}

function getNewValue(lastValue: string, changed?: string): string {
  if (changed == null) return lastValue;
  const tmp = getChangedTmp(changed, lastValue);
  const matches = tmp.replace(/[.]/g, "").match(/^[0-9]*$/);
  if (matches == null || matches.length === 0) return lastValue;
  const match = matches[0];
  if (match.length > 8) return lastValue;
  return formatNumbersToDate(match);
}

function formatNumbersToDate(numbers: string): string {
  if (numbers.length < 5) return numbers.replace(/(.{2})/g, "$1.");
  else return numbers.replace(/^(.{2})(.{2})(.+)/, "$1.$2.$3");
}

function getChangedTmp(changed: string, lastValue: string): string {
  if (lastValue.length > changed.length && lastValue.charAt(lastValue.length - 1) === ".") {
    return changed.substring(0, changed.length - 1);
  }
  return changed;
}
