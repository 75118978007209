import ITexts from "./ITexts";

const en: ITexts = {
  // generals
  invest_wizzard_general_error_message: "Something went wrong. Please try again later.",
  invest_wizzard_general_register_now: "REGISTER NOW",
  invest_wizzard_general_complete_data_first: "Please complete your profile first",
  invest_wizzard_general_invest_now: "INVEST NOW",
  invest_wizzard_general_continue_investing: "CONTINUE INVESTING",
  invest_wizzard_general_last_edited: "Last edited on",
  invest_wizzard_general_last_edited_at: "um",
  invest_wizzard_general_last_edited_clock: "Clock",

  // CardTitle
  invest_wizzard_card_title_investor_information: "Investor Information",
  invest_wizzard_card_title_contract: "Contract",
  invest_wizzard_card_title_investor_survey: "Adequacy Check",
  invest_wizzard_card_title_finalization: "Finalization",

  // errors
  invest_wizzard_errors_project_not_found: "Project not found.",
  invest_wizzard_errors_emission_not_open: "Emission not found.",
  invest_wizzard_errors_project_not_investable: "This project cannot be invested at this time.",
  invest_wizzard_errors_user_has_no_person: "User data has errors.",
  invest_wizzard_errors_person_data_is_unfinished: "User data is incomplete.",
  invest_wizzard_errors_natural_person_is_not_old_enough: "Investments are only possible from the age of 18.",
  invest_wizzard_errors_email_is_not_proven: "Please confirm your email address first.",
  invest_wizzard_errors_failed_to_create_a_prepayment_transaction: "Unfortunately, we cannot reach secupay at the moment. Please try again later.",
  invest_wizzard_errors_amount_does_not_fit_in: "Sorry, the investment amount you selected is no longer available for this project.",
  invest_wizzard_errors_other: "Something went wrong. Please try again later.",

  // Step2 Investor Information
  invest_wizzard_step2_alert_message: "We have sent you an email with investor information.",
  invest_wizzard_step2_alert_description: `
  Please read this information carefully and calmly and then continue with the investment process. <br />
  We recommend saving this information in a safe place where you can easily find it later. <br />
  By clicking on "Continue to the next step" you confirm that you will carefully study this information before
  you submit a binding drawing. <br />
`,
  invest_wizzard_step2_continue: "CONTINUE TO THE NEXT STEP",

  // Step3 Contract
  invest_wizzard_step3_investment_not_found_error_alert_message: "Error",
  invest_wizzard_step3_investment_not_found_error_alert_description: "The investment could not be loaded. Please refresh the page.",
  invest_wizzard_step3_already_invested_1: "You already have this year",
  invest_wizzard_step3_already_invested_2: "invested in projects from this issuer. You can still invest a maximum",
  invest_wizzard_step3_already_invested_3: "Invest.",
  invest_wizzard_step3_available_assets_intro:
    "Since you want to invest a total of more than EUR 1,000 in investments from this issuer, we are legally obliged to obtain the following confirmation from you:",
  invest_wizzard_step3_available_assets_hint: "This hint needs to be confirmed.",
  invest_wizzard_step3_available_assets_text_1: `
  I hereby confirm that I have EITHER <b>disposable assets</b> in the form of bank balances and financial instruments from
  have at least <b>100,000 euros</b> OR that the total amount I want to invest in investments from this issuer
  twice my average <b>monthly net income</b>
`,
  invest_wizzard_step3_available_assets_text_over10000_1: `
  I hereby certify that the total amount that I wish to invest in the investments of this issuer
  twice my average <b>monthly net income</b>
`,
  invest_wizzard_step3_available_assets_text_2: "does not exceed.",
  invest_wizzard_step3_alert_message: "Note according to Section 12 (2) of the Investment Act:",
  invest_wizzard_step3_alert_description:
    "The acquisition of this investment is associated with considerable risks and can lead to the complete loss of the invested capital.",
  invest_wizzard_step3_document_intro: "I have read and printed out and/or saved the following documents and agree to them",
  invest_wizzard_step3_document_hint: "This needs to be confirmed",
  invest_wizzard_step3_data_entry_intro_1:
    "By entering the following personal data, I confirm that I have taken note of the warning notice on the first page of the Capital Investment Information Sheet (shown above).",
  invest_wizzard_step3_data_entry_intro_2: `
  This process and the extent of the data that we must inquire about, as well as an <b>independent entry</b> of the profile data, are <b>required by law</b>.
`,
  invest_wizzard_step3_data_from_profile_load: "LOAD DATA FROM PROFILE",
  invest_wizzard_step3_data_from_profile: "** Profile data",
  invest_wizzard_step3_data_from_profile_hide: "HIDE",
  invest_wizzard_step3_legal_person_part_title: "Data relating to the company",
  invest_wizzard_step3_agent_part_title: "Data related to the authorized representative",
  invest_wizzard_step3_companyname_placeholder: "Company Name",
  invest_wizzard_step3_companyname_hint: "Company name mismatch.",
  invest_wizzard_step3_foundingdate_placeholder: "Founding Date",
  invest_wizzard_step3_foundingdate_hint: "Founding date mismatch.",
  invest_wizzard_step3_registerid_placeholder: "Register ID",
  invest_wizzard_step3_registerid_hint: "Register number does not match.",
  invest_wizzard_step3_court_placeholder: "Register",
  invest_wizzard_step3_court_hint: "Register mismatch.",
  invest_wizzard_step3_address_street_name_placeholder: "Street",
  invest_wizzard_step3_address_street_name_hint: "Street mismatch.",
  invest_wizzard_step3_address_street_number_placeholder: "House Number.",
  invest_wizzard_step3_address_street_number_hint: "House Number does not match.",
  invest_wizzard_step3_address_postal_code_placeholder: "Postal code",
  invest_wizzard_step3_address_postal_code_hint: "Postal code does not match.",
  invest_wizzard_step3_address_city_placeholder: "City",
  invest_wizzard_step3_address_city_hint: "City mismatch.",
  invest_wizzard_step3_companyName_placeholder: "Company Name",
  invest_wizzard_step3_companyName_hint: "Company name mismatch.",
  invest_wizzard_step3_email_placeholder: "Email",
  invest_wizzard_step3_email_hint: "Email address does not match.",
  invest_wizzard_step3_birthplace_placeholder: "Place of birth",
  invest_wizzard_step3_birthplace_hint: "Place of birth mismatch.",
  invest_wizzard_step3_birthday_placeholder: "Date of birth",
  invest_wizzard_step3_birthday_hint: "Date of birth mismatch.",
  invest_wizzard_step3_verification_document_number_placeholder: "Passport or identity card number",
  invest_wizzard_step3_verification_document_number_hint: "Passport or identity card number does not match.",
  invest_wizzard_step3_verification_issuing_agency_placeholder: "Issuing Agency",
  invest_wizzard_step3_verification_issuing_agency_hint: "Issuing agency mismatch.",
  invest_wizzard_step3_please_sign_here: "Please sign here",
  invest_wizzard_step3_first_name_placeholder: "First Name",
  invest_wizzard_step3_first_name_hint: "First name does not match.",
  invest_wizzard_step3_last_name_placeholder: "Last Name",
  invest_wizzard_step3_last_name_hint: "Last name mismatch.",
  invest_wizzard_step3_place_placeholder: "Place",
  invest_wizzard_step3_place_hint: "Current location is required.",
  invest_wizzard_step3_date_placeholder: "Date",
  invest_wizzard_step3_date_hint: "Please enter the current date to sign.",
  invest_wizzard_step3_continue: "GO TO THE NEXT STEP",
  invest_wizzard_step3_address_alert: "Please enter your address exactly as it appears in your profile",

  // Step4 Investor Survey
  invest_wizzard_step4_continue: "GO TO THE NEXT STEP",
  invest_wizzard_step4_intro: `
As an investment broker, we are <b>legally obliged</b> to provide information about your experience and knowledge in
in relation to dealings with certain types of financial assets and about your education and professional activities. the
Providing the following information is <b>voluntary</b> for you and is in your own interest.
`,
  invest_wizzard_step4_appropriate_hint: "Thank you for your information.",
  invest_wizzard_step4_appropriateness_not_assessable_hint: `
  We are <b>required by law</b> to tell you that we do not have <b>sufficient information</b> to
  to assess whether you have sufficient knowledge and experience to
  <b>Adequate assessment of business risks</b>.
`,
  invest_wizzard_step4_appropriateness_not_assessable_checkbox: "Notice noted.",
  invest_wizzard_step4_appropriateness_not_assessable_checkbox_hint: "Hint must be accepted to continue.",
  invest_wizzard_step4_inappropriate_hint: `
  We are <b>required by law</b> to inform you that, based on the knowledge and
  experience cannot adequately assess the intended transaction.
`,
  invest_wizzard_step4_inappropriate_checkbox: "Notice noted.",
  invest_wizzard_step4_inappropriate_checkbox_hint: "Hint must be accepted to continue.",
  invest_wizzard_step4_title: "Education and professional experience",
  invest_wizzard_step4_current_occupation: "Current occupation",
  invest_wizzard_step4_current_other_occupation_palceholder: "Current occupation",
  invest_wizzard_step4_previous_occupation: "Relevant Previous Occupation",
  invest_wizzard_step4_occupation_other: "- other occupation -",
  invest_wizzard_step4_occupation_asset_manager: "Asset Manager",
  invest_wizzard_step4_occupation_bank_employee: "Bank Employee",
  invest_wizzard_step4_occupation_finance_consultant: "Financial Advisor",
  invest_wizzard_step4_occupation_finance_broker: "Finance Broker",
  invest_wizzard_step4_occupation_finance_intermediary: "Financial Intermediary",
  invest_wizzard_step4_occupation_investment_banker: "Investment Banker",
  invest_wizzard_step4_occupation_head_of_controlling: "Head of Controlling Function",
  invest_wizzard_step4_occupation_head_of_finance: "Head of Finance",
  invest_wizzard_step4_occupation_tax_accountant: "Tax Accountant",
  invest_wizzard_step4_educational_degree: "Highest Educational Degree",
  invest_wizzard_step4_educational_degree_not_assigned: "- please select -",
  invest_wizzard_step4_educational_degree_none: "I don't have a degree (yet),",
  invest_wizzard_step4_educational_degree_abitur: "Abitur",
  invest_wizzard_step4_educational_degree_university: "University Degree",
  invest_wizzard_step4_educational_degree_doctor: "Doctor",
  invest_wizzard_step4_subordinatedloan: "Subordinated loan",
  invest_wizzard_step4_openfundsandetf: "Open Funds and ETFs",
  invest_wizzard_step4_bond: "Bonds",
  invest_wizzard_step4_share: "Shares",
  invest_wizzard_step4_closedfundsandparticipationright: "Closed Funds and Participation Rights",
  invest_wizzard_step4_equity: "Silent partnerships and real equity",
  invest_wizzard_step4_warrants: "Options and Warrants",
  invest_wizzard_step4_experience_period: "Options and Warrants",
  invest_wizzard_step4_experience_period_up_to_three_years: "up to 3 years",
  invest_wizzard_step4_experience_period_more_than_three_years: "more than 3 years",
  invest_wizzard_step4_experience_frequency_one_to_five_per_year: "1-5 per year",
  invest_wizzard_step4_experience_frequency_six_to_fifteen_per_year: "6-15 per year",
  invest_wizzard_step4_experience_frequency_more_than_fifteen_per_year: "> 15 per year",
  invest_wizzard_step4_experience_size_less_than_five_thousand: "< €5,000",
  invest_wizzard_step4_experience_size_five_to_ten_thousand: "€5,000 - €10,000",
  invest_wizzard_step4_experience_size_more_than_ten_thousand: "> €10,000",
  invest_wizzard_step4_experience_type_independent: "independent",
  invest_wizzard_step4_experience_type_investment_consultancy: "with investment advice",
  invest_wizzard_step4_experience_type_investment_management: "through asset management",

  // Step5 Finalization
  invest_wizzard_step5_title: "Overview",
  invest_wizzard_step5_amount: "Investment Amount",
  invest_wizzard_step5_quote: "Quote",
  invest_wizzard_step5_quote_placeholder: "Enter personal message...",
  invest_wizzard_step5_quote_publish: "Post Quote Public",
  invest_wizzard_step5_quote_anonymously: "Post anonymously",
  invest_wizzard_step5_invest_now_bindingly: "INVEST NOW BINDINGLY",
  invest_wizzard_step5_amount_does_not_fit_in_message: "Notice",
  invest_wizzard_step5_amount_does_not_fit_in_description: "Sorry, the investment amount you selected is no longer available for this project.",

  // Step6 transfer info
  invest_wizzard_step6_generic_error_alert: "Something went wrong",
  invest_wizzard_step6_success_message: "You have successfully completed the investment.",
  invest_wizzard_step6_intro: "Please make the following transfer",
  invest_wizzard_step6_owner: "Account holder",
  invest_wizzard_step6_iban: "IBAN",
  invest_wizzard_step6_bic: "BIC",
  invest_wizzard_step6_bankname: "Credit institution:",
  invest_wizzard_step6_purpose: "Reason for payment:",
  invest_wizzard_step6_amount: "Amount",
  invest_wizzard_step6_outro_sent_everything_by_mail: "We sent you everything again by email."
};

export default en;
