import ITexts from "./ITexts";

const en: ITexts = {
  project_funded_list_loading: "Loading projects...",
  project_funded_list_empty: "We couldn't load any funded projects.",
  project_funded_list_completed: "Funded",
  project_funded_list_paid: "Repaid",
  project_funded_list_insolvencyProceedings: "Default",
  project_funded_list_co2savings: "t CO₂ savings",
  project_funded_list_year: "Year",
  project_funded_list_years: "years",
  project_funded_list_contractDuration: "tenor",
  project_funded_list_interestRate: "return p.a.",
  project_funded_list_goto: "Go to project"
};

export default en;
