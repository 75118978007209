import gql from "graphql-tag";
import { apolloClient } from "utils";
import login from "./loginWithCredentials";
import trackConversion, { TrackingEvent } from "utils/adcell/trackConversion";

const generalDocumentTemplateSetVersionId = "023150be-4d0e-4a9d-b648-691b75dbaf7d"; // fixed until we use the GeneralDocumentVersioning

const mutation = gql`
  mutation REGISTRATION($createUserInput: CreateUserInput!, $email: String!, $password: String!) {
    beginTransaction
    createUser(input: $createUserInput, returnId: "$userId")

    createEmail(email: $email, returnId: "$emailCreationToken")
    createPassword(password: $password, returnId: "$passwordCreationToken")

    createUserAuthentication(
      userId: "$userId"
      authFactorCreationTokens: ["$passwordCreationToken", "$emailCreationToken"]
      loginProviderSets: ["email", "password"]
      modifyProviderSets: ["email", "password"]
      isActive: true
    )
    commitTransaction
  }
`;

export interface IRegistrationInput {
  tenantId: string;
  localeId: string;
  legalFormId: string;
  email: string;
  password: string;
  generalDocumentTemplateSetPayload: any;
  subscribedToNewsletter?: boolean;
  affiliateConditionId?: string;
  affiliateCampaign?: string;
  legalPersonId?: string;
}

export default async function register(input: IRegistrationInput): Promise<string> {
  const subscribedToNewsletter = input.subscribedToNewsletter === true;
  const email = input.email.toLowerCase();
  const { password, tenantId } = input;
  const createUserInput = getCreateUserInput(input);
  const variables = {
    createUserInput,
    email: input.email.toLowerCase(),
    password: input.password
  };
  const response = await apolloClient.mutate({
    mutation,
    variables
  });
  if (response.errors?.[0] != null) throw response.errors;
  const userId = response.data.createUser;
  trackConversion(TrackingEvent.register, userId);
  const loginSucceeded = await login({ tenantId, email, password });
  if (!loginSucceeded) throw new Error("Login Failed");
  if (subscribedToNewsletter === true) {
    try {
      await apolloClient.mutate({
        mutation: gql`
          mutation {
            subscribeMeToNewsletter
          }
        `
      });
    } catch (error) {
      console.log("subscribeMeToNewsletter failed", error);
    }
  }
  return userId;
}

export interface ICreateUserInput {
  localeId: string;
  tenantId: string;
  legalFormId: string;
  generalDocumentTemplateSetVersionId?: string;
  generalDocumentTemplateSetPayload: any;
  affiliateConditionId?: string;
  affiliateCampaign?: string;
  legalPersonId?: string;
}

function getCreateUserInput(registrationInput: IRegistrationInput): ICreateUserInput {
  return {
    localeId: registrationInput.localeId,
    tenantId: registrationInput.tenantId,
    legalFormId: registrationInput.legalFormId,
    generalDocumentTemplateSetVersionId,
    generalDocumentTemplateSetPayload: registrationInput.generalDocumentTemplateSetPayload,
    affiliateConditionId: registrationInput.affiliateConditionId,
    affiliateCampaign: registrationInput.affiliateCampaign,
    legalPersonId: registrationInput.legalPersonId
  };
}

export async function createLegalPerson(name: string, legalFormId: string): Promise<any> {
  const legalPersonId = (await apolloClient.mutate({ mutation: CREATE_LEGAL_PERSON, variables: { name }, fetchPolicy: "no-cache" }))?.data
    .createLegalPerson;
  if (legalPersonId == null) throw new Error("legalPersonId is NULL");

  await apolloClient.mutate({
    mutation: CREATE_LEGAL_PERSON_EVENT,
    variables: { event: { type: "finalize", legalPersonId }, legalPersonId },
    fetchPolicy: "no-cache"
  });

  return legalPersonId ?? undefined;
}

const CREATE_LEGAL_PERSON = gql`
  mutation createLegalPerson($name: String!) {
    createLegalPerson(name: $name)
  }
`;

const CREATE_LEGAL_PERSON_EVENT = gql`
  mutation createLegalPersonEvent($event: JSON!, $legalPersonId: ID!) {
    createLegalPersonEvent(input: { state: finished, changed: true, event: $event, legalPersonId: $legalPersonId })
  }
`;
