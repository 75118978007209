import tenantId from "constants/tenantId";
import gql from "graphql-tag";
import { apolloClient } from "utils";
import { UserInfo, User } from "./types";

export async function getUserInfo(): Promise<UserInfo | null> {
  try {
    const response = await apolloClient.query({ query: QUERY, fetchPolicy: "no-cache" });
    if (response.errors != null || response.data == null || response.data.users == null || response.data.users[0] == null) return null;
    const gqlUser = response.data.users[0];
    const currentUser: User = {
      id: gqlUser.id,
      email: gqlUser.email,
      name: gqlUser.name,
      hasProofedEmail: gqlUser.hasProofedEmail,
      legalPerson: gqlUser.legalPerson,
      naturalPerson: gqlUser.naturalPerson
    };

    const principalUsers: User[] = gqlUser.agentAuthorizedRepresents.map(({ principalUser: gqlPrincipalUser }) => ({
      id: gqlPrincipalUser.id,
      name: gqlPrincipalUser.name,
      legalPerson: gqlPrincipalUser.legalPerson,
      naturalPerson: gqlPrincipalUser.naturalPerson
    }));
    return { currentUser, principalUsers };
  } catch (error) {
    return null;
  }
}

const QUERY = gql`
  query {
    users(where: { _Owner_id: { is: TRUE } }) {
      id
      name
      email
      hasProofedEmail
      naturalPerson {
        id
        state
        firstName
        lastName
      }
      legalPerson {
        id
        state
        name
      }
      agentAuthorizedRepresents {
        principalUser {
          id
          name
          naturalPerson {
            id
            state
            firstName
            lastName
          }
          legalPerson {
            id
            state
            name
          }
        }
      }
    }
  }
`;

const mutation = gql`
  mutation LOGIN($tenantId: String!, $email: String!, $password: String!) {
    beginTransaction
    getUserIdentifier(username: $email, tenant: $tenantId, returnId: "$userIdentifier")
    proofPassword(userIdentifier: "$userIdentifier", password: $password, returnId: "$passwordProofToken")
    login(authFactorProofTokens: "$passwordProofToken") {
      refreshToken
      accessToken
      tokenMeta {
        userId
        issuedAt
        expiresAt
        providerSet
      }
    }
    commitTransaction
  }
`;

export async function login(email: string, password: string): Promise<boolean> {
  const response = await apolloClient.mutate({
    mutation,
    variables: {
      tenantId,
      email: email.toLowerCase(),
      password
    },
    fetchPolicy: "no-cache"
  });
  if (response.errors) throw response.errors;
  return response.data.login !== null;
}
