import React, { useState, useContext, useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import Alert from "antd/lib/alert";
import { ColProps } from "antd/lib/col";
import Form, { FormInstance } from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import Title from "antd/lib/typography/Title";
import CountryAutoComplete from "components/input/CountryAutoComplete";
import phonePrefixes from "constants/phonePrefixes";
import { intlContext } from "translations/context";
import { IFormValuesAddress } from "../../types/IFormValues";
import NaturalPerson from "../../types/NaturalPerson";

interface IProps {
  form: FormInstance<IFormValuesAddress>;
  myPerson: NaturalPerson | undefined;
}

const cols: { labelCol: ColProps; wrapperCol: ColProps } = {
  labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 }, xxl: { span: 4 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 12 }, xxl: { span: 10 } }
};

export default function StepAddress({ form, myPerson }: IProps): JSX.Element {
  const [hintVisibility, setHintVisibility] = useState<boolean>(false);
  const { translate } = useContext(intlContext);

  const { setFieldsValue } = form;

  useEffect(() => {
    if (myPerson != null) {
      const fields = mapPersonToFields(myPerson);
      setFieldsValue(fields);
    }
  }, [myPerson, setFieldsValue]);

  return (
    <Form name="DataCompletionMyNaturalPersonAddress" form={form} labelAlign="left" layout="horizontal" style={{ marginBottom: 0 }}>
      <Title level={3}>{translate("data_completion_address_title")}</Title>
      <Form.Item
        name="addrCountryCode"
        rules={[{ required: true, message: translate("data_completion_address_country_hint") }]}
        label={translate("data_completion_address_country_label")}
        style={{ marginBottom: 6 }}
        {...cols}
      >
        <CountryAutoComplete excludeSomeCountries={true} placeholder={translate("data_completion_address_country_placeholder")} />
      </Form.Item>
      <Form.Item
        name="addrCity"
        rules={[{ required: true, message: translate("data_completion_address_city_hint"), whitespace: true }]}
        label={translate("data_completion_address_city_label")}
        style={{ marginBottom: 6 }}
        {...cols}
      >
        <Input placeholder={translate("data_completion_address_city_placeholder")} />
      </Form.Item>
      <Form.Item
        name="addrPostalCode"
        rules={[{ required: true, message: translate("data_completion_address_postalCode_hint"), whitespace: true }]}
        label={translate("data_completion_address_postalCode_label")}
        style={{ marginBottom: 6 }}
        {...cols}
      >
        <Input placeholder={translate("data_completion_address_postalCode_placeholder")} />
      </Form.Item>
      <Form.Item
        name="addrStreetName"
        rules={[{ required: true, message: translate("data_completion_address_streetName_hint"), whitespace: true }]}
        label={translate("data_completion_address_streetName_label")}
        style={{ marginBottom: 6 }}
        {...cols}
      >
        <Input placeholder={translate("data_completion_address_streetName_placeholder")} />
      </Form.Item>
      <Form.Item
        name="addrStreetNumber"
        rules={[{ required: true, message: translate("data_completion_address_streetNumber_hint"), whitespace: true }]}
        label={translate("data_completion_address_streetNumber_label")}
        style={{ marginBottom: 6 }}
        {...cols}
      >
        <Input placeholder={translate("data_completion_address_streetNumber_placeholder")} />
      </Form.Item>
      <Form.Item name="addrAddition" label={translate("data_completion_address_addition_label")} style={{ marginBottom: 12 }} {...cols}>
        <Input placeholder={translate("data_completion_address_addition_placeholder")} />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[{ required: true, message: translate("data_completion_address_phoneNumber_hint"), pattern: /^\d{3}\d*$/ }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_address_phoneNumber_label")}
        {...cols}
      >
        <Input
          addonBefore={<PhonePrefixSelector form={form} />}
          placeholder={translate("data_completion_address_phoneNumber_placeholder")}
          suffix={<InfoCircleOutlined onClick={() => setHintVisibility(!hintVisibility)} />}
        />
      </Form.Item>
      {hintVisibility && (
        <Alert
          type="info"
          message={translate("data_completion_address_phoneNumber_alert_message")}
          description={translate("data_completion_address_phoneNumber_alert_description")}
        />
      )}
    </Form>
  );
}

function PhonePrefixSelector(props: { form: FormInstance }): JSX.Element {
  return (
    <Form.Item name="phoneNumberPrefix" initialValue="+49">
      <Select style={{ width: 80 }} dropdownStyle={{ width: 200 }}>
        {phonePrefixes.map((prefix, index) => (
          <Select.Option value={prefix} key={index}>
            {prefix}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

function mapPersonToFields(myPerson: NaturalPerson): IFormValuesAddress {
  return {
    addrCountryCode: myPerson.addrCountryCode,
    addrCountry: myPerson.addrCountry,
    addrCity: myPerson.addrCity,
    addrPostalCode: myPerson.addrPostalCode,
    addrStreetName: myPerson.addrStreetName,
    addrStreetNumber: myPerson.addrStreetNumber,
    addrAddition: myPerson.addrAddition,
    phoneNumberPrefix: myPerson.phoneNumberPrefix || "+49",
    phoneNumber: myPerson.phoneNumber
  };
}
