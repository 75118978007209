import ITexts from "./ITexts";

const en: ITexts = {
  // General
  investment_continue_investment: "Continue",
  investment_default_title: "Invest",
  investment_abort_button: "Cancel investment",
  investment_required_field: "*required field",
  investment_warning: "Warning",
  investment_info: "Note",
  investment_error: "Error",
  investment_error_description: `
    There has been an error. Please contact our support at <a href="mailto:mail@bettervest.com">mail@bettervest.com</a> with the following error message:
  `,
  investment_unknown_error: "Unknown error",
  investment_languague_label: "Language",

  // 1_CreateInvestment
  investment_1_invest_now_disabled: "Sign up and invest",
  investment_1_invest_now_1: "Invest from ",
  investment_1_invest_now_2: "",
  investment_1_hint_only_logged_in: "Only logged in users can invest.",
  investment_1_hint_only_natural_person: "Currently only natural persons can invest in this project.",
  investment_1_hint_profile_incomplete: "Please complete your user profile first to be able to invest.",
  investment_1_hint_unconfirmed_email: "Please confirm your email address first to be able to invest.",
  investment_1_hint_project_not_found: "Error: The project could not be found.",
  investment_1_hint_project_not_invest: "Investing in this project is currently not possible.",
  investment_1_hint_before_start: "The period to invest in this project has not started yet.",
  investment_1_hint_after_end: "The period to invest in this project has ended.",
  investment_1_hint_project_full: "The project is already fully funded.",

  // 2_AcceptInvestorInformation
  investment_2_title: "Pre-contractual information sent by email",
  investment_2_alert_description: `
    <br />
    We have sent you an e-mail with the information related to the offer and the information regarding our role as an intermediary.<br />
    Please review these documents carefully, including the risk factors contained therein, and only then continue with the investment process.<br />
    Please save the files in a place where you can easily access them later.
  `,
  investment_2_checkbox: "I confirm that I have received and saved the documents.",
  investment_2_button: "Next",

  // 3_ApproveUpToDatanessOfPersonalData
  investment_3_title: "Money Laundering Act Identification",
  investment_3_preview_intro: "Your current personal information is required for your investment:",
  investment_3_preview_call_to_action: "Is the data still current and correct?",
  investment_3_preview_change_profile_button: "No, correct data",
  investment_3_preview_continue_button: "Yes, current & correct",
  investment_3_edit_title: "Change personal information",
  investment_3_edit_alert_message: "Please note the following",
  investment_3_edit_alert_description: "Changing your last name or address will result in you being identified again.",
  investment_3_edit_back_without_save_button: "Back",
  investment_3_edit_save_and_back_button: "Save",
  investment_3_firstName: "First Name",
  investment_3_lastName: "Last name",
  investment_3_country: "Country",
  investment_3_city: "City",
  investment_3_postalCode: "Postal Code",
  investment_3_streetName: "Street",
  investment_3_streetNumber: "Street Number",
  investment_3_addition: "Addition",
  investment_3_address: "Address",
  investment_3_companyDataTitle: "Company Data:",
  investment_3_companyName: "company name",
  investment_3_legalForm: "Legal Form",
  investment_3_registerId: "Register number",
  investment_3_court: "Registrar",
  investment_3_legalAddress: "Business Address",

  // 4_SubmitAmount / 4_SignVIB
  investment_4_amount_title: "Investment Amount",
  investment_4_vib_title: "Capital Investment Information Sheet",
  investment_4_already_submitted_amount_1: "You already have",
  investment_4_already_submitted_amount_2: " Invested in this project.",
  investment_4_accured_interest_start_date: "This investment will accrue interest from the",
  investment_4_accured_interest_amount: "Total amount including accrued interest",
  investment_4_accured_interest_alert_message: "Alert",
  investment_4_accured_interest_alert_description: `This security will accrue interest from the specified date. Since the interest amount for the entire period covered will be paid out on the next interest payment date, regardless of when the investment was made, you as an investor must pay the accrued interest when making the purchase.`,
  investment_4_available_assets_intro:
    "Since you want to invest a total of more than EUR 1,000 in investments from this issuer, we are legally obliged to obtain the following confirmation from you:",
  investment_4_available_assets_hint: "This hint needs to be confirmed.",
  investment_4_available_assets_text: `
    I hereby confirm that I have EITHER <b>disposable assets</b> in the form of bank balances and financial instruments from
    have at least <b>100,000 euros</b> OR that the total amount I want to invest in investments from this issuer
    does not exceed twice my average <b>monthly net income</b>.
  `,
  investment_4_available_assets_text_legalPerson: `
  I hereby confirm that the company I represent has EITHER <b>disposable assets</b> in the form of bank balances and financial instruments from
  at least <b>100,000 euros</b> OR that the total amount that I wish to invest in the assets of this issuer on behalf of
  does not exceed twice the average <b>monthly net income</b> of the company I represent.
`,
  investment_4_available_assets_text_over10000: `
    I hereby certify that the total amount that I wish to invest in the assets of this issuer on behalf of
    does not exceed twice the average <b>monthly net income</b> of the company I represent.
  `,
  investment_4_available_assets_text_over10000_legalPerson: `
  I hereby certify that the total amount that I wish to invest in the investments of this issuer
  does not exceed twice my average <b>monthly net income</b>.
`,
  investment_4_vib_document_template: "Investment Fact Sheet",
  investment_4_continue: "Continue",
  investment_4_amount_confirm: "Confirm Amount",
  investment_4_amount_edit: "Edit amount",
  investment_4_vib_change_label:
    "Your data is no longer up to date because you have moved or have a new ID card? Change your data by entering the new data here. The changes will be automatically applied to your profile.",
  investment_4_amount_label:
    "Please enter the desired investment amount here. You can invest from 50 euros and the amount must be divisible by 50 (e.g. 100 euros, 1.550 euros, 4.050 euros). Please confirm your entry by clicking on the 'Confirm amount' button. .",
  investment_4_amount_confirm_betrag: "Confirm amount and continue",
  investment_4_information_sent_label:
    "We have sent you an e-mail with the information related to the offer and the information regarding our role as an intermediary. Please review these documents carefully, including the risk factors contained therein, and only then continue with the investment process.",

  // eigencrowd especial case
  investment_eigencrowd:
    "Here please split your original investment amount from the self-crowd funding “Become a part of bettervest” between the three options. Please split the full amount - if you give less, you cannot confirm the amount. If you choose an option do not want to select, please enter “0”. Please confirm your entry by clicking on the 'Confirm amount' button.",

  // anheile
  investment_4_secured_loan: `Self-disclosure: If the total amount of securities acquired by me (including any previous investments in the securities offered here) exceeds EUR 1,000, I hereby confirm that at least one of the following two statements a) or b) applies to me:
  <br> a) I have freely disposable assets in the form of bank balances and financial instruments of at least EUR 100,000 AND the total amount of securities acquired by me does not exceed EUR 10,000.
  <br> b) The total amount of securities acquired by me is not more than twice my average monthly net income, but not more than 25,000 EUR.`,

  // 5_SubmitInvestorSurvey
  investment_5_survey_title: "Adequacy test",
  investment_5_survey_intro: `
    We are required by law to request certain criteria.<br />
    <br />
    Prior to an brokering an investment, we are obliged to obtain information from the customer about knowledge and experience in relation to transactions with certain types of financial assets or securities services.
  `,
  investment_5_survey_profession: "Occupation / Job",
  investment_5_investment_experience_title:
    "Please tick each investment where you have some knowledge. How long have you had experience with each investment product?",
  investment_5_service_experience_title:
    "How do you typically acquire financial instruments and how much experience do you have with them (in years)?",
  investment_5_avg_transaction_title: "Number and Volume Experiences",
  investment_5_knowledge_yes: "I have some knowledge",
  investment_5_knowledge_no: "No knowledge",
  investment_5_experience_none: "No experience",
  investment_5_experience_lower_3: "experience up to 3 years",
  investment_5_experience_3_to_5: "experience 3 to 5 years",
  investment_5_experience_more_than_5: "experience over 5 years",
  investment_5_type_bond_label: "Bonds",
  investment_5_type_emerging_market_bonds_label: "Emerging Market Bonds",
  investment_5_type_share_label: "Stocks",
  investment_5_type_participation_in_a_limited_partnership_label: "Participation in limited partnerships",
  investment_5_type_participation_right_label: "Profit participation rights",
  investment_5_type_unsecured_junior_debt_label: "Subordinated loan",
  investment_5_service_independent_label: "Independently / no advisor",
  investment_5_service_consultancy_label: "Through an investment advisor",
  investment_5_avg_yearly_transactions_label: "Average number of transactions per year",
  investment_5_avg_yearly_transactions_none: "none",
  investment_5_avg_yearly_transactions_lower_5: "up to 5",
  investment_5_avg_yearly_transactions_bet_5_to_10: "up to 10",
  investment_5_avg_yearly_transactions_more_than_10: "more than 10",
  investment_5_avg_transaction_value_label: "Average value per transaction",
  investment_5_avg_transaction_value_lower_3000: "up to EUR 3,000",
  investment_5_avg_transaction_value_bet_3000_to_5000: "3,000 - 5,000 EUR",
  investment_5_avg_transaction_value_bet_5000_to_10000: "5,000 - 10,000 EUR",
  investment_5_avg_transaction_value_over_10000: "over 10,000 EUR",
  investment_5_inappropriate_title: "Appropriateness Test - Inappropriate",
  investment_5_inappropriate_intro: `
    The evaluation of the information you provided about previous knowledge and experience with financial instruments showed that the product offered here or the amount of your subscription does not correspond to the investment profile created on the basis of your information. According to the information you have given, you do not have enough knowledge and experience to be able to adequately assess the risks associated with the purchase of the product offered here, i.e. your subscription does not correspond to your previous investment profile.<br />
    <br />
    Please review the risks associated with subscribing.<br />
    A detailed description of the risks associated with the subscription can be found in the risk information that was sent to you by e-mail in the preliminary information.<br />
    <br />
    If you decide to draw the product anyway, we ask you to legally confirm this:
  `,
  investment_5_inappropriate_checkbox: `
    I have taken note of the information that the subscription to the capital investment does not correspond to my investment profile based on the knowledge and previous experience with financial instruments that I have stated. <b>Nevertheless, I decide to subscribe to the financial instrument offered.</b>
  `,
  investment_5_not_specified_title: "Adequacy test – response missing/not specified",
  investment_5_not_specified_intro: `
    Dear investor,<br />
    <br />
    The appropriateness is assessed according to whether you as an investor have the necessary knowledge and experience to be able to adequately assess the risks associated with the type of financial instruments or investment services.<br />
    <br />
    You have provided incomplete information in the answers you have given. We therefore reiterate our warning that it is not possible to provide an adequacy review where information is missing or incomplete. Only you can determine the appropriateness of the financial instruments. In particular, you should familiarise yourself with the risks and conditions of the financial instruments.
  `,
  investment_5_not_specified_checkbox: `
  I have taken note of the information that it is not possible to assess whether the intended transaction is appropriate for me according to my knowledge and experience. I nevertheless agree to subscribe to the offered financial instrument.
  `,
  investment_5_continue_without_data: "Continue without data",
  investment_5_continue: "Continue",
  investment_5_answer: "Provide data",

  // 6_SubmitIdentificationPreInfos
  investment_6_title: "Money Laundering Act Identification",
  investment_6_benficial_owner: "I confirm that I am both the contracting party and the <b>beneficial owner</b> of this transaction.",
  investment_6_political_exposed:
    "I confirm that I do not hold an important public office as <b>a so-called politically exposed person and that I am not, for example, a close family member of apolitically exposed person. </b>",
  investment_6_continue: "Confirm",
  investment_6_after_hint: `
    Are you neither the beneficial owner and/or are you a PEP or are you close to one? <br />
    Contact us: <a href="mailto:mail@bettervest.com">mail@bettervest.com</a>
  `,
  investment_6_after_hint_legalPerson: `
    Are you neither the beneficial owner and/or are you a PEP or are you close to one?<br />
    Contact us: <a href="mailto:mail@bettervest.com">mail@bettervest.com</a>
  `,

  // 7_RecheckForValidIdentification
  investment_7_title: "Money Laundering Act Identification",
  investment_7_text: `
    <b>Note</b><br />
    <br />
    Due to legal requirements, we are obliged to verify your identity before an investment is possible.<br />
    <br />
    <b>Online identification (duration approx. 10 minutes):</b><br />
    <br />
    By clicking on "Start identification now" you will be forwarded to the Deutsche Post website and go through an "online identification" process.<br />
    <br />
    Please have the following documents ready: passport or identity card
  `,
  investment_7_continue: "Start identification now",

  // 8_BankAccount
  investment_8_bank_title: "Account Information",
  investment_8_bank_save_and_continue_button: "Save and continue",
  investment_8_bank_edit_button: "Edit account data",
  investment_8_bank_continue_button: "Continue",
  investment_8_bank_preview_intro: "Your current account details:",
  investment_8_bank_preview_intro_legal: "The current account details of the company you represent:",
  investment_8_bank_preview_no_data: "No account data available.",
  investment_8_bank_edit_intro: "Please enter exactly as on file with your bank:",
  investment_8_bank_owner: "Account owner",
  investment_8_bank_owner_error: "At least three characters",
  investment_8_bank_iban: "IBAN",
  investment_8_bank_iban_error: "IBAN is invalid",
  investment_8_bank_bic: "BIC",
  investment_8_bank_bic_error: "BIC is invalid",
  investment_8_bank_bankname: "Bank",
  investment_8_bank_bankname_error: "At least three characters",

  // 8_Deposit
  investment_8_deposit_title: "Deposit Information",
  investment_8_deposit_intro: "As soon as you make an investment in a security, a deposit is required for the booking.",
  investment_8_deposit_save_and_continue_button: "Save and continue",
  investment_8_deposit_edit_button: "Edit deposit details",
  investment_8_deposit_continue_button: "Continue",
  investment_8_deposit_preview_intro: "Your current deposit data:",
  investment_8_deposit_preview_intro_legal: "The currently stored deposit data of the company you represent:",
  investment_8_deposit_preview_no_data: "No deposit data available.",
  investment_8_deposit_edit_intro: "Please enter exactly as deposited with your custodian bank:",
  investment_8_deposit_owner: "Deposit holder full name",
  investment_8_deposit_number: "Deposit Number",
  investment_8_deposit_bankCode: "BLZ (not BIC!)",
  investment_8_deposit_alert_message: "Alert",
  investment_8_deposit_alert_description: `
    You can also inquire at your house bank, please note here that deposits at the following banks unfortunately cannot be used for an investment in a security on bettervest.com:
    <br />
    Augsburger Aktienbank, Umweltbank, Frankfurter Fondsbank (FIL Fondsbank), Fondsdepotbank, Dekabank, Union Investment, Trade Republic
  `,

  // 9_Costs
  investment_9_title: "Commissions, Costs and Fees Information",
  investment_9_alert_description: "There are no costs for you as an investor. All costs are incurred by the respective issuer",
  investment_9_continue: "Continue",

  // 10_SigningDeclaration
  investment_10_title: "Subscription Statement",
  investment_10_amount_text_1: "must be at least",
  investment_10_amount_text_2: " amount and by ",
  investment_10_amount_text_3: " be divisible",
  investment_10_sub_coupon_validating: "Validating the coupon...",
  investment_10_sub_coupon_isValid: "Coupon Value:",
  investment_10_sub_coupon_isInvalid: "The coupon code is invalid.",
  investment_10_sub_coupon: "Coupon Code",
  investment_10_sub_coupon_placeholder: "optional",
  investment_10_transferInfo_owner: "Account holder",
  investment_10_transferInfo_iban: "IBAN",
  investment_10_transferInfo_bic: "BIC",
  investment_10_transferInfo_bankname: "Credit institution",
  investment_10_transferInfo_purpose: "Reason for payment",
  investment_10_document_title: "Acknowledgement of receipt",
  investment_10_submit_button_1: "Submit declaration of subscription",
  investment_10_submit_button_2: "payable",
  // -- Participation rights
  investment_10_sub_genuss_text_1:
    "As an investor, I hereby offer the person giving the participation right to conclude a <b>participation right contract</b> with the following <b>investment amount</b>.",
  investment_10_sub_genuss_amount: "Investment Amount",
  investment_10_sub_genuss_text_2:
    "The granting of the participation right is based on the participation right contract „<b>Genussrecht_bettervest Eigencrowd Variant A</b>“",
  investment_10_sub_genuss_text_4: `
    <br />
    My offer is legally binding. The contract is only concluded when the contract offeror has accepted the contract. I have taken note that the participatory rights giver to accept the
    subscription offers is not obligated. A reason for a refusal is not required.
    <br /><br />
    After submitting the drawing, I receive an email requesting payment. I will pay the participation right amount into the account specified there within three working days after receipt of the payment request.
    <br/>
    I have taken note that the conclusion of the contract is subject to the condition subsequent that my payment is not received in the account within two weeks of receipt of the payment request (this condition does not apply if the amount is already in the escrow account due to offsetting with the project
    "Become part of bettervest"). <br/ > <br/ >
    <b>
    Consent under data protection law: As an investor, I hereby grant the following consent under data protection law for the purpose of maintaining the investor register and subscribing and administering the participation right:
    <br /> <br />
    My personal data provided in the electronic subscription process and/or in this subscription form may be used by the platform operator bettervest GmbH, Frankfurt am Main, the liability umbrella Effecta GmbH, Florstadt, the borrower and any third party service providers who are contractually
    are involved in the processing of the contractual relationship, stored, processed and used to the extent necessary for the processing of the subscription/investment brokerage and the loan-contractual relationship.
    <br /> <br />
    My data, which bettervest GmbH has collected on a contractual or legal basis in accordance with the above provisions, may continue to be stored, processed and used by bettervest GmbH in accordance with the above provisions until further notice if bettervest GmbH does not more than contractual
    tied agent of Effecta GmbH should be active.
    <br /> <br />
    The data will not be passed on to third parties for advertising purposes. Data processing takes place in compliance with the relevant data protection laws. The personal data will be deleted as soon as further storage is no longer necessary. I will be given information about the stored data and its disclosure on request.
    </b>
    `,
  investment_10_sub_genuss_outro: `
    I am aware that the participation rights offered are entrepreneurial financing, which has the risks described in the risk information. In principle, a loss of my entire invested capital cannot be ruled out.<br />
    <br />
    I am aware that the issuer of the profit participation rights can only accept my subscription offer if I have previously identified myself properly in accordance with the Money Laundering Act.
  `,
  // -- Subordinated Loan Specific
  investment_10_sub_text_1:
    "As an investor, I hereby offer to enter into a qualified subordinated loan agreement with a pre-insolvency enforcement restriction and subsequent loan amount.",
  investment_10_sub_amount: "Loan Amount",
  investment_10_sub_text_2:
    "The granting of the subordinated loan is based on the loan terms and conditions „<b>Subordinated Loan for the Energy Transition</b> - Subordinated Loan with Qualified Subordination and Pre-Insolvency Enforcement Restriction“",
  investment_10_sub_text_3: `
    My offer is legally binding. The contract shall only be concluded upon acceptance of the contract offering by the Borrower. I acknowledge that the Borrower is not obligated to accept the subscription offerings. A reason for non-acceptance is not required.<br />
    <br />
    After submitting the subscription, I will receive a payment request by e-mail. I agree to deposit the loan amount into the account stated therein within three working days of receipt of the payment request.
  `,
  investment_10_sub_text_4: `
    I have noted that the conclusion of the contract is subject to the condition that my payment has not been received in the account within two weeks of receipt of the payment request.<br />
    <br />
    <b>
    Data protection consent: As an investor, I hereby give the following consent under the data protection law for the purpose of maintaining the investor register and subscribing to and managing the subordinated loan:<br/>
      <br />
      My personal data provided in the electronic subscription process and/or in this subscription form may be stored, processed and used by the platform operator bettervest GmbH, Frankfurt am Main, the liability umbrella Effecta GmbH, Florstadt, the Borrower and any third-party service providers who are contractually involved in administering the contractual relationship, to the extent necessary for the processing of the subscription/investment brokerage and the loan contractual relationship.
      <br />
      <br />
      My data, which bettervest GmbH has collected on a contractual or legal basis in accordance with the above provisions, may continue to be stored, processed, and used by bettervest GmbH in accordance with the above provisions until revoked, even if bettervest GmbH should no longer act as a contractually bound agent of Effecta GmbH.<br />
      <br />
      The data will not be passed on to third parties for advertising purposes. Data processing is carried out in compliance with the relevant data protection laws. Personal data will be deleted when further retention is no longer required. I will be informed about the stored data and its disclosure upon request.
    </b>
  `,
  investment_10_sub_text_4_legal: `
    I have taken note that the conclusion of the contract is subject to the condition subsequent that my payment has not been credited to the account within two weeks of receipt of the payment request.<br />
    <br />
    <b>
      Consent under data protection law: As an investor, I hereby grant the following consent under data protection law for the purpose of maintaining the investor register and subscribing to and managing the subordinated loan:<br/>
      <br />
      My personal and company-related data provided in the electronic subscription process and/or in this subscription form may be processed by the platform operator bettervest GmbH, Frankfurt am Main, the liability umbrella Effecta GmbH, Florstadt, the borrower and any third party service providers who are contractually involved in the processing of the contractual relationship. stored, processed and used to the extent necessary to process the subscription/investment brokerage and the contractual loan relationship.<br />
      <br />
      The data will not be passed on to third parties for advertising purposes. Data processing takes place in compliance with the relevant data protection laws. The personal and company-related data will be deleted as soon as further storage is no longer necessary. I will be given information about the stored data and its disclosure on request.
    </b>
  `,
  investment_10_sub_outro: `
    I am aware that the subordinated loans offered relate to a corporate financing that involves the risks as described in the risk disclosures. In general, the loss of my entire invested capital cannot be ruled out.<br />
    <br />
    I am aware that the borrower can only accept my subscription offer if I have previously properly identified myself in accordance with the Money Laundering Act.
  `,
  investment_10_sub_outro_legal: `
    I am aware that the subordinated loans offered relate to a corporate financing that involves the risks as described in the risk disclosures. In general, the loss of my entire invested capital cannot be ruled out.<br />
    <br />
    I am aware that the borrower can only accept my subscription offer if I have previously properly identified myself in accordance with the Money Laundering Act.
  `,
  // -- Secured Loan Specific
  investment_10_sec_text_1_1: "As an investor, I hereby offer, for my own account ",
  investment_10_sec_text_1_2: " Units of the issuer's bearer bond ",
  investment_10_sec_text_1_3: " in the nominal amount of each ",
  investment_10_sec_text_1_4: " to subscribe at the investment amount indicated below:",
  investment_10_sec_amount: "Investment Amount",
  investment_10_sec_text_2: "The issuance of the bond is based on the issuer's „Anleihebedingungen“ for the project ",
  investment_10_depot_intro: "Investor account details",
  investment_10_depot_owner: "Depot owner",
  investment_10_depot_number: "Depot Number",
  investment_10_depot_bankCode: "Bank Code",
  investment_10_sec_text_3_deposit: "I am the sole deposit holder or I hold the deposit together with my spouse.",
  investment_10_sec_text_3_deposit_legalPerson: "The company I represent is the sole depositary.",
  investment_10_sec_text_3: `
  I confirm that I am the sole beneficial owner of this transaction within the meaning of the Money Laundering Act and that there is no other beneficial owner. I do not enter into the business at the instigation of a third party, especially not as a trustee.<br />
  <br />
  Interest, repayments and any (partial) repayments of the investment amount are to be paid to the reference account of the custody account. The account is in my name.<br />
  <br />
  My offer is legally binding. The contract only comes into effect when the issuer makes the allocation. I understand that the issuer is not obliged to accept the subscription offers. A reason for a refusal is not required.<br />
  <br />
  After submitting the drawing, I receive an email requesting payment. As in the subscription process, I will be informed in this letter about any accrued interest to be paid. I will pay the investment amount and any accrued interest due no later than on the settlement date, i.e. 7 days after receipt of this request for payment, into the account specified there.
`,
  investment_10_sec_text_3_legalPerson: `
  Interest, redemption and any (partial) repayments of the investment amount are to be paid to the reference account of the custody account.<br />
  <br />
  My offer is legally binding. The contract only comes into effect when the issuer makes the allocation. I understand that the issuer is not obliged to accept the subscription offers. A reason for a refusal is not required.<br />
  <br />
  After submitting the drawing, I receive an email requesting payment. As in the subscription process, I will be informed in this letter about any accrued interest to be paid. I will pay the investment amount and any accrued interest due no later than on the settlement date, i.e. 7 days after receipt of this request for payment, into the account specified there.
`,
  investment_10_sec_text_4: `
  If payment is made after the settlement date, the accrued interest to be paid will increase. The issuer is entitled to terminate the contract if the investment amount and any accrued interest due are not received in the specified account one week after the settlement date.<br />
  <br />
  Consent under data protection law: As an investor, I hereby grant the following consent under data protection law for the purpose of maintaining the investor register and subscribing to and managing the security:<br/>
  <br />
  My personal data provided in the electronic subscription process and/or in this subscription form may be used by the platform operator bettervest GmbH, Frankfurt am Main, the liability umbrella Effecta GmbH, Florstadt, the paying agent Bankhaus Gebr. Martin, the issuer and any third party service providers who are contractually involved in the processing of the contractual relationship are stored, processed and used to the extent necessary to process the subscription/investment brokerage and the contractual relationship.<br />
  <br />
  My data, which bettervest GmbH has collected on a contractual or legal basis in accordance with the above provisions, may continue to be stored, processed and used by bettervest GmbH in accordance with the above provisions until further notice if bettervest GmbH does not more as a contractually bound agent of Effecta GmbH.<br />
  <br />
  The data will not be passed on to third parties for advertising purposes. Data processing takes place in compliance with the relevant data protection laws. The personal data will be deleted as soon as further storage is no longer necessary. I will be given information about the stored data and its disclosure on request.
`,
  investment_10_sec_text_4_legal: `
    If payment is made after the settlement date, the accrued interest to be paid will increase. The issuer is entitled to terminate the contract if the investment amount and any accrued interest due are not received in the specified account one week after the settlement date.<br />
    <br />
    Consent under data protection law: As an investor, I hereby grant the following consent under data protection law for the purpose of maintaining the investor register and subscribing to and managing the security:<br/>
    <br />
    My personal and company-related data provided in the electronic subscription process and/or in this subscription form may be processed by the platform operator bettervest GmbH, Frankfurt am Main, the liability umbrella Effecta GmbH, Florstadt, the paying agent Bankhaus Gebr. Martin, the issuer and any third-party service providers who are contractually in are involved in the execution of the contractual relationship, stored, processed and used to the extent necessary for the execution of the subscription/investment brokerage and the contractual relationship.<br />
    <br />
    My data, which bettervest GmbH has collected on a contractual or legal basis in accordance with the above provisions, may continue to be stored, processed and used by bettervest GmbH in accordance with the above provisions until further notice if bettervest GmbH does not more as a contractually bound agent of Effecta GmbH.<br />
    <br />
    The data will not be passed on to third parties for advertising purposes. Data processing takes place in compliance with the relevant data protection laws. The personal and company-related data will be deleted as soon as further storage is no longer necessary. I will be given information about the stored data and its disclosure on request.
  `,
  investment_10_sec_outro: `
    I am aware that the security offered is a corporate financing that has the risks described in the risk information. In principle, a loss of my entire invested capital cannot be ruled out.<br />
    <br />
    I am aware that the issuer can only accept my subscription offer if I have previously identified myself properly in accordance with the Money Laundering Act.
  `,
  investment_10_sec_outro_legal: `
    I am aware that the security offered is a corporate financing that has the risks described in the risk information. In principle, a loss of my entire invested capital cannot be ruled out.<br />
    <br />
    I am aware that the issuer can only accept my subscription offer if I and the company I represent have been duly identified in accordance with the Money Laundering Act.
  `,
  // 11_Quote
  investment_11_title: "Subscription Submission Submitted",
  investment_11_confirmation_intro_1: `Thank you for submitting your declaration of subscription to the project"`,
  investment_11_confirmation_intro_2: `" on bettervest.com<br /><br /><b>Please initiate the following funds transfer:</b><br /><br />`,
  investment_11_confirmation_owner: "Account holder",
  investment_11_confirmation_iban: "IBAN",
  investment_11_confirmation_bic: "BIC",
  investment_11_confirmation_bankName: "Credit institution",
  investment_11_confirmation_purpose: "Reason for payment",
  investment_11_confirmation_amount: "Amount",
  investment_11_confirmation_outro: `<b>We have also just sent you an email. There you will find instructions on the next steps necessary to complete the submission of your subscription declaration.</b>`,
  investment_11_continue_button: "Continue",
  investment_11_quote: "Comments (optional)",
  investment_11_publish: "Publish Comment",
  investment_11_anonymously: "Anonymously",
  investment_11_close_button: "Close",
  investment_11_send_and_close_button: "Send comment and close",
  investment_12_confirmation_amount:
    "You can either enter the payment information manually or simply scan the QR code below with your banking app. After you have scanned the QR code, the completed transfer will be displayed in your banking app. For security reason, you should once check the transfer before submitting the transfer.",

  investment_2_alert_disclaimer:
    "The investment product offered here is subject to German law and its offer is made exclusively in the Federal Republic of Germany. Only the German version of the contractual documents is binding. The English translation is to be used for information purposes only.",
  investment_reset_survey_button: "Reset information"
};

export default en;
