import "react-app-polyfill/ie9";
// tslint:disable-next-line
import "react-app-polyfill/node_modules/core-js/features/array";
// tslint:disable-next-line
import "react-app-polyfill/node_modules/core-js/features/object";
import React, { lazy, Suspense } from "react";
import * as ReactDOM from "react-dom";
import openRegisterModal from "applications/openRegisterModal";
import renderAll from "applications/renderAll";
import * as serviceWorker from "./serviceWorker";
if (process.env.REACT_APP_WORDPRESS !== "true") {
  console.log(JSON.stringify(process.env));
  // tslint:disable-next-line: variable-name
  const App = lazy(() => import("./App"));
  // tslint:disable-next-line: variable-name
  const Main = () => (
    <Suspense fallback={<>betterboard wird geladen...</>}>
      <App />
    </Suspense>
  );
  ReactDOM.render(<Main />, document.getElementById("root"));
}

if ((window as any).bettervest == null) (window as any).bettervest = {};
(window as any).bettervest.renderWidgets = renderAll;
(window as any).bettervest.openRegisterModal = openRegisterModal;

renderAll();

serviceWorker.unregister();
