// import ITexts from "./ITexts";

const en = {
  data_completion_button_back: "Back",
  data_completion_button_save_and_next: "Save and Continue",
  data_completion_button_finalize: "Finalize Registration",
  data_completion_modal_title: "Complete your Profile",
  data_completion_registerFn_error: "Something failed. Please try again later.",
  data_completion_registerFn_success: "Saved",
  data_completion_title_name: "Name",
  data_completion_title_address: "Address",
  data_completion_title_birthData: "Birth Information",
  data_completion_title_verification: "Verification",
  data_completion_title_feedback: "Feedback",

  data_completion_modal_legal_person_title: "Vervollständigen Sie Ihr Firmenprofil",

  // Name Step
  data_completion_name_title: "Name",
  data_completion_name_first_label: "First Name",
  data_completion_name_first_hint: "Please enter your first name.",
  data_completion_name_first_placeholder: "e.g. Max",
  data_completion_name_last_label: "Last Name",
  data_completion_name_last_hint: "Please specify your last name.",
  data_completion_name_last_placeholder: "e.g. Last Name",
  data_completion_name_gender_label: "Gender",
  data_completion_name_gender_hint: "Please select your gender",
  data_completion_name_gender_male: "Male",
  data_completion_name_gender_female: "Female",
  data_completion_name_gender_diverse: "Diverse",

  // Address Step
  data_completion_address_title: "Registration address / phone",
  data_completion_address_country_label: "Country",
  data_completion_address_country_hint: "Please select your country",
  data_completion_address_country_placeholder: "e.g. Germany",
  data_completion_address_city_label: "City",
  data_completion_address_city_hint: "Please specify the city.",
  data_completion_address_city_placeholder: "e.g. Frankfurt am Main",
  data_completion_address_postalCode_label: "Zip code",
  data_completion_address_postalCode_hint: "Please enter your zip code.",
  data_completion_address_postalCode_placeholder: "e.g. 60487",
  data_completion_address_streetName_label: "Street",
  data_completion_address_streetName_hint: "Please enter your street.",
  data_completion_address_streetName_placeholder: "e.g. sample street",
  data_completion_address_streetNumber_label: "House number",
  data_completion_address_streetNumber_hint: "Please enter your house number.",
  data_completion_address_streetNumber_placeholder: "e.g. 12a",
  data_completion_address_addition_label: "Addition",
  data_completion_address_addition_placeholder: "e.g. 4th floor",
  data_completion_address_address_label: "Address",
  data_completion_address_address_hint: "Please enter your address.",
  data_completion_address_placeholder: "Street number, city",
  data_completion_address_additional_label: "Additional address information",
  data_completion_address_additional_placeholder: "-",
  data_completion_address_phoneNumber_label: "Phone",
  data_completion_address_phoneNumber_hint: "Please enter your phone number, e.g. +4915201234567",
  data_completion_address_phoneNumber_placeholder: "e.g. +4915201234567",
  data_completion_address_phoneNumber_alert_message: "Note",
  data_completion_address_phoneNumber_alert_description: "We only need your phone number in urgent cases for queries.",

  // Birth Data Step
  data_completion_birth_title: "Birth data",
  data_completion_birth_date_label: "Date of birth",
  data_completion_birth_date_hint: "Please provide your date of birth.",
  data_completion_birth_date_placeholder: "e.g. 01.01.1990",
  data_completion_birth_place_label: "Place of birth",
  data_completion_birth_place_hint: "Please specify your place of birth.",
  data_completion_birth_place_placeholder: "e.g. Frankfurt am Main",
  data_completion_birth_place_country_label: "Country of birth",
  data_completion_birth_place_country_hint: "Please specify your country of birth.",
  data_completion_birth_place_country_placeholder: "e.g. Germany",

  // Verification Step
  data_completion_verification_title: "Verification",
  data_completion_verification_type_label: "Identity document type",
  data_completion_verification_type_hint: "Please specify the type of your identification document.",
  data_completion_verification_type_id: "Identity card",
  data_completion_verification_type_passport: "Passport",
  data_completion_verification_number_hint_required: "Please provide the number of your document.",
  data_completion_verification_number_label: "ID card number",
  data_completion_verification_number_hint_valid: "The number entered is invalid.",
  data_completion_verification_number_placeholder: "e.g. LXXXX1234",
  data_completion_verification_agency_label: "Issuing authority",
  data_completion_verification_agency_hint: "Please specify the issuing authority.",
  data_completion_verification_agency_placeholder: "e.g. City of Frankfurt",
  data_completion_verification_nationality_label: "Nationality",
  data_completion_verification_nationality_hint: "Please specify your nationality.",
  data_completion_verification_nationality_placeholder: "e.g. Germany",

  // Feedback Step
  data_completion_feedback_title: "Feedback",
  data_completion_feedback_recommendation_subtitle: "How do you know bettervest?",
  data_completion_feedback_recommendation_placeholder: "-- please select --",
  data_completion_feedback_recommendation_option_financeConsultancy: "Financial advisor",
  data_completion_feedback_recommendation_option_offlineMedia: "Offline media",
  data_completion_feedback_recommendation_option_onineMedia: "Online media",
  data_completion_feedback_recommendation_option_socialMedia: "Social Media",
  data_completion_feedback_recommendation_option_searchEngine: "Search Engine",
  data_completion_feedback_recommendation_option_triodos: "Triodos",
  data_completion_feedback_recommendation_option_events: "Event",
  data_completion_feedback_recommendation_option_recommendation: "Recommendation",
  data_completion_feedback_recommendation_option_washeldentunPodcast: "WasHeldenTun podcast",
  data_completion_feedback_recommendation_option_articlePrint: "Article: Green investing with social impact",
  data_completion_feedback_recommendation_option_articleOnline: "Article: Sustainable investing: Doing good with your investments",
  data_completion_feedback_recommendation_option_other: "Other",
  data_completion_feedback_recommendation_option_tvReport: "TV-Report",
  data_completion_feedback_recommendation_option_Podcast: "Podcast",
  data_completion_feedback_recommendation_option_articleFinanzen: "Article: FAZ Beilage - Finanzen 2023",
  data_completion_feedback_recommendation_freeText_placeholder: "Additional information",
  data_completion_feedback_newsletter_subtitle: "You find news exciting? Then sign up for our project newsletter.",
  data_completion_feedback_newsletter_checkbox: "I would like to be informed about projects via newsletter (email).",
  data_completion_feedback_newsletter_alert_message: "Note",
  data_completion_feedback_newsletter_alert_description:
    "By giving your consent, you agree to receive information about bettervest and about future investment projects. This information will be sent by bettervest GmbH, Frankfurt am Main. The consent is voluntary and can be revoked at any time. The revocation is done by sending an unsubscribe request to the contact address mentioned on the website or by clicking on the unsubscribe link in the sent messages."
};

export default en;
