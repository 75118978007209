import ITexts from "./ITexts";

const de: ITexts = {
  // General
  investment_continue_investment: "Investition fortsetzen",
  investment_default_title: "Investieren",
  investment_abort_button: "Investition abbrechen",
  investment_required_field: "*Pflichtfeld",
  investment_warning: "Warnung",
  investment_info: "Hinweis",
  investment_error: "Fehler",
  investment_error_description: `
    Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie unseren Support unter <a href="mailto:mail@bettervest.com">mail@bettervest.com</a> mit folgender Fehlermeldung:
  `,
  investment_unknown_error: "Unbekannter Fehler",
  investment_languague_label: "Language",

  // 1_CreateInvestment
  investment_1_invest_now_disabled: "Anmelden und investieren",
  investment_1_invest_now_1: "Jetzt ab ",
  investment_1_invest_now_2: " investieren",
  investment_1_hint_only_logged_in: "Nur angemeldete Benutzer können investieren.",
  investment_1_hint_only_natural_person: "Zur Zeit können nur natürliche Personen in dieses Projekt investieren.",
  investment_1_hint_profile_incomplete: "Bitte vervollständigen Sie zunächst Ihr Benutzerprofil um Investieren zu können.",
  investment_1_hint_unconfirmed_email: "Bitte bestätigen Sie zunächst Ihre E-Mail-Addresse um Investieren zu können.",
  investment_1_hint_project_not_found: "Fehler: Das Projekt konnte nicht gefunden werden.",
  investment_1_hint_project_not_invest: "Das Investieren ist zur Zeit bei diesem Projekt nicht möglich.",
  investment_1_hint_before_start: "Der Zeitraum zum Investieren in dieses Projekt hat noch nicht begonnen.",
  investment_1_hint_after_end: "Der Zeitraum zum Investieren in dieses Projekt ist beendet.",
  investment_1_hint_project_full: "Das Projekt ist bereits vollständig finanziert.",

  // 2_AcceptInvestorInformation
  investment_2_title: "Vorvertragliche Informationen per E-Mail versandt",
  investment_2_alert_description: `
    <br />
    Wir haben Ihnen eine E-Mail mit den angebotsbezogenen Informationen und den Informationen zugesandt, die unsere Tätigkeit als Vermittler betreffen.<br />
    Bitte lesen Sie sich diese Unterlagen sorgfältig und in Ruhe durch, einschließlich der dort enthaltenen Risikohinweise und setzen Sie erst danach den Investitionsprozess fort.<br />
    Bitte speichern Sie die Dateien an einem Ort, an dem Sie später problemlos darauf Zugriff haben.
  `,
  investment_2_checkbox: "Ich bestätige, dass ich die Unterlagen erhalten und abgespeichert habe.",
  investment_2_button: "Weiter",

  // 3_ApproveUpToDatenessOfPersonalData
  investment_3_title: "Identifizierung gemäß Geldwäschegesetz",
  investment_3_preview_intro: "Für Ihre Investition werden Ihre aktuellen persönlichen Daten benötigt:",
  investment_3_preview_call_to_action: "Sind die Daten noch aktuell und korrekt?",
  investment_3_preview_change_profile_button: "Nein, Daten ändern",
  investment_3_preview_continue_button: "Ja, aktuell & korrekt",
  investment_3_edit_title: "Persönliche Daten ändern",
  investment_3_edit_alert_message: "Bitte beachten Sie folgenden Hinweis",
  investment_3_edit_alert_description: "Die Änderung Ihres Nachnamens oder Ihrer Adresse führt zu einer erneuten Identifikation Ihrer Person.",
  investment_3_edit_back_without_save_button: "Zurück",
  investment_3_edit_save_and_back_button: "Speichern",
  investment_3_firstName: "Vorname",
  investment_3_lastName: "Nachname",
  investment_3_country: "Land",
  investment_3_city: "Ort",
  investment_3_postalCode: "Postleitzahl",
  investment_3_streetName: "Straße",
  investment_3_streetNumber: "Hausnummer",
  investment_3_addition: "Zusatz",
  investment_3_address: "Anschrift",
  investment_3_companyDataTitle: "Gesellschaftsbezogene Daten:",
  investment_3_companyName: "Name der Gesellschaft",
  investment_3_legalForm: "Rechtsform",
  investment_3_registerId: "Registernummer",
  investment_3_court: "Registerführende Stelle",
  investment_3_legalAddress: "Geschäftsanschrift",

  // 4_SubmitAmount / 4_SignVIB
  investment_4_amount_title: "Investitionsbetrag",
  investment_4_vib_title: "Vermögensinformationsblatt",
  investment_4_already_submitted_amount_1: "Sie haben bereits ",
  investment_4_already_submitted_amount_2: " in dieses Projekt investiert.",
  investment_4_accured_interest_start_date: "Für diese Investition fallen Stückzinsen an, ab dem",
  investment_4_accured_interest_amount: "Gesamtbetrag inkl. Stückzinsen",
  investment_4_accured_interest_alert_message: "Hinweis",
  investment_4_accured_interest_alert_description: `Dieses Wertpapier wird ab dem genannten Datum verzinst. Da am nächsten Zinszahlungstermin der Zinsbetrag für den gesamten zurückgelegten Zeitraum ausgezahlt wird, unabhängig davon wann investiert wurde, müssen Sie als Investor die angelaufenen Stückzinsen beim Erwerb mitzahlen.`,
  investment_4_available_assets_intro:
    "Da Sie insgesamt mehr als EUR 1.000 in Vermögensanlagen dieses Emittenten investieren möchten, sind wir gesetzlich verpflichtet, von Ihnen die folgende Bestätigung einzuholen:",
  investment_4_available_assets_hint: "Dieser Hinweis muss bestätigt werden.",
  investment_4_available_assets_text: `
    Hiermit bestätige ich, dass ich ENTWEDER über ein <b>frei verfügbares Vermögen</b> in Form von Bankguthaben und Finanzinstrumenten von
    mindestens <b>100.000 Euro</b> verfüge, ODER dass der Gesamtbetrag, den ich in Vermögensanlagen dieses Emittenten investieren möchte,
    den zweifachen Betrag meines durchschnittlichen <b>monatlichen Nettoeinkommens</b> nicht übersteigt.
  `,
  investment_4_available_assets_text_legalPerson: `
  Hiermit bestätige ich, dass die von mir vertretene Gesellschaft ENTWEDER über ein <b>frei verfügbares Vermögen</b> in Form von Bankguthaben und Finanzinstrumenten von
  mindestens <b>100.000 Euro</b> verfügt, ODER dass der Gesamtbetrag, den ich in Vertretung in Vermögensanlagen dieses Emittenten investieren möchte,
  den zweifachen Betrag des durchschnittlichen <b>monatlichen Nettoeinkommens</b> der von mir vertretenen Gesellschaft nicht übersteigt.
`,
  investment_4_available_assets_text_over10000: `
    Hiermit bestätige ich, dass der Gesamtbetrag, den ich in Vertretung in Vermögensanlagen dieses Emittenten investieren möchte,
    den zweifachen Betrag des durchschnittlichen <b>monatlichen Nettoeinkommens</b> der von mir vertretenen Gesellschaft nicht übersteigt.
  `,
  investment_4_available_assets_text_over10000_legalPerson: `
  Hiermit bestätige ich, dass der Gesamtbetrag, den ich in Vermögensanlagen dieses Emittenten investieren möchte,
  den zweifachen Betrag meines durchschnittlichen <b>monatlichen Nettoeinkommens</b> nicht übersteigt.
`,
  investment_4_vib_document_template: "Vermögensanlagen-Informationsblatt",
  investment_4_continue: "Weiter",
  investment_4_amount_confirm: "Betrag bestätigen",
  investment_4_amount_edit: "Betrag anpassen",
  investment_4_vib_change_label:
    "Ihre Daten sind nicht mehr aktuell, weil Sie umgezogen sind oder einen neuen Ausweis haben? Ändern Sie Ihre Daten, indem Sie die neuen Daten hier eintragen. Die Änderungen werden automatisch in Ihrem Profil übernommen.",
  investment_4_amount_label:
    "Bitte geben Sie hier den gewünschten Investitionsbetrag an. Sie können ab 50 Euro investieren und der Betrag muss durch 50 teilbar sein (z.B. 100 Euro, 1.550 Euro, 4.050 Euro).",
  investment_4_amount_confirm_betrag: "Betrag bestätigen und Weiter",
  investment_4_information_sent_label:
    "Wir haben Ihnen eine E-Mail mit den angebotsbezogenen Informationen und den Informationen zugesandt, die unsere Tätigkeit als Vermittler betreffen. Bitte lesen Sie sich diese Unterlagen sorgfältig und in Ruhe durch, einschließlich der dort enthaltenen Risikohinweise und setzen Sie erst danach den Investitionsprozess fort.",

  // eigencrowd especial case
  investment_eigencrowd:
    "Bitte teilen Sie hier Ihren ursprünglichen Investitionsbetrag von dem Eigencrowd-Funding “Werde ein Teil von bettervest” auf die drei Optionen auf. Bitte teilen Sie den vollen Betrag auf - wenn Sie weniger angeben, können Sie den Betrag nicht bestätigen. Wenn Sie eine Option nicht auswählen wollen, tragen Sie bitte “0” ein. Bitte bestätigen Sie die Eingabe mit Klick auf den Button ‘Betrag bestätigen’.",

  // anheile
  investment_4_secured_loan: `Selbstauskunft: Falls der Gesamtbetrag der von mir erworbenen Wertpapiere (einschließlich etwaiger früherer Investments in die hier angebotenen Wertpapiere) 1.000 EUR übersteigt, bestätige ich hiermit, dass mindestens eine der beiden folgenden Aussagen a) oder b) auf mich zutrifft:
  <br>a) Ich verfüge über ein frei verfügbares Vermögen in Form von Bankguthaben und Finanzinstrumenten von mindestens 100.000 EUR UND der Gesamtbetrag der von mir erworbenen Wertpapiere beträgt nicht mehr als 10.000 EUR.
  <br>b) Der Gesamtbetrag der von mir erworbenen Wertpapiere beträgt nicht mehr als das Doppelte meines durchschnittlichen monatlichen Nettoeinkommens, höchstens jedoch 25.000 EUR.`,

  // 5_SubmitInvestorSurvey
  investment_5_survey_title: "Angemessenheitstest",
  investment_5_survey_intro: `
    Wir sind gesetzlich dazu verpflichtet, bestimmte Parameter abzufragen.<br />
    <br />
    Vor einer Anlagevermittlung sind wir dazu verpflichtet vom Kunden Informationen über Kenntnisse und Erfahrungen in Bezug auf Geschäfte mit bestimmten Arten von Finanzanlagen oder Wertpapierdienstleistungen einzuholen.
  `,
  investment_5_survey_profession: "Beruf / Tätigkeit",
  investment_5_investment_experience_title:
    "Bitte kreuzen Sie jede Produktklasse an, in der Sie über Kenntnisse verfügen. Seit wann haben Sie Erfahrungen mit Geschäften in den jeweiligen Produktklassen?",
  investment_5_service_experience_title:
    "Wie tätigen Sie die Käufe bzw. Verkäufe von Finanzinstrumenten und wie lange haben Sie damit Erfahrungen (in Jahren)?",
  investment_5_avg_transaction_title: "Erfahrungen in Anzahl und Volumen",
  investment_5_knowledge_yes: "Ich verfüge über Kenntnisse",
  investment_5_knowledge_no: "Keine Kenntnisse",
  investment_5_experience_none: "Keine Erfahrung",
  investment_5_experience_lower_3: "bis 3 Jahre Erfahrung",
  investment_5_experience_3_to_5: "3 bis 5 Jahre Erfahrung",
  investment_5_experience_more_than_5: "über 5 Jahre Erfahrung",
  investment_5_type_bond_label: "Anleihen",
  investment_5_type_emerging_market_bonds_label: "Anleihen in Schwellenländern",
  investment_5_type_share_label: "Aktien",
  investment_5_type_participation_in_a_limited_partnership_label: "KG-Beteiligungen",
  investment_5_type_participation_right_label: "Genussrechte",
  investment_5_type_unsecured_junior_debt_label: "Nachrangdarlehen",
  investment_5_service_independent_label: "eigenständig / beratungsfrei",
  investment_5_service_consultancy_label: "mit Anlageberatung",
  investment_5_avg_yearly_transactions_label: "Durchschnittliche Anzahl von Transaktionen pro Jahr",
  investment_5_avg_yearly_transactions_none: "keine",
  investment_5_avg_yearly_transactions_lower_5: "bis 5",
  investment_5_avg_yearly_transactions_bet_5_to_10: "bis 10",
  investment_5_avg_yearly_transactions_more_than_10: "mehr als 10",
  investment_5_avg_transaction_value_label: "Durchschnittlicher Gegenwert pro Transaktion",
  investment_5_avg_transaction_value_lower_3000: "bis 3.000 EUR",
  investment_5_avg_transaction_value_bet_3000_to_5000: "3.000 - 5.000 EUR",
  investment_5_avg_transaction_value_bet_5000_to_10000: "5.000 - 10.000 EUR",
  investment_5_avg_transaction_value_over_10000: "über 10.000 EUR",
  investment_5_inappropriate_title: "Angemessenheitstest - unangemessen",
  investment_5_inappropriate_intro: `
    Die Auswertung der von Ihnen gemachten Angaben zu bisherigen Kenntnissen und Erfahrungen bei Finanzinstrumenten ergab, dass das hier angebotene Produkt bzw. die Höhe Ihrer Zeichnung nicht dem auf Basis Ihrer Angaben erstellten Anlageprofil entspricht. Gemäß Ihren Angaben verfügen Sie über zu wenig Kenntnisse und Erfahrungen, um die mit dem Erwerb des hier angebotenen Produkts verbundenen Risiken ausreichend beurteilen zu können, d.h. Ihre Zeichnung entspricht nicht Ihrem bisherigen Anlageprofil.<br />
    <br />
    Bitte informieren Sie sich noch einmal über die mit der Zeichnung verbundenen Risiken.<br />
    Eine ausführliche Darstellung der mit der Zeichnung verbundenen Risiken finden Sie in den Risikohinweisen, welche Ihnen in den Vorab-Informationen per Mail zugesandt wurden.<br />
    <br />
    Wenn Sie sich entscheiden, das Produkt dennoch zu zeichnen, bitte wir Sie, dies rechtskräftig zu bestätigen:
  `,
  investment_5_inappropriate_checkbox: `
    Den Hinweis, dass die Zeichnung der Kapitalanlage nicht meinem Anlageprofil auf Grund der von mir angegebenen Kenntnisse und bisherigen Erfahrungen bei Finanzinstrumenten entspricht, habe ich zur Kenntnis genommen. <b>Ich entscheide mich dennoch für die Zeichnung des angebotenen Finanzinstrumentes.</b>
  `,
  investment_5_not_specified_title: "Angemessenheitstest - keine Angaben",
  investment_5_not_specified_intro: `
    Sehr geehrte Investorin,<br />
    sehr geehrter Investor,<br />
    <br />
    die Angemessenheit beurteilt sich danach, ob Sie als Anleger/-in über die erforderlichen Kenntnisse und Erfahrungen verfügen, um die Risiken im Zusammenhang mit der Art der Finanzierungsinstrumente oder Wertpapierdienstleistungen angemessen beurteilen können.<br />
    <br />
    In den von Ihnen angegebenen Antworten haben Sie unvollständige Angaben gemacht. Wir weisen Sie daher darauf hin, dass eine Angemessenheitsprüfung bei fehlenden/unvollständigen Angaben nicht möglich ist. Sie müssen daher selbst entscheiden, ob die Finanzinstrumente für Sie angemessen sind. Insbesondere sollen Sie sich mit den Risiken und Gegebenheiten des Finanzinstruments vertraut machen.   
  `,
  investment_5_not_specified_checkbox: `
    Den Hinweis, dass nicht beurteilt werden kann, ob das beabsichtigte Geschäft für mich nach meinen Kenntnissen und Erfahrungen angemessen ist, habe ich zur Kenntnis genommen. Ich entscheide mich dennoch für die Zeichnung des angebotenen Finanzinstrumentes.
  `,
  investment_5_continue_without_data: "Weiter ohne Angaben",
  investment_5_continue: "Weiter",
  investment_5_answer: "Angaben machen",

  // 6_SubmitIdentificationPreInfos
  investment_6_title: "Identifizierung gemäß Geldwäschegesetz",
  investment_6_benficial_owner:
    "Ich bestätige, dass ich als Vertragspartner gleichzeitig der <b>wirtschaftlich Berechtigte</b> dieser Transaktion bin.",
  investment_6_political_exposed:
    "Ich bestätige, dass ich <b>nicht als sogenannte politisch exponierte Persönlichkeit</b> ein wichtiges öffentliches Amt innehabe und nicht als z.B. unmittelbares Familienmitglied einer politisch exponierten Person nahestehe.",
  investment_6_continue: "Bestätigen",
  investment_6_after_hint: `
    Sie sind nicht wirtschaftlich Berechtigte/r und/oder sind eine politisch exponierte Person oder stehen einer solchen nahe?<br />
    Kontaktieren Sie uns: <a href="mailto:mail@bettervest.com">mail@bettervest.com</a>
  `,
  investment_6_after_hint_legalPerson: `
    Sie sind eine politisch exponierte Person oder stehen einer solchen nahe?<br />
    Kontaktieren Sie uns: <a href="mailto:mail@bettervest.com">mail@bettervest.com</a>
  `,

  // 7_RecheckForValidIdentification
  investment_7_title: "Identifizierung gemäß Geldwäschegesetz",
  investment_7_text: `
    <b>Hinweis</b><br />
    <br />
    Wir sind aufgrund gesetzlicher Vorgaben dazu verpflichtet, Ihre Identität zu überprüfen, bevor eine Investition möglich ist.<br />
    <br />
    <b>Online-Identifikation (Dauer ca. 10 Minuten):</b><br />
    <br />
    Mit Klick auf "Jetzt Identifikation starten" werden Sie auf die Seite der Deutschen Post weitergeleitet und duchlaufen einen "Online Indentifikations" Prozess.<br />
    <br />
    Bitte halten Sie folgende Unterlagen dazu bereit: Reisepass oder Personalausweis
  `,
  investment_7_continue: "Jetzt Identifikation starten",

  // 8_BankAccount
  investment_8_bank_title: "Kontoinformationen",
  investment_8_bank_save_and_continue_button: "Speichern und Weiter",
  investment_8_bank_edit_button: "Kontodaten ändern",
  investment_8_bank_continue_button: "Weiter",
  investment_8_bank_preview_intro: "Ihre aktuell hinterlegten Kontodaten:",
  investment_8_bank_preview_intro_legal: "Die aktuell hinterlegten Kontodaten der von Ihnen vertretenen Gesellschaft:",
  investment_8_bank_preview_no_data: "Keine Kontodaten vorhanden.",
  investment_8_bank_edit_intro: "Bitte exakt so angeben, wie bei Ihrer Bank hinterlegt:",
  investment_8_bank_owner: "Kontoinhaber",
  investment_8_bank_owner_error: "Mindestens drei Zeichen",
  investment_8_bank_iban: "IBAN",
  investment_8_bank_iban_error: "IBAN ist ungültig",
  investment_8_bank_bic: "BIC",
  investment_8_bank_bic_error: "BIC ist ungültig",
  investment_8_bank_bankname: "Kreditinstitut",
  investment_8_bank_bankname_error: "Mindestens drei Zeichen",

  // 8_Deposit
  investment_8_deposit_title: "Depotinformationen",
  investment_8_deposit_intro: "Sobald Sie eine Investition in ein Wertpapier tätigen, wird für die Einbuchung ein Depot benötigt.",
  investment_8_deposit_save_and_continue_button: "Speichern und Weiter",
  investment_8_deposit_edit_button: "Depotdaten ändern",
  investment_8_deposit_continue_button: "Weiter",
  investment_8_deposit_preview_intro: "Ihre aktuell hinterlegten Depotdaten:",
  investment_8_deposit_preview_intro_legal: "Die aktuell hinterlegten Depotdaten der von Ihnen vertretenen Gesellschaft:",
  investment_8_deposit_preview_no_data: "Keine Depotdaten vorhanden.",
  investment_8_deposit_edit_intro: "Bitte exakt so angeben, wie bei Ihrer Depotbank hinterlegt:",
  investment_8_deposit_owner: "Depotinhaber vollständig namentlich",
  investment_8_deposit_number: "Depotnummer",
  investment_8_deposit_bankCode: "BLZ (nicht BIC!)",
  investment_8_deposit_alert_message: "Hinweis",
  investment_8_deposit_alert_description: `
    Sie können auch bei Ihrer Hausbank anfragen, beachten Sie hier bitte nur, dass Depots bei den folgenden Banken leider nicht für eine Investition in ein Wertpapier auf bettervest.com verwendet werden können:
    <br />
    Augsburger Aktienbank, Umweltbank, Frankfurter Fondsbank (FIL Fondsbank), Fondsdepotbank, Dekabank, Union Investment, Trade Republic
  `,

  // 9_Costs
  investment_9_title: "Informationen über Zuwendungen, Kosten und Gebühren",
  investment_9_alert_description: "Für Sie als Anleger fallen keine Kosten an. Alle anfallenden Kosten entstehen dem jeweiligen Emittenten.",
  investment_9_continue: "Weiter",

  // 10_SigningDeclaration
  investment_10_title: "Zeichnungserklärung",
  investment_10_amount_text_1: "muss mindestens ",
  investment_10_amount_text_2: " betragen und durch ",
  investment_10_amount_text_3: " teilbar sein",
  investment_10_sub_coupon_validating: "Gültigkeit des Gutscheins wird überprüft ...",
  investment_10_sub_coupon_isValid: "Gutscheinwert:",
  investment_10_sub_coupon_isInvalid: "Der Gutscheincode ist ungültig.",
  investment_10_sub_coupon: "Gutscheincode",
  investment_10_sub_coupon_placeholder: "optional",
  investment_10_transferInfo_owner: "Kontoinhaber",
  investment_10_transferInfo_iban: "IBAN",
  investment_10_transferInfo_bic: "BIC",
  investment_10_transferInfo_bankname: "Kreditinstitut",
  investment_10_transferInfo_purpose: "Verwendungszweck",
  investment_10_document_title: "Empfangsbestätigung",
  investment_10_submit_button_1: "Zahlungspflichtig",
  investment_10_submit_button_2: "Zeichnungserklärung abgeben",
  // -- Genussrechte
  investment_10_sub_genuss_text_1:
    "Als Anleger biete ich hiermit dem Genussrechtsgeber den Abschluss eines <b>Genussrechtsvertrags</b> mit nachfolgendem <b>Investitionsbetrag</b> an.",
  investment_10_sub_genuss_amount: "Investitionsbetrag",
  investment_10_sub_genuss_text_2:
    "Die Gewährung des Genussrechts beruht auf dem Genussrechtsvertrag „<b>Genussrecht_bettervest Eigencrowd Variante A</b>“",
  investment_10_sub_genuss_text_4: `
  <br />
  Mein Angebot ist rechtlich verbindlich. Erst mit Annahme des Vertragsangebots durch den Genussrechtsgeber kommt der Vertrag zustande. Ich habe zur Kenntnis genommen, dass der Genussrechtsgeber zur Annahme der 
  Zeichnungsangebote nicht verpflichtet ist. Eine Begründung einer Ablehnung ist nicht erforderlich. 
  <br /><br />
  Nach Einreichung der Zeichnung erhalte ich per E-Mail eine Zahlungsaufforderung. Den Genussrechtsbetrag werde ich innerhalb von drei Werktagen nach Erhalt der Zahlungsaufforderung auf das dort genannte Konto einzahlen. 
  <br/>
  Ich habe zur Kenntnis genommen, dass der Vertragsabschluss unter der auflösenden Bedingung steht, dass meine Zahlung nicht innerhalb von zwei Wochen ab Erhalt der Zahlungsaufforderung auf dem Konto eingegangen ist (diese Bedingung entfällt, sofern der Betrag schon auf dem Treuhandkonto vorliegt aufgrund Verrechnung mit dem Projekt 
  „Werde Teil von bettervest“). <br/ > <br/ >
  <b>
  Datenschutzrechtliche Einwilligung: Als Anleger erteile ich hiermit zum Zwecke der Führung des Anlegerregisters sowie der Zeichnung und Verwaltung des Genussrechts die folgende datenschutzrechtliche Einwilligung:
  <br /> <br />
  Meine im elektronischen Zeichnungsprozess und/oder in diesem Zeichnungsschein angegebenen personenbezogenen Daten dürfen durch den Plattformbetreiber bettervest GmbH, Frankfurt am Main, das Haftungsdach Effecta GmbH, Florstadt, den Darlehensnehmer und etwaige dritte Dienstleister, die vertraglich
  in die Abwicklung des Vertragsverhältnisses eingebunden sind, gespeichert, verarbeitet und genutzt werden, soweit zur Abwicklung der Zeichnung/Anlagevermittlung und des Darlehens-Vertragsverhältnisses erforderlich.
  <br /> <br />
  Meine Daten, die die bettervest GmbH auf vertraglicher oder gesetzlicher Grundlage in Einklang mit den vorstehenden Bestimmungen erhoben hat, dürfen in Einklang mit den vorstehenden Bestimmungen bis auf Widerruf auch dann weiter von der bettervest GmbH gespeichert, bearbeitet und genutzt werden, wenn die bettervest GmbH nicht mehr als vertraglich 
  gebundener Vermittler der Effecta GmbH tätig sein sollte.
  <br /> <br />
  Es erfolgt keine Weitergabe der Daten an Dritte zu Werbezwecken. Die Datenverarbeitung geschieht unter Beachtung der einschlägigen Datenschutzgesetze. Die personenbezogenen Daten werden gelöscht, sobald eine weitere Speicherung nicht mehr notwendig ist. Mir wird über die gespeicherten Daten und deren Weitergabe auf Anfrage Auskunft erteilt. 
  </b>
  `,
  investment_10_sub_genuss_outro: `
    Mir ist bewusst, dass es sich bei den angebotenen Genussrechten um eine unternehmerische Finanzierung handelt, die die in den Risikohinweisen beschriebenen Risiken aufweist. Es kann prinzipiell auch ein Verlust meines gesamten eingesetzten Kapitals nicht ausgeschlossen werden.<br />
    <br />
    Mir ist bekannt, dass der Genussrechtsgeber mein Zeichnungsangebot nur annehmen kann, wenn ich mich zuvor ordnungsgemäß nach dem Geldwäschegesetz identifiziert habe. 
  `,
  // -- Subordinated Loan Specific
  investment_10_sub_text_1:
    "Als Anleger biete ich hiermit dem Darlehensnehmer den Abschluss eines qualifiziert nachrangigen Darlehens mit vorinsolvenzlicher Durchsetzungssperre mit nachfolgendem Darlehensbetrag an.",
  investment_10_sub_amount: "Darlehensbetrag",
  investment_10_sub_text_2:
    "Die Gewährung des Nachrangdarlehens beruht auf den Darlehensbedingungen „<b>Energiewende-Nachrangdarlehen</b> - Nachrangdarlehen mit qualifiziertem Rangrücktritt und vorinsolvenzlicher Durchsetzungssperre“ ",
  investment_10_sub_text_3: `
    Mein Angebot ist rechtlich verbindlich. Erst mit Annahme des Vertragsangebots durch den Darlehensnehmer kommt der Vertrag zustande. Ich habe zur Kenntnis genommen, dass der Darlehensnehmer zur Annahme der Zeichnungsangebote nicht verpflichtet ist. Eine Begründung einer Ablehnung ist nicht erforderlich.<br />
    <br />
    Nach Einreichung der Zeichnung erhalte ich per E-Mail eine Zahlungsaufforderung. Den Darlehensbetrag werde ich innerhalb von drei Werktagen nach Erhalt der Zahlungsaufforderung auf das dort genannte Konto einzahlen.
  `,
  investment_10_sub_text_4: `
    Ich habe zur Kenntnis genommen, dass der Vertragsabschluss unter der auflösenden Bedingung steht, dass meine Zahlung nicht innerhalb von zwei Wochen ab Erhalt der Zahlungsaufforderung auf dem Konto eingegangen ist.<br />
    <br />
    <b>
      Datenschutzrechtliche Einwilligung: Als Anleger erteile ich hiermit zum Zwecke der Führung des Anlegerregisters sowie der Zeichnung und Verwaltung des Nachrangdarlehens die folgende datenschutzrechtliche Einwilligung:<br/>
      <br />
      Meine im elektronischen Zeichnungsprozess und/oder in diesem Zeichnungsschein angegebenen personenbezogenen Daten dürfen durch den Plattformbetreiber bettervest GmbH, Frankfurt am Main, das Haftungsdach Effecta GmbH, Florstadt, den Darlehensnehmer und etwaige dritte Dienstleister, die vertraglich in die Abwicklung des Vertragsverhältnisses eingebunden sind, gespeichert, verarbeitet und genutzt werden, soweit zur Abwicklung der Zeichnung/Anlagevermittlung und des Darlehens-Vertragsverhältnisses erforderlich.<br />
      <br />
      Meine Daten, die die bettervest GmbH auf vertraglicher oder gesetzlicher Grundlage in Einklang mit den vorstehenden Bestimmungen erhoben hat, dürfen in Einklang mit den vorstehenden Bestimmungen bis auf Widerruf auch dann weiter von der bettervest GmbH gespeichert, bearbeitet und genutzt werden, wenn die bettervest GmbH nicht mehr als vertraglich gebundener Vermittler der Effecta GmbH tätig sein sollte.<br />
      <br />
      Es erfolgt keine Weitergabe der Daten an Dritte zu Werbezwecken. Die Datenverarbeitung geschieht unter Beachtung der einschlägigen Datenschutzgesetze. Die personenbezogenen Daten werden gelöscht, sobald eine weitere Speicherung nicht mehr notwendig ist. Mir wird über die gespeicherten Daten und deren Weitergabe auf Anfrage Auskunft erteilt.
    </b>
  `,
  investment_10_sub_text_4_legal: `
    Ich habe zur Kenntnis genommen, dass der Vertragsabschluss unter der auflösenden Bedingung steht, dass meine Zahlung nicht innerhalb von zwei Wochen ab Erhalt der Zahlungsaufforderung auf dem Konto eingegangen ist.<br />
    <br />
    <b>
      Datenschutzrechtliche Einwilligung: Als Anleger erteile ich hiermit zum Zwecke der Führung des Anlegerregisters sowie der Zeichnung und Verwaltung des Nachrangdarlehens die folgende datenschutzrechtliche Einwilligung:<br/>
      <br />
      Meine im elektronischen Zeichnungsprozess und/oder in diesem Zeichnungsschein angegebenen personenbezogenen und gesellschaftsbezogenen Daten dürfen durch den Plattformbetreiber bettervest GmbH, Frankfurt am Main, das Haftungsdach Effecta GmbH, Florstadt, den Darlehensnehmer und etwaige dritte Dienstleister, die vertraglich in die Abwicklung des Vertragsverhältnisses eingebunden sind, gespeichert, verarbeitet und genutzt werden, soweit zur Abwicklung der Zeichnung/Anlagevermittlung und des Darlehens-Vertragsverhältnisses erforderlich.<br />
      <br />
      Meine Daten, die die bettervest GmbH auf vertraglicher oder gesetzlicher Grundlage in Einklang mit den vorstehenden Bestimmungen erhoben hat, dürfen in Einklang mit den vorstehenden Bestimmungen bis auf Widerruf auch dann weiter von der bettervest GmbH gespeichert, bearbeitet und genutzt werden, wenn die bettervest GmbH nicht mehr als vertraglich gebundener Vermittler der Effecta GmbH tätig sein sollte.<br />
      <br />
      Es erfolgt keine Weitergabe der Daten an Dritte zu Werbezwecken. Die Datenverarbeitung geschieht unter Beachtung der einschlägigen Datenschutzgesetze. Die personenbezogenen und gesellschaftsbezogenen Daten werden gelöscht, sobald eine weitere Speicherung nicht mehr notwendig ist. Mir wird über die gespeicherten Daten und deren Weitergabe auf Anfrage Auskunft erteilt.
    </b>
  `,
  investment_10_sub_outro: `
    Mir ist bewusst, dass es sich bei den angebotenen Nachrangdarlehen um eine unternehmerische Finanzierung handelt, die die in den Risikohinweisen beschriebenen Risiken aufweist. Es kann prinzipiell auch ein Verlust meines gesamten eingesetzten Kapitals nicht ausgeschlossen werden.<br />
    <br />
    Mir ist bekannt, dass der Darlehensnehmer mein Zeichnungsangebot nur annehmen kann, wenn ich mich zuvor ordnungsgemäß nach dem Geldwäschegesetz identifiziert habe. 
  `,
  investment_10_sub_outro_legal: `
    Mir ist bewusst, dass es sich bei den angebotenen Nachrangdarlehen um eine unternehmerische Finanzierung handelt, die die in den Risikohinweisen beschriebenen Risiken aufweist. Es kann prinzipiell auch ein Verlust meines gesamten eingesetzten Kapitals nicht ausgeschlossen werden.<br />
    <br />
    Mir ist bekannt, dass der Darlehensnehmer mein Zeichnungsangebot nur annehmen kann, wenn ich und die von mir vertretene Gesellschaft zuvor ordnungsgemäß nach dem Geldwäschegesetz identifiziert worden.
  `,
  // -- Secured Loan Specific
  investment_10_sec_text_1_1: "Als Anleger biete ich hiermit an, für eigene Rechnung ",
  investment_10_sec_text_1_2: " Stücke der Inhaber-Teilschuldverschreibung des Emittenten ",
  investment_10_sec_text_1_3: " im Nennbetrag von jeweils ",
  investment_10_sec_text_1_4: " zum nachfolgend bezeichneten Anlagebetrag zu zeichnen:",
  investment_10_sec_amount: "Anlagebetrag",
  investment_10_sec_text_2: "Die Begebung der Schuldverschreibung beruht auf den „Anleihebedingungen“ des Emittenten zum Projekt ",
  investment_10_depot_intro: "Depotverbindung des Anlegers",
  investment_10_depot_owner: "Depotinhaber",
  investment_10_depot_number: "Depotnummer",
  investment_10_depot_bankCode: "Bankleitzahl",
  investment_10_sec_text_3_deposit: "Ich bin der alleinige Depothalter oder ich halte das Depot zusammen mit meinem Ehegatten oder meiner Ehegattin.",
  investment_10_sec_text_3_deposit_legalPerson: "Die von mir vertretene Gesellschaft ist der alleinige Depothalter.",
  investment_10_sec_text_3: `
    Ich bestätige, dass ich selbst der alleinige wirtschaftlich Berechtigte dieser Transaktion im Sinne des Geldwäschegesetzes bin und kein abweichender wirtschaftlich Berechtigter existiert. Ich gehe das Geschäft nicht auf Veranlassung eines Dritten, insbesondere nicht als Treuhänder ein.<br />
    <br />
    Zins-, Tilgungs- und etwaige (Teil-)Rückzahlungen des Anlagebetrags sind auf das Referenzkonto des Depots zu zahlen. Das Konto lautet auf meinen Namen.<br />
    <br />
    Mein Angebot ist rechtlich verbindlich. Erst mit Zuteilung durch den Emittenten kommt der Vertrag zustande. Ich habe zur Kenntnis genommen, dass der Emittent zur Annahme der Zeichnungsangebote nicht verpflichtet ist. Eine Begründung einer Ablehnung ist nicht erforderlich.<br />
    <br />
    Nach Einreichung der Zeichnung erhalte ich per E-Mail eine Zahlungsaufforderung. In diesem Schreiben werde ich wie bereits im Zeichnungsprozess über die etwaig zu zahlenden Stückzinsen informiert. Den Anlagebetrag und die etwaig fälligen Stückzinsen werde ich spätestens zum Abrechnungstag, d.h. 7 Tage nach Erhalt dieser Zahlungsaufforderung auf das dort genannte Konto einzahlen.
  `,
  investment_10_sec_text_3_legalPerson: `
    Zins-, Tilgungs- und etwaige (Teil-)Rückzahlungen des Anlagebetrags sind auf das Referenzkonto des Depots zu zahlen.<br />
    <br />
    Mein Angebot ist rechtlich verbindlich. Erst mit Zuteilung durch den Emittenten kommt der Vertrag zustande. Ich habe zur Kenntnis genommen, dass der Emittent zur Annahme der Zeichnungsangebote nicht verpflichtet ist. Eine Begründung einer Ablehnung ist nicht erforderlich.<br />
    <br />
    Nach Einreichung der Zeichnung erhalte ich per E-Mail eine Zahlungsaufforderung. In diesem Schreiben werde ich wie bereits im Zeichnungsprozess über die etwaig zu zahlenden Stückzinsen informiert. Den Anlagebetrag und die etwaig fälligen Stückzinsen werde ich spätestens zum Abrechnungstag, d.h. 7 Tage nach Erhalt dieser Zahlungsaufforderung auf das dort genannte Konto einzahlen.
  `,
  investment_10_sec_text_4: `
    Bei einer Zahlung nach dem Abrechnungstag erhöhen sich die zu zahlenden Stückzinsen. Der Emittent ist zur Kündigung berechtigt, wenn der Anlagebetrag und die etwaig fälligen Stückzinsen eine Woche nach dem Abrechnungstag nicht auf dem genannten Konto eingegangen sind.<br />
    <br />
    Datenschutzrechtliche Einwilligung: Als Anleger erteile ich hiermit zum Zwecke der Führung des Anlegerregisters sowie der Zeichnung und Verwaltung des Wertpapiers die folgende datenschutzrechtliche Einwilligung:<br/>
    <br />
    Meine im elektronischen Zeichnungsprozess und/oder in diesem Zeichnungsschein angegebenen personenbezogenen Daten dürfen durch den Plattformbetreiber bettervest GmbH, Frankfurt am Main, das Haftungsdach Effecta GmbH, Florstadt, die Zahlstelle Bankhaus Gebr. Martin, den Emittenten und etwaige dritte Dienstleister, die vertraglich in die Abwicklung des Vertragsverhältnisses eingebunden sind, gespeichert, verarbeitet und genutzt werden, soweit zur Abwicklung der Zeichnung/Anlagevermittlung und des Vertragsverhältnisses erforderlich.<br />
    <br />
    Meine Daten, die die bettervest GmbH auf vertraglicher oder gesetzlicher Grundlage in Einklang mit den vorstehenden Bestimmungen erhoben hat, dürfen in Einklang mit den vorstehenden Bestimmungen bis auf Widerruf auch dann weiter von der bettervest GmbH gespeichert, bearbeitet und genutzt werden, wenn die bettervest GmbH nicht mehr als vertraglich gebundener Vermittler der Effecta GmbH tätig sein sollte.<br />
    <br />
    Es erfolgt keine Weitergabe der Daten an Dritte zu Werbezwecken. Die Datenverarbeitung geschieht unter Beachtung der einschlägigen Datenschutzgesetze. Die personenbezogenen Daten werden gelöscht, sobald eine weitere Speicherung nicht mehr notwendig ist. Mir wird über die gespeicherten Daten und deren Weitergabe auf Anfrage Auskunft erteilt.
  `,
  investment_10_sec_text_4_legal: `
    Bei einer Zahlung nach dem Abrechnungstag erhöhen sich die zu zahlenden Stückzinsen. Der Emittent ist zur Kündigung berechtigt, wenn der Anlagebetrag und die etwaig fälligen Stückzinsen eine Woche nach dem Abrechnungstag nicht auf dem genannten Konto eingegangen sind.<br />
    <br />
    Datenschutzrechtliche Einwilligung: Als Anleger erteile ich hiermit zum Zwecke der Führung des Anlegerregisters sowie der Zeichnung und Verwaltung des Wertpapiers die folgende datenschutzrechtliche Einwilligung:<br/>
    <br />
    Meine im elektronischen Zeichnungsprozess und/oder in diesem Zeichnungsschein angegebenen personenbezogenen und gesellschaftsbezogenen Daten dürfen durch den Plattformbetreiber bettervest GmbH, Frankfurt am Main, das Haftungsdach Effecta GmbH, Florstadt, die Zahlstelle Bankhaus Gebr. Martin, den Emittenten und etwaige dritte Dienstleister, die vertraglich in die Abwicklung des Vertragsverhältnisses eingebunden sind, gespeichert, verarbeitet und genutzt werden, soweit zur Abwicklung der Zeichnung/Anlagevermittlung und des Vertragsverhältnisses erforderlich.<br />
    <br />
    Meine Daten, die die bettervest GmbH auf vertraglicher oder gesetzlicher Grundlage in Einklang mit den vorstehenden Bestimmungen erhoben hat, dürfen in Einklang mit den vorstehenden Bestimmungen bis auf Widerruf auch dann weiter von der bettervest GmbH gespeichert, bearbeitet und genutzt werden, wenn die bettervest GmbH nicht mehr als vertraglich gebundener Vermittler der Effecta GmbH tätig sein sollte.<br />
    <br />
    Es erfolgt keine Weitergabe der Daten an Dritte zu Werbezwecken. Die Datenverarbeitung geschieht unter Beachtung der einschlägigen Datenschutzgesetze. Die personenbezogenen und gesellschaftsbezogenen Daten werden gelöscht, sobald eine weitere Speicherung nicht mehr notwendig ist. Mir wird über die gespeicherten Daten und deren Weitergabe auf Anfrage Auskunft erteilt.
  `,
  investment_10_sec_outro: `
    Mir ist bewusst, dass es sich bei dem angebotenen Wertpapier um eine unternehmerische Finanzierung handelt, die die in den Risikohinweisen beschriebenen Risiken aufweist. Es kann prinzipiell auch ein Verlust meines gesamten eingesetzten Kapitals nicht ausgeschlossen werden.<br />
    <br />
    Mir ist bekannt, dass der Emittent mein Zeichnungsangebot nur annehmen kann, wenn ich mich zuvor ordnungsgemäß nach dem Geldwäschegesetz identifiziert habe. 
  `,
  investment_10_sec_outro_legal: `
    Mir ist bewusst, dass es sich bei dem angebotenen Wertpapier um eine unternehmerische Finanzierung handelt, die die in den Risikohinweisen beschriebenen Risiken aufweist. Es kann prinzipiell auch ein Verlust meines gesamten eingesetzten Kapitals nicht ausgeschlossen werden.<br />
    <br />
    Mir ist bekannt, dass der Emittent mein Zeichnungsangebot nur annehmen kann, wenn ich und die von mir vertretene Gesellschaft zuvor ordnungsgemäß nach dem Geldwäschegesetz identifiziert worden. 
  `,

  // 11_Quote
  investment_11_title: "Zeichungserklärung abgegeben",
  investment_11_confirmation_intro_1: `Vielen Dank für die Einreichung Ihrer Zeichnung in das Projekt "`,
  investment_11_confirmation_intro_2: `" auf bettervest.com<br /><br /><b>Bitte nehmen Sie folgende Überweisung vor:</b><br /><br />`,
  investment_11_confirmation_owner: "Kontoinhaber",
  investment_11_confirmation_iban: "IBAN",
  investment_11_confirmation_bic: "BIC",
  investment_11_confirmation_bankName: "Kreditinstitut",
  investment_11_confirmation_purpose: "Verwendungszweck",
  investment_11_confirmation_amount: "Betrag",
  investment_11_confirmation_outro: `<b>Wir haben Ihnen soeben außerdem eine E-Mail zugesendet. Dort finden Sie die Anleitung zu den weiteren notwendigen Schritten, die erforderlich sind, um die Einreichung Ihrer Zeichnung abzuschließen.</b>`,
  investment_11_continue_button: "Weiter",
  investment_11_quote: "Kommentar (optional)",
  investment_11_publish: "Kommentar veröffentlichen",
  investment_11_anonymously: "Anonym",
  investment_11_close_button: "Schließen",
  investment_11_send_and_close_button: "Kommentar senden und schließen",
  investment_12_confirmation_amount:
    "Sie können die Zahlungsinformationen entweder manuell eingeben oder einfach den untenstehenden QR-Code mit Ihrer Banking-App scannen. Nachdem sie den QR-Code gescannt haben, wird Ihnen in Ihrer Banking-App die ausgefüllte Überweisung angezeigt. Diese sollten Sie zur Sicherheit einmal kurz überprüfen bevor Sie die Überweisung abschicken.",

  investment_2_alert_disclaimer: "",
  investment_reset_survey_button: "Angaben zurücksetzen"
};

export default de;
