import React, { useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import message from "antd/lib/message";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import State from "applications/models/State";
import FormattedMessage from "components/FormattedMessage";
import { intlContext } from "translations/context";
import * as userInfoActions from "../../models/userInfo/actions";
import useRegisterApi, { RegisterFormValues } from "../hooks/useRegisterApi";
import * as actions from "../models/actions";
import RegisterForm from "./RegisterForm";

interface IProps {
  afterClose?: () => void;
}

export default function RegisterModal({ afterClose }: IProps): JSX.Element {
  const { visible, documentTemplateSet, showSuccessView } = useSelector((state: State) => ({
    visible: state.userHandling?.registerModalVisible ?? false,
    isLegalPersonSelected: state.userHandling?.isLegalPersonSelected ?? false,
    documentTemplateSet: state.userHandling?.generalDocumentTemplateSet ?? null,
    showSuccessView: state.userHandling?.registerShowSuccessView ?? false
  }));
  const dispatch = useDispatch();
  const { translate } = useContext(intlContext);
  const [form] = Form.useForm<RegisterFormValues>();

  const { handle: onRegister, loading } = useRegisterApi({
    form,
    onError: useCallback((err) => {
      if (err != null && err.message != null && err.message.startsWith("Network error")) {
        message.error(<FormattedMessage id="server_is_unavailable" />, 15);
      } else message.error(<FormattedMessage id="userhandling_register_message_error" />);
    }, []),
    onSuccess: useCallback(() => {
      message.success(<FormattedMessage id="userhandling_register_message_success" />);
      dispatch(userInfoActions.fetchUserInfo());
      dispatch(actions.setRegisterModalVisible(false));
      setTimeout(() => {
        dispatch(actions.setShowSuccessView(false));
      }, 20); // Wait for modal close animation.
    }, [dispatch])
  });

  return (
    <Modal
      afterClose={() => afterClose?.()}
      visible={visible}
      title={translate("userhandling_register_title")}
      onCancel={() => dispatch(actions.setRegisterModalVisible(false))}
      footer={[
        <React.Fragment key="submit">
          {!showSuccessView && (
            <Form.Item>
              <Button id="register" key="submit" type="primary" onClick={() => onRegister()} disabled={documentTemplateSet == null} loading={loading}>
                {translate("userhandling_register")}
              </Button>
            </Form.Item>
          )}
        </React.Fragment>
      ]}
    >
      {!showSuccessView ? (
        <RegisterForm form={form} />
      ) : (
        <>
          <h1>{<FormattedMessage id="userhandling_register_almostDone_title" />}</h1>
          <ol>
            <li>{<FormattedMessage id="userhandling_register_almostDone_step1" />}</li>
            <li>{<FormattedMessage id="userhandling_register_almostDone_step2" />}</li>
          </ol>
        </>
      )}
    </Modal>
  );
}
