import * as React from "react";
import * as ReactDOM from "react-dom";
import store from "applications/models/store";
import UserHandlingApp from "applications/user-handling/App";

export default function openRegisterModal() {
  const container = document.getElementById("react-app-register-modal");
  if (container == null) {
    console.log("Error: Container not found");
    return;
  }

  ReactDOM.render(<UserHandlingApp store={store} onlyRegister afterClose={() => ReactDOM.unmountComponentAtNode(container)} />, container);
}
