import ITexts from "./ITexts";

const de: ITexts = {
  // General
  data_completion_modal_title: "Vervollständigen Sie Ihr Profil",
  data_completion_modal_legal_person_title: "Vervollständigen Sie Ihr Firmenprofil",
  data_completion_button_back: "Zurück",
  data_completion_button_save_and_next: "Speichern und Weiter",
  data_completion_button_finalize: "Registrierung abschließen",
  data_completion_registerFn_error: "Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.",
  data_completion_registerFn_success: "Gespeichert!",

  // Name Step
  data_completion_name_title: "Name",
  data_completion_name_first_label: "Vorname",
  data_completion_name_first_hint: "Bitte geben Sie Ihren Vornamen an.",
  data_completion_name_first_placeholder: "z.B. Max",
  data_completion_name_last_label: "Nachname",
  data_completion_name_last_hint: "Bitte geben Sie Ihren Nachnamen an.",
  data_completion_name_last_placeholder: "z.B. Mustermann",
  data_completion_name_gender_label: "Geschlecht",
  data_completion_name_gender_hint: "Bitte geben Sie Ihr Geschlecht an.",
  data_completion_name_gender_male: "männlich",
  data_completion_name_gender_female: "weiblich",
  data_completion_name_gender_diverse: "divers",

  // Address Step
  data_completion_address_title: "Meldeadresse / Telefon",
  data_completion_address_country_label: "Land",
  data_completion_address_country_hint: "Bitte geben Sie Ihr Land an.",
  data_completion_address_country_placeholder: "z.B. Deutschland",
  data_completion_address_city_label: "Ort",
  data_completion_address_city_hint: "Bitte geben Sie den Ort an.",
  data_completion_address_city_placeholder: "z.B. Frankfurt am Main",
  data_completion_address_postalCode_label: "Postleitzahl",
  data_completion_address_postalCode_hint: "Bitte geben Sie die Postleitzahl an.",
  data_completion_address_postalCode_placeholder: "z.B. 60487",
  data_completion_address_streetName_label: "Straße",
  data_completion_address_streetName_hint: "Bitte geben Sie die Straße an.",
  data_completion_address_streetName_placeholder: "z.B. Musterstraße",
  data_completion_address_streetNumber_label: "Hausnummer",
  data_completion_address_streetNumber_hint: "Bitte geben Sie die Hausnummer an.",
  data_completion_address_streetNumber_placeholder: "z.B. 12a",
  data_completion_address_addition_label: "Zusatz",
  data_completion_address_addition_placeholder: "z.B. 4. Etage",
  data_completion_address_address_label: "Adresse",
  data_completion_address_address_hint: "Bitte geben Sie Ihre Adresse an.",
  data_completion_address_address_placeholder: "Straße Hausnummer, Stadt",
  data_completion_address_additional_label: "Zusätzliche Adressinformationen",
  data_completion_address_additional_placeholder: "-",
  data_completion_address_phoneNumber_label: "Telefon",
  data_completion_address_phoneNumber_hint: "Bitte geben Sie Ihre Telefonnummer an, z.B. +4915201234567",
  data_completion_address_phoneNumber_placeholder: "z.B. +4915201234567",
  data_completion_address_phoneNumber_alert_message: "Hinweis",
  data_completion_address_phoneNumber_alert_description: "Wir benötigen Ihre Telefonnummer nur in dringenden Fällen bei Rückfragen.",

  // Birth Data Step
  data_completion_birth_title: "Geburtsdaten",
  data_completion_birth_date_label: "Geburtsdatum",
  data_completion_birth_date_hint: "Bitte geben Sie Ihr Geburtsdatum an.",
  data_completion_birth_date_placeholder: "z.B. 01.01.1990",
  data_completion_birth_place_label: "Geburtsort",
  data_completion_birth_place_hint: "Bitte geben Sie Ihren Geburtsort an.",
  data_completion_birth_place_placeholder: "z.B. Frankfurt am Main",
  data_completion_birth_place_country_label: "Geburtsland",
  data_completion_birth_place_country_hint: "Bitte geben Sie Ihr Geburtsland an.",
  data_completion_birth_place_country_placeholder: "z.B. Deutschland",

  // Verification Step
  data_completion_verification_title: "Verifizierung",
  data_completion_verification_type_label: "Ausweistyp",
  data_completion_verification_type_hint: "Bitte geben Sie den Typ ihres Ausweisdokuments an.",
  data_completion_verification_type_id: "Personalausweis",
  data_completion_verification_type_passport: "Reisepass",
  data_completion_verification_number_hint_required: "Bitte geben Sie die Nummer Ihres Dokuments an.",
  data_completion_verification_number_label: "Ausweisnummer",
  data_completion_verification_number_hint_valid: "Die eingegebene Nummer ist ungültig.",
  data_completion_verification_number_placeholder: "z.B. LXXXX1234",
  data_completion_verification_agency_label: "Ausstellende Behörde",
  data_completion_verification_agency_hint: "Bitte geben Sie die ausstellende Behörde an.",
  data_completion_verification_agency_placeholder: "z.B. Stadt Frankfurt",
  data_completion_verification_nationality_label: "Nationalität",
  data_completion_verification_nationality_hint: "Bitte geben Sie Ihre Nationalität an.",
  data_completion_verification_nationality_placeholder: "z.B. Deutschland",

  // Feedback Step
  data_completion_feedback_title: "Feedback",
  data_completion_feedback_recommendation_subtitle: "Woher kennen Sie bettervest?",
  data_completion_feedback_recommendation_placeholder: "-- bitte Auswahl treffen --",
  data_completion_feedback_recommendation_option_financeConsultancy: "Finanzberatung/ Financial advisor",
  data_completion_feedback_recommendation_option_offlineMedia: "Offline Media",
  data_completion_feedback_recommendation_option_onineMedia: "Online Media",
  data_completion_feedback_recommendation_option_socialMedia: "Social Media",
  data_completion_feedback_recommendation_option_searchEngine: "Suchmaschine",
  data_completion_feedback_recommendation_option_triodos: "Triodos",
  data_completion_feedback_recommendation_option_events: "Veranstaltung",
  data_completion_feedback_recommendation_option_recommendation: "Weiterempfehlung",
  data_completion_feedback_recommendation_option_washeldentunPodcast: "WasHeldenTun-Podcast",
  data_completion_feedback_recommendation_option_articlePrint: "Artikel: Grüne Geldanlagen mit sozialer Wirkung",
  data_completion_feedback_recommendation_option_articleOnline: "Artikel: Nachhaltig investieren: Mit der Geldanlage Gutes tun",
  data_completion_feedback_recommendation_option_other: "Sonstiges",
  data_completion_feedback_recommendation_option_tvReport: "TV-Report",
  data_completion_feedback_recommendation_option_Podcast: "Podcast",
  data_completion_feedback_recommendation_option_articleFinanzen: "Artikel: FAZ Beilage - Finanzen 2023",
  data_completion_feedback_recommendation_freeText_placeholder: "Zusätzliche Informationen",
  data_completion_feedback_newsletter_subtitle: "Sie finden Neuigkeiten spannend? Dann melden Sie sich zu unserem Projekt-Newsletter an.",
  data_completion_feedback_newsletter_checkbox: "Ich möchte per Newsletter (E-Mail) über Projekte informiert werden.",
  data_completion_feedback_newsletter_alert_message: "Hinweis",
  data_completion_feedback_newsletter_alert_description:
    "Mit der Einwilligung erklären Sie sich damit einverstanden, Informationen über bettervest und über zukünftige Investitionsprojekte zu erhalten. Der Versand dieser Informationen erfolgt durch die bettervest GmbH, Frankfurt am Main. Die Einwilligung ist freiwillig und kann jederzeit widerrufen werden. Der Widerruf erfolgt durch einen Abbestellwunsch an die auf der Website genannte Kontaktadresse oder durch einen Klick auf den Abbestelllink in den zugesandten Nachrichten."
};

export default de;
