import ITexts from "./ITexts";

const de: ITexts = {
  // Description
  project_side_bar_characteristics: "Characteristics",
  project_side_bar_requiredBudget: "Funding goal",
  project_side_bar_company_value: "Valuation",
  project_side_bar_contractDuration: "Duration",
  project_side_bar_securedLoan_duration: "Origin Term",
  project_side_bar_securedLoan_dueDate: "Due Date",
  project_side_bar_interest: "Interest rate p.a. up to",
  project_side_bar_earlyBirdBonus: "Early Bird Bonus p.a.",
  project_side_bar_initialInterest: "Initital interest rate p.a.",
  project_side_bar_gracePeriods: "Grace Periods",
  project_side_bar_year: "Year",
  project_side_bar_years: "years",
  project_side_bar_genussrecht_description: "Participation"
};

export default de;
