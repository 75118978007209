import React, { useContext, useEffect } from "react";
import Title from "antd/lib/typography/Title";
import { ColProps } from "antd/lib/col";
import Form, { FormInstance } from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import CountryAutoComplete from "components/input/CountryAutoComplete";
import { intlContext } from "translations/context";
import { EVerificationDocumentType } from "types/NaturalPerson";
import { IFormValuesVerification } from "../../types/IFormValues";
import NaturalPerson from "../../types/NaturalPerson";

interface IProps {
  form: FormInstance<IFormValuesVerification>;
  myPerson: NaturalPerson | undefined;
}

const cols: { labelCol: ColProps; wrapperCol: ColProps } = {
  labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 }, xxl: { span: 4 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 12 }, xxl: { span: 10 } }
};

export default function StepVerification({ form, myPerson }: IProps): JSX.Element {
  const { translate } = useContext(intlContext);

  const { setFieldsValue } = form;

  useEffect(() => {
    if (myPerson != null) {
      const fields = mapPersonToFields(myPerson);
      setFieldsValue(fields);
    }
  }, [myPerson, setFieldsValue]);

  return (
    <Form name="DataCompletionMyNaturalPersonVerification" form={form} labelAlign="left" layout="horizontal" style={{ marginBottom: 0 }}>
      <Title level={3}>{translate("data_completion_verification_title")}</Title>
      <Form.Item
        name="verificationDocumentType"
        rules={[
          {
            required: true,
            message: translate("data_completion_verification_type_hint"),
            whitespace: true
          }
        ]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_verification_type_label")}
        {...cols}
      >
        <Select style={{ width: "100%" }}>
          <Select.Option value={EVerificationDocumentType.identityCard}>{translate("data_completion_verification_type_id")}</Select.Option>
          <Select.Option value={EVerificationDocumentType.passport}>{translate("data_completion_verification_type_passport")}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="verificationDocumentNumber"
        rules={[
          {
            required: true,
            message: translate("data_completion_verification_number_hint_required"),
            whitespace: true
          },
          {
            required: true,
            message: translate("data_completion_verification_number_hint_valid"),
            whitespace: true
          }
        ]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_verification_number_label")}
        {...cols}
      >
        <Input placeholder={translate("data_completion_verification_number_placeholder")} />
      </Form.Item>
      <Form.Item
        name="verificationDocumentIssuingAgency"
        rules={[{ required: true, message: translate("data_completion_verification_agency_hint"), whitespace: true }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_verification_agency_label")}
        {...cols}
      >
        <Input placeholder={translate("data_completion_verification_agency_placeholder")} />
      </Form.Item>
      <Form.Item
        name="nationality"
        rules={[{ required: true, message: translate("data_completion_verification_nationality_hint") }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_verification_nationality_label")}
        {...cols}
      >
        <CountryAutoComplete excludeSomeCountries={true} placeholder={translate("data_completion_verification_nationality_placeholder")} />
      </Form.Item>
    </Form>
  );
}

function mapPersonToFields(myPerson: NaturalPerson): IFormValuesVerification {
  return {
    verificationDocumentType: myPerson.verificationDocumentType || EVerificationDocumentType.identityCard,
    verificationDocumentNumber: myPerson.verificationDocumentNumber,
    verificationDocumentIssuingAgency: myPerson.verificationDocumentIssuingAgency,
    nationality: myPerson.nationality
  };
}
