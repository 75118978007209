export const affiliateDashboardApp = "react-app-affiliate-dashboard";
export const confirmEmailApp = "react-app-confirm-email";
export const investButtonContainerApp = "react-app-invest-button-container";
export const investorDashboardApp = "react-app-investor-dashboard";
export const myInvestmentsApp = "react-app-my-investments";
export const investmentApp = "react-app-investment";
export const projectDocumentsApp = "react-app-project-documents";
export const projectFundedListApp = "react-app-project-funded-list";
export const projectListApp = "react-app-project-list";
export const projectOwnerDashboardApp = "react-app-project-owner-dashboard";
export const projectPageHeaderApp = "react-app-project-page-header";
export const projectPageV2App = "react-app-project-page-v2";
export const projectSideBarApp = "react-app-project-side-bar";
export const projectUpdatesApp = "react-app-project-updates";
export const setPasswordApp = "react-app-set-password";
export const userHandlingApp = "slogan";
export const userProfileApp = "react-app-user-profile";
export const usersCanvassUsersApp = "react-app-users-canvass-users";
export const waivePenaltyInterestApp = "react-app-waive-penalty-interest";
export const projectListOverview = "react-app-project-list-overview";
export const eigencrowdApp = "react-app-eigencrowd";
export const myCompanyApp = "react-app-my-company";
export const reminderDashboardApp = "react-app-reminder-dashboard";
export const newsletterSubscription = "react-app-newsletter-subscription";
export const presseNewsletterSubscription = "react-app-presse-newsletter-subscription";
export const websiteHomeInfo = "react-app-website-home-info";
export const rvoProjectListApp = "react-app-rvo-project-list";
export const websiteHomeInfoImpact = "react-app-website-home-info-impact";
export const websiteHomeInfoGraphs = "react-app-website-home-info-graphs";
export const websiteHomeInfoRiskmitigation = "react-app-website-home-info-riskmitigation";
