import ITexts from "./ITexts";

const en: ITexts = {
  // Main View
  my_company_intro: `You want to invest on bettervest.com on behalf of a company?
  <br/> Here you can add your company account. Please first enter the company name and the form of the company. Then please fill in all fields with the company data to complete the company profile.`,
  my_company_intro2:
    "For the registration of a company, we also need other documents such as the extract from the commercial register. After you have created the company account, we will send you an e-mail with further information and the necessary documents. ",
  my_company_intro3: "Error",
  my_company_intro4: `Sorry, no reply could be received from the server. Please email <a href="mailto:mail@bettervest.com?subject=Error loading my investments">mail@bettervest.com</a>.`
};

export default en;
