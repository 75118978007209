import ITexts from "./ITexts";

const de: ITexts = {
  userprofile_menu_personalData: "Persönliche Daten",
  userprofile_menu_legalData: "Juristische Person",
  userprofile_menu_password: "Passwort",
  userprofile_menu_account: "Bankverbindung",
  userprofile_menu_deposit: "Depotverbindung",
  userprofile_menu_email: "E-Mail-Adresse",
  userprofile_menu_notification: "Benachrichtigung",

  userprofile_email_message_success: "E-Mail wurde an Sie versandt.",
  userprofile_email_message_error: "Fehler beim Versand der E-Mail",
  userprofile_email_current_email_address: "Aktuelle E-Mail-Adresse",
  userprofile_email_not_confirmed_message: "E-Mail-Adresse nicht bestätigt",
  userprofile_email_not_confirmed_description: "Bitte klicken Sie zur Bestätigung auf den Link in der E-Mail, die wir Ihnen gesendet haben.",
  userprofile_email_change_email_address: "E-Mail-Adresse ändern",
  userprofile_email_alert_message:
    "Wenn Sie Ihre E-Mail-Adresse geändert haben, erhalten Sie von uns eine E-Mail an die neue Adresse zur Bestätigung Ihrer neuen E-Mail-Adresse.",
  userprofile_email_newEmail_hint: "Bitte geben Sie Ihre neue E-Mail-Adresse an.",
  userprofile_email_newEmail_placeholder: "Neue E-Mail-Adresse",
  userprofile_email_password_hint: "Bitte geben Sie Ihr aktuelles Passwort an.",
  userprofile_email_password_placeholder: "Aktuelles Passwort",

  userprofile_personalData_feedback_title: "Feedback",
  userprofile_personalData_feedback_recommendation_subtitle: "Woher kennen Sie bettervest?",
  userprofile_personalData_feedback_recommendation_none: "Keine Angabe",
  userprofile_personalData_feedback_recommendation_internetSearch: "Internetsuchmaschine",
  userprofile_personalData_feedback_recommendation_friend: "Freund",

  userprofile_notification_alert_message: "Hinweis",
  userprofile_notification_alert_description: "Über den Newsletter können wir Sie über Projekte die bald starten informieren.",
  userprofile_notification_newsletter_title: "Projektstart-Newsletter",
  userprofile_notification_newsletter_subtitle: "Sie finden Neuigkeiten spannend? Dann melden Sie sich zu unserem Projekt-Newsletter an.",
  userprofile_notification_newsletter_checkbox: "Ich möchte per Newsletter (E-Mail) über Projekte informiert werden.",
  userprofile_notification_newsletter_alert:
    "Mit der Einwilligung erklären Sie sich damit einverstanden, Informationen über bettervest und über zukünftige Investitionsprojekte zu erhalten. Der Versand dieser Informationen erfolgt durch die bettervest GmbH, Frankfurt am Main. Die Einwilligung ist freiwillig und kann jederzeit widerrufen werden. Der Widerruf erfolgt durch einen Abbestellwunsch an die auf der Website genannte Kontaktadresse oder durch einen Klick auf den Abbestelllink in den zugesandten Nachrichten.",

  userprofile_password_message_success: "Passwort geändert",
  userprofile_password_message_error: "Etwas ist schief gelaufen. Versuchen Sie es bitte später erneut.",
  userprofile_password_alert_message: "Passwort ändern",
  userprofile_password_alert_description: "Wir schicken Ihnen eine E-Mail mit einem Link über den Sie Ihr neues Passwort bestimmen können.",
  userprofile_password_change_password_button: "Passwort ändern",

  userprofile_personalData_address_title: "Meldeadresse",
  userprofile_personalData_address_country_label: "Land",
  userprofile_personalData_address_country_hint: "Bitte geben Sie Ihr Land an.",
  userprofile_personalData_address_country_placeholder: "Land",
  userprofile_personalData_address_city_label: "Ort",
  userprofile_personalData_address_city_hint: "Bitte geben Sie den Ort an.",
  userprofile_personalData_address_city_placeholder: "z.B. Frankfurt am Main",
  userprofile_personalData_address_postalCode_label: "Postleitzahl",
  userprofile_personalData_address_postalCode_hint: "Bitte geben Sie die Postleitzahl an.",
  userprofile_personalData_address_postalCode_placeholder: "z.B. 60487",
  userprofile_personalData_address_streetName_label: "Straße",
  userprofile_personalData_address_streetName_hint: "Bitte geben Sie die Straße an.",
  userprofile_personalData_address_streetName_placeholder: "z.B. Musterstraße",
  userprofile_personalData_address_streetNumber_label: "Hausnummer",
  userprofile_personalData_address_streetNumber_hint: "Bitte geben Sie die Hausnummer an.",
  userprofile_personalData_address_streetNumber_placeholder: "z.B. 12a",
  userprofile_personalData_address_addition_label: "Zusatz",
  userprofile_personalData_address_addition_placeholder: "z.B. 4. Etage",
  userprofile_personalData_address_address_hint: "Bitte geben Sie Ihre Adresse an.",
  userprofile_personalData_address_address_placeholder: "Straße Hausnummer, Stadt",
  userprofile_personalData_address_additional_placeholder: "Zusätzliche Adressinformationen",
  userprofile_personalData_address_phoneNumber_label: "Telefonnummer",
  userprofile_personalData_address_phoneNumber_hint: "Bitte geben Sie Ihre Telefonnummer an, z.B. +4915208753100",
  userprofile_personalData_address_phoneNumber_placeholder: "z.B. +4915208753100",
  userprofile_personalData_birth_title: "Geburtsdaten",
  userprofile_personalData_birth_date_label: "Geburtstag",
  userprofile_personalData_birth_date_hint: "Bitte geben Sie Ihr Geburtsdatum an.",
  userprofile_personalData_birth_date_placeholder: "z.B. 01.01.1990",
  userprofile_personalData_birth_place_label: "Geburtsort",
  userprofile_personalData_birth_place_hint: "Bitte geben Sie Ihren Geburtsort an.",
  userprofile_personalData_birth_place_placeholder: "z.B. Frankfurt am Main",
  userprofile_personalData_birthData_nationality_label: "Nationalität",
  userprofile_personalData_birthData_nationality_hint: "Bitte geben Sie Ihre Nationalität an.",
  userprofile_personalData_birthData_nationality_placeholder: "Deutschland",
  userprofile_personalData_message_update_success: "Persönliche Daten geändert",
  userprofile_personalData_message_update_error: "Etwas ist schief gelaufen. Probieren Sie es bitte später erneut.",
  userprofile_personalData_name_title: "Name",
  userprofile_personalData_name_first_label: "Vorname",
  userprofile_personalData_name_first_hint: "Bitte geben Sie Ihren Vornamen an.",
  userprofile_personalData_name_first_placeholder: "z.B. Max",
  userprofile_personalData_name_last_label: "Nachname",
  userprofile_personalData_name_last_hint: "Bitte geben Sie Ihren Nachnamen an.",
  userprofile_personalData_name_last_placeholder: "z.B. Mustermann",
  userprofile_personalData_name_gender_label: "Geschlecht",
  userprofile_personalData_name_gender_hint: "Bitte geben Sie Ihr Geschlecht an.",
  userprofile_personalData_name_gender_placeholder: "z.B. männlich",
  userprofile_personalData_name_gender_male: "männlich",
  userprofile_personalData_name_gender_female: "weiblich",
  userprofile_personalData_name_gender_diverse: "divers",
  userprofile_personalData_verification_title: "Verifizierung",
  userprofile_personalData_verification_type_label: "Ausweistyp",
  userprofile_personalData_verification_type_hint: "Bitte geben Sie den Typ Ihres Ausweisdokuments an.",
  userprofile_personalData_verification_type_id: "Personalausweis",
  userprofile_personalData_verification_type_passport: "Reisepass",
  userprofile_personalData_verification_number_label: "Ausweisnummer",
  userprofile_personalData_verification_number_hint_required: "Bitte geben Sie die Nummer Ihres Dokuments an.",
  userprofile_personalData_verification_number_hint_valid: "Die eingegebene Nummer ist ungültig.",
  userprofile_personalData_verification_number_placeholder: "z.B. LX1234567",
  userprofile_personalData_verification_agency_label: "Ausstellende Behörde",
  userprofile_personalData_verification_agency_hint: "Bitte geben Sie die ausstellende Behörde an.",
  userprofile_personalData_verification_agency_placeholder: "z.B. Stadt FrankfurtAusstellende Behörde",
  userprofile_personalData_tax_title: "Steuer",
  userprofile_personalData_taxId_label: "Steueridentifikationsnummer",
  userprofile_personalData_taxId_placeholder: "z.B. 12345678912",
  userprofile_personalData_tax_number_hint_required: "Steueridentifikationsnummer",

  // Legal Person
  userprofile_legalPerson_position_label: "Position in der Firma",
  userprofile_legalPerson_position_placeholder: "Geschäftsführer /-in",
  userprofile_legalPerson_companyname_label: "Name der Gesellschaft",
  userprofile_legalPerson_companyname_placeholder: "z.B. Musterfirma",
  userprofile_legalPerson_legalform_label: "Rechtsform",
  userprofile_legalPerson_legalform_placeholder: "z.B. GmbH",
  userprofile_legalPerson_foundingDate_label: "Datum der Gründung",
  userprofile_legalPerson_foundingDate_placeholder: "01.01.1999",
  userprofile_legalPerson_address_title: "Geschäftsaddresse",
  userprofile_legalPerson_address_label: "Anschrift",
  userprofile_legalPerson_address_placeholder: "z.B. Musterstraße 5, 12345 Musterstadt",
  userprofile_legalPerson_court_label: "Registerführende Stelle",
  userprofile_legalPerson_court_placeholder: "z.B. Amtsgericht Musterstadt",
  userprofile_legalPerson_registerId_label: "Handelsregistereintrag",
  userprofile_legalPerson_registerId_placeholder: "z.B. HRB 123",
  userprofile_legalPerson_taxId_label: "Steuernummer",
  userprofile_legalPerson_taxId_placeholder: "z.B. 1234567890",

  // Account
  userprofile_account_alert_message: "Sie wollen Ihre Bankverbindung ändern?",
  userprofile_account_alert_description: `
    1. Bitte passen Sie die hinterlegten Daten in der vorgegebenen Maske an.
    <br />
    2. Füllen Sie bitte ebenfalls <a href="https://api.bettervest.com/files/Aenderung_Stammdaten_Formblatt.pdf" target="_blank" rel="noopener noreferrer"><b> folgendes Formular </b> </a> unseres Treuhänders Secupay AG aus und senden <br>
    den Scan per E-Mail direkt an: <a href="mailto:info@secupay.com">info@secupay.com</a> oder postalisch an: Secupay AG, Goethestraße 6, 01896 Pulsnitz. <br>
    Secupay wird die Änderung dann speichern und bei zukünftigen Auszahlungen verwenden.
    <br /> <br />
    <i>
      Falls Sie bislang allerdings noch keine Überweisung an das Treuhandkonto bei Secupay vorgenommen haben oder noch keine Kontodaten im Profil
      hinterlegt haben, brauchen Sie dieses Formular <b>nicht</b> auszufüllen.
    </i>
  `,
  userprofile_account_change_title: "Bankverbindung"
};

export default de;
