import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import KeyOutlined from "@ant-design/icons/KeyOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import FormattedMessage from "components/FormattedMessage";
import * as actions from "../models/actions";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";

interface IProps {
  afterClose?: () => void;
  registerModalInitiallyVisible?: boolean;
}

export default function NotLoggedInView({ afterClose, registerModalInitiallyVisible }: IProps): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    if (registerModalInitiallyVisible === true) dispatch(actions.setRegisterModalVisible(true));
  }, [dispatch, registerModalInitiallyVisible]);

  return (
    <div style={{ textAlign: "right" }}>
      <Space size="small" direction="horizontal" align="end">
        <Button onClick={() => dispatch(actions.setLoginModalVisible(true))} style={{ marginRight: 6 }}>
          <KeyOutlined />
          <FormattedMessage id="userhandling_login" />
        </Button>
        <Button
          id="open-register-modal"
          type="primary"
          onClick={() => {
            dispatch(actions.setRegisterModalVisible(true));
            trackInMatomo();
          }}
        >
          <UserAddOutlined />
          <FormattedMessage id="userhandling_register" />
        </Button>
      </Space>
      <LoginModal />
      <RegisterModal afterClose={afterClose} />
    </div>
  );
}

let registerModalOpenWasTrackedAlready = false;

function trackInMatomo(): void {
  const paq = (window as any)._paq;
  if (paq == null || registerModalOpenWasTrackedAlready === true) return;
  paq.push(["trackEvent", "Registration", "Open Register Modal"]);
  registerModalOpenWasTrackedAlready = true;
}
