/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Menu from "antd/lib/menu";
import FormattedMessage from "components/FormattedMessage";
const { SubMenu } = Menu;

const listItemStyle: React.CSSProperties = {
  marginRight: 0
};

export default function MyBettervestMenu(props: object): JSX.Element {
  return (
    <div style={{ float: "right" }}>
      <Menu selectedKeys={["menu"]} mode="horizontal" style={{ borderBottomWidth: 0, padding: 0, lineHeight: "32px" }}>
        <SubMenu
          key="menu"
          style={{
            backgroundColor: "#8cc443",
            color: "white",
            paddingLeft: 12,
            paddingRight: 12,
            marginRight: 0,
            height: "32px",
            lineHeight: "32px"
          }}
          title={
            <span style={{ color: "white", marginTop: "5px" }}>
              <FormattedMessage id="userhandling_menu_title" />
            </span>
          }
        >
          <Menu.Item key="user-profile" style={listItemStyle}>
            <a href="/de/mein-profil">
              <FormattedMessage id="userhandling_menu_my_profile" />
            </a>
          </Menu.Item>
          <Menu.Item key="my-investments" style={listItemStyle}>
            <a href="/de/meine-investitionen">
              <FormattedMessage id="userhandling_menu_my_investments" />
            </a>
          </Menu.Item>
          {/* <Menu.Item key="my-benefits" style={listItemStyle}>
            <a href="/de/meine-vorteile">
              <FormattedMessage id="userhandling_menu_my_benefits" />
            </a>
          </Menu.Item> */}
          <Menu.Item key="my-projects" style={listItemStyle}>
            <a href="/de/meine-projekte">
              <FormattedMessage id="userhandling_menu_my_projects" />
            </a>
          </Menu.Item>
          <Menu.Item key="my-company" style={listItemStyle}>
            <a href="/de/mein-unternehmen">
              <FormattedMessage id="userhandling_menu_my_company" />
            </a>
          </Menu.Item>
          <Menu.Item key="version" disabled style={listItemStyle}>
            Version: {process.env.REACT_APP_VERSION}
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
}
