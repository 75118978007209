import ITexts from "./ITexts";

const en: ITexts = {
  // Main View
  my_investments_main_open_data_completion_button: "Please complete your profile first",
  my_investments_main_not_logged_in_alert_message: "Please log in / register",
  my_investments_error_occurred_alert_message: "Error",
  my_investments_error_occurred_alert_description: `Sorry, no reply was received from the server. Please email <a href="mailto:mail@bettervest.com?subject=Error loading my investments">mail@bettervest.com</a>.`,

  // Earnings report
  my_investments_earnings_report_title: "Earnings Report",
  my_investments_earnings_report_title_2:
    "Here you can download your tax certificates for 2023, which were provided by our payment service provider Secupay. Please note that Secupay did not carry out the tax deduction until March 2023: ",
  my_investments_earnings_report_alert_message:
    "The earnings statement is automatically generated as a PDF for download on January 1st of the year following the first payment.",
  my_investments_earnings_report_alert_message2: `Due to the tax deduction carried out for the first time in 2023 by our trustee Secupay AG, we are currently revising the presentation of the "Erträgnisaufstellung". The "Erträgnisaufstellung" that was previously provided for download is no longer available as it is no longer useful.`,
  my_investments_earnings_report_alert_message3:
    "Since 2023, our payment service provider Secupay Ag has been legally responsible for the deduction of taxes on interest payments before paying out to crowd investors. At the end of the first quarter of the folllowing year, you will receive a tax certificate from Secupay AG detailing the total amount of taxes that were deducted at the source.",
  my_investments_earnings_report_title_sub: "There are currently no statements of income available",
  // Statistics Row
  my_investments_statistics_title: "Overview",
  my_investments_statistics_count: "Number of investments",
  my_investments_statistics_investmentSum: "Investment Sum",
  my_investments_statistics_avgAmount: "Average Amount",
  my_investments_statistics_interestSum: "Interest paid",
  my_investments_statistics_residualLoan: "Residual Loan",
  my_investments_statistics_row_alert_1: "* includes interest received",
  my_investments_statistics_row_alert_2: "** includes default interest",

  // Investment table
  my_investments_investment_table_title: "List of Investments",
  my_investments_investment_table_options_current: "Current investments",
  my_investments_investment_table_options_repaid: "Repaid investments",
  my_investments_investment_table_options_cancelled: "Canceled investments",
  my_investments_investment_table_options_insolvent: "Insolvencies",
  my_investments_investment_table_options_all: "All Investments",
  my_investments_investment_table_column_date_title: "Date",
  my_investments_investment_table_column_projectName_title: "Project",
  my_investments_investment_table_column_duration_title: "Duration",
  my_investments_investment_table_column_interestRate_title: "Regular Yield",
  my_investments_investment_table_column_initialInterestRate_title: "Initial Interest Rate",
  my_investments_investment_table_column_amount_title: "Amount",
  my_investments_investment_table_column_action_title: "Action",
  my_investments_investment_table_column_action_details: "Details",
  my_investments_investment_table_column_action_repayments: "Repayments",
  my_investments_investment_table_column_action_project_updates: "Action",
  my_investments_investment_table_options_current_info: "It may take up to 8 days for a payout to appear",
  my_investments_investment_table_options_current_description2:
    "This is because we first have to wait for denied transfers from our payment service provider.",

  // investment details
  my_investments_investment_detail_title: "Project Owner",
  my_investments_investment_detail_email_link: "Email to project owner",
  my_investments_investment_detail_email_subject: "Questions about the project at bettervest",
  my_investments_investment_detail_project: "Project",
  my_investments_investment_detail_timeline_title: "Investment status",
  my_investments_investment_detail_timeline_submitted: "Investment submission",
  my_investments_investment_detail_timeline_paid: "Payment received by trustee",
  my_investments_investment_detail_timeline_revocationFree: "Revocation period expired",
  my_investments_investment_detail_timeline_active: "Project start / Start of interest calculation on",
  my_investments_investment_detail_timeline_active_secured: "Deposit entry",
  my_investments_investment_detail_timeline_cancelled: "Investment cancelled",
  my_investments_investment_detail_timeline_repaid: "Loan repaid",
  my_investments_investment_detail_timeline_insolvent: "Project insolvent",
  my_investments_investment_detail_payin_title: "Payment Information",
  my_investments_investment_detail_payin_owner: "Account holder",
  my_investments_investment_detail_payin_iban: "IBAN",
  my_investments_investment_detail_payin_bic: "BIC",
  my_investments_investment_detail_payin_bankname: "Credit institution",
  my_investments_investment_detail_payin_amount: "Amount",
  my_investments_investment_detail_payin_purpose: "Reason for payment",
  my_investments_investment_detail_waivePenaltyInterest_title: "Waive default interest",
  my_investments_investment_detail_waivePenaltyInterest_text: "I waive all default interest payments",
  my_investments_investment_detail_waivePenaltyInterest_optOut_1: `
    By withdrawing the waiver of interest on arrears, you will receive on future repayments for the project "`,
  my_investments_investment_detail_waivePenaltyInterest_optOut_2: `" default interest again,
    calculated for the full delay period. The withdrawal of the waiver of default interest has no effect on past payments.
    <br />
    <br />
    If you wish to confirm the withdrawal of the waiver of future default interest, please click on "Confirm".
    You will then receive an email from bettervest with a confirmation letter.
    <br />
    <br />
    You can opt out of late payment interest at any time on <a href="https://www.bettervest.com/de/meine-investitionen/" rel="nofollow" target="_blank">https://www.bettervest.com/de/meine-investitionen/</a> again agree to the corresponding investment in the "Details" area.
    Similarly, the waiver will only affect payments made after the waiver was approved.
  `,
  my_investments_investment_detail_waivePenaltyInterest_optOut_toast: "We have sent you a confirmation email.",
  my_investments_investment_detail_waivePenaltyInterest_optIn_1: `
    If you agree to waive interest on arrears, future project repayments will give you "`,
  my_investments_investment_detail_waivePenaltyInterest_optIn_2: `" no interest on arrears.
    The waiver of default interest has no effect on past payments.
    <br />
    <br />
    If you would like to agree to the waiver of future default interest, please click "Confirm".
    You will then receive an email from bettervest with a confirmation letter.
    <br />
    <br />
    You can opt out at any time at <a href="https://www.bettervest.com/de/meine-investitionen/" rel="nofollow" target="_blank">https://www.bettervest.com/de/meine-investitionen/</a> in the "Details" area of ​​the corresponding investment.
    Likewise, the reversal only affects payments made after the reversal.
  `,
  my_investments_investment_detail_waivePenaltyInterest_optIn_toast: "We've sent you a confirmation email.",
  my_investments_investment_detail_reportings_title: "Reporting",
  my_investments_investment_detail_documents_title: "Documents",
  my_investments_investment_detail_links_title: "Links",
  my_investments_investment_detail_links_to_project_page: "To the project page",
  my_investments_investment_detail_send_as_gift_1: "This investment was sent as gift to: ",
  my_investments_investment_detail_send_as_gift_2: "",

  // Investment repayments
  my_investments_investment_repayments_title: "Repayments",
  my_investments_investment_repayments_secured:
    "Please refer to your securities purchase agreement for the terms of the pending interest and principal payments. Since your securities are held and managed via a securities account, the actual payment details are not shown here. If you have any questions, please contact the issuer or the custodian bank.",
  my_investments_investment_repayments_empty_message: "The repayment table will be available at the latest at the start of the project.",
  my_investments_investment_repayments_can_take_eight_days: "It can take up to 8 days for a payout to appear.",
  my_investments_investment_repayments_column_period_title: "Period",
  my_investments_investment_repayments_column_date_title: "Repayment date",
  my_investments_investment_repayments_column_repayment_title: "Repayment",
  my_investments_investment_repayments_column_interest_title: "Interest",
  my_investments_investment_repayments_column_taxKEST_title: "Withholding tax",
  my_investments_investment_repayments_column_taxCAPI_title: "Capital Gains Tax",
  my_investments_investment_repayments_column_taxSOLI_title: "Solidarity surcharge",
  my_investments_investment_repayments_column_taxKIST_title: "Church tax",
  my_investments_investment_repayments_column_amount_title: "Repayment",
  my_investments_investment_repayments_column_residualLoan_title: "Residual loan",
  my_investments_investment_repayments_subject: "Subject",
  my_investments_investment_repayments_others_irregular_payment: "Special payment",
  my_investments_investment_repayments_others_partial_payment: "Partial payment",
  my_investments_investment_repayments_others_planned_for: "expected",
  my_investments_investment_repayments_others_delayed: "delayed",
  my_investments_investment_repayments_others_info_via_mail: "Info via email",
  my_investments_investment_repayments_tax_deduction:
    "Our payment services provider, Secupay AG, is since 2023 required to withhold taxes on interest payments, prior to disbursement of interest to crowd investors. You will receive a tax statement from Secupay at year-end with respect to withholding taxes paid.",

  // Project Updates
  my_investments_investment_project_updates_title: "Implementation",
  my_investments_statistics_title_2: "Repayments",
  my_investments_statistics_title_info: "These numbers are not guaranteed.",
  my_investments_statistics_investment_sum: "Total Invested",
  my_investments_statistics_investment_headline1: "Projects fully repaid",
  my_investments_statistics_investment_headline2: "Projects without delay (delay less than 90 days)",
  my_investments_statistics_investment_headline3: "Projects in delay",
  my_investments_statistics_investment_headline4: "Insolvent projects",
  my_investments_statistics_principal_repaid: "Repayment received",
  my_investments_statistics_interest_repaid: "Interest received **",
  my_investments_statistics_interest_total: "Total interest over loan tenor *",
  my_investments_statistics_principal_open: "Open loan balance",
  my_investments_statistics_losts: "Loss"
};

export default en;
