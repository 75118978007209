import ITexts from "./ITexts";

const de: ITexts = {
  confirmEmail_title: "bettervest",
  confirmEmail_success: "Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse.",
  confirmEmail_error:
    "Etwas ist schief gelaufen. Bitte klicken Sie später erneut auf den Link zur Bestätigung. Eventuell ist der Link nicht mehr aktuell.",
  confirmEmail_no_token: "Der Link enthält keinen Token.",
  confirmEmail_loading: "Wir prüfen den Token aus der E-Mail. Einen Moment bitte."
};

export default de;
