import ITexts from "./ITexts";

const en: ITexts = {
  // Description
  project_list_item_go_to_project: "To the project",
  project_list_item_go_to_project_coming_soon: "Coming soon online",
  project_list_item_go_to_project_crowd_cube: "Equity",
  project_list_item_go_to_project_coming_soon_redirect: "To the campaign"
};

export default en;
