import Cookies from "js-cookie";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import legalFormId from "constants/legalFormId";
import localeId from "constants/localeId";
import tenantId from "constants/tenantId";
import State from "../../models/State";
import * as actions from "../models/actions";
import initiateEmailProof from "../graphql/initiateEmailProof";
import register, { createLegalPerson, IRegistrationInput } from "../graphql/register";
import { FormInstance } from "antd/lib/form";

export enum ERegisterAs {
  naturalPerson = "naturalPerson",
  legalPerson = "legalPerson"
}

export interface RegisterFormValues {
  registerAs: ERegisterAs;
  email: string | undefined;
  password: string | undefined;
  passwordRepeated: string | undefined;
  approvedDocumentTemplates: { [key: string]: boolean };
  subscribedToNewsletter: boolean;
  companyName: string | undefined;
}

interface IParams {
  form: FormInstance<RegisterFormValues>;
  onError: (err: any) => void;
  onSuccess: () => void;
}

export default function useRegisterApi({ form, onError, onSuccess }: IParams) {
  const { loading } = useSelector((state: State) => ({
    loading: state.userHandling?.registerLoading ?? false
  }));
  const dispatch = useDispatch();

  const handle = useCallback(async () => {
    try {
      const { email, password, registerAs, approvedDocumentTemplates, subscribedToNewsletter, companyName } = await form.validateFields();
      if (email == null || password == null) return;
      const input: IRegistrationInput = {
        tenantId,
        localeId,
        legalFormId,
        email,
        password,
        subscribedToNewsletter,
        generalDocumentTemplateSetPayload: approvedDocumentTemplates,
        affiliateConditionId: Cookies.get("r"),
        affiliateCampaign: Cookies.get("c")
      };

      dispatch(actions.setRegisterLoading(true));
      try {
        if (registerAs === ERegisterAs.legalPerson) {
          let legalPersonId;
          if (companyName === undefined) throw new Error("NAME_IS_NULL");
          legalPersonId = await createLegalPerson(companyName, legalFormId);
          input.legalPersonId = legalPersonId;
        }
        await register(input);
        await initiateEmailProof({ tenantId, email, info: "REGISTER" });
        if (onSuccess) onSuccess();
        trackInMatomo();
        trackInGoogleAds();
        trackInBingAds();
      } catch (err) {
        console.log({ msg: "graphql.createUser.error", err });
        if (onError) onError(err);
      }

      dispatch(actions.setRegisterLoading(false));
    } catch (errorInfo) {
      console.log({ errorInfo });
      return;
    }
  }, [dispatch, form, onError, onSuccess]);

  return { handle, loading };
}

function trackInMatomo(): void {
  try {
    const paq = (window as any)._paq;
    if (paq == null) return;
    paq.push(["trackEvent", "Registration", "Register"]);
  } catch (err) {
    console.warn("error when tracking matomo");
  }
}

function trackInGoogleAds(): void {
  try {
    const gtag = (window as any).gtag;
    if (gtag == null) return;
    gtag("event", "conversion", { send_to: "AW-977184238/L_CuCPqDtQcQ7sv60QM" });
  } catch (err) {
    console.warn("error when tracking googls ads");
  }
}

function trackInBingAds(): void {
  try {
    const uetq = (window as any).uetq;
    if (uetq == null) return;
    uetq.push("event", "registration", {
      event_category: "registration",
      event_label: "registration",
      event_value: 0
    });
  } catch (err) {
    console.warn("error when tracking bing ads");
  }
}
