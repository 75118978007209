import { fetchUserInfo } from "./actions";
import rootReducer from "./reducers";
import { AnyAction } from "redux";

export function getUserInfoModule() {
  return {
    id: "user-info",
    reducerMap: {
      userInfo: rootReducer
    },
    initialActions: [(fetchUserInfo() as any) as AnyAction]
  };
}
