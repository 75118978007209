import ITexts from "./ITexts";

const de: ITexts = {
  project_owner_dashboard_error: "Fehler",
  project_owner_dashboard_error_description: `
    Es ist ein Fehler aufgetreten.
    Bitte kontaktieren Sie Ihren Ansprechpartner bei bettervest oder
    unseren Support unter <a href="mailto:mail@bettervest.com">mail@bettervest.com</a> mit folgender Fehlermeldung:
  `,
  project_owner_dashboard_currently_not_available: "Momentan noch nicht verfügbar",

  // MainView
  project_owner_dashboard_no_projects: "Sie haben zur Zeit keine Projekte.",
  project_owner_dashboard_project: "Projekt",
  project_owner_dashboard_select_project: "Projekt auswählen",

  // Overview
  project_owner_dashboard_overview_title: "Übersicht",
  project_owner_dashboard_overview_company_name: "Firmenname",
  project_owner_dashboard_overview_start_date: "Funding Startdatum",
  project_owner_dashboard_overview_end_date: "Funding Enddatum",
  project_owner_dashboard_overview_investiments: "Investitionen",
  project_owner_dashboard_overview_number_investments: "Anzahl Investitionen",
  project_owner_dashboard_overview_amount_collected: "Gezeichneter Betrag",
  project_owner_dashboard_overview_revocation_free: "Widerrufsfreie Investitionen",
  project_owner_dashboard_overview_revocation_free_amount: "Widerrufsfreier Betrag",
  project_owner_dashboard_overview_principal_to_pay: "Zu zahlende Tilgung",
  project_owner_dashboard_overview_interest_to_pay: "Zu zahlende Zinsen",
  project_owner_dashboard_overview_emission: "Emissionen",
  project_owner_dashboard_overview_emission_description: "Emission:",

  // Outstanding
  project_owner_dashboard_outstanding_title: "Darlehensstatus an bestimmbaren Datum",
  project_owner_dashboard_outstanding_select_date: "Datum auswählen: ",
  project_owner_dashboard_outstanding_paid_principal_title: "Geleistete Tilgung",
  project_owner_dashboard_outstanding_paid_interest_title: "Geleistete Zinszahlungen",
  project_owner_dashboard_outstanding_due_principal_title: "Fällige Tilgungszahlung",
  project_owner_dashboard_outstanding_due_interest_title: "Fällige Zinsen",
  project_owner_dashboard_outstanding_due_total_title: "Fällige Tilgungszahlungt + Fällige Zinsen",
  project_owner_dashboard_outstanding_outstanding_principal_title: "Offene Tilgungszahlungen",
  project_owner_dashboard_outstanding_outstanding_interest_title: "Offene Zinszahlungen",
  project_owner_dashboard_outstanding_outstanding_total_title: "Offen insgesamt",
  project_owner_dashboard_outstanding_paid_principal: `<b>Geleistete Tilgung:</b> Geleistete Tilgung bis zu dem ausgewählten Datum`,
  project_owner_dashboard_outstanding_paid_interest: `<b>Geleistete Zinszahlungen:</b> Geleistete Zinszahlungen bis zu dem ausgewählten Datum`,
  project_owner_dashboard_outstanding_due_principal: `<b>Fällige Tilgungszahlung:</b> (Über-) fällige Tilgung an dem ausgewählten Datum`,
  project_owner_dashboard_outstanding_due_interest: `<b>Fällige Zinsen:</b> (Über-) fällige Zinsen an dem ausgewählten Datum`,
  project_owner_dashboard_outstanding_due_total: `<b>Fällige Tilgungszahlungt + Fällige Zinsen:</b> (Über-) fällige Zinsen + (Über-) fällige Tilgung an dem ausgewählten Datum`,
  project_owner_dashboard_outstanding_outstanding_principal: `<b>Offene Tilgungszahlungen:</b> Offene Tilgungszahlungen (fällig und nicht fällig) zu dem ausgewählten Datum`,
  project_owner_dashboard_outstanding_outstanding_interest: `<b>Offene Zinszahlungen:</b> Offene Zinszahlungen (fällig und nicht fällig) an dem gewählten Datum`,
  project_owner_dashboard_outstanding_outstanding_total: `<b>Offen insgesamt:</b> Offene geplante Zahlungen (fällig und nicht fällig) an dem ausgewählten Datum. Entspricht der Summe aus offenen Tilgungen und offenen Zinszahlungen.`,
  project_owner_dashboard_outstanding_outstanding_disclaimer:
    "** Bitte beachten Sie, dass in dieser Übersicht nur die geplanten Tilgungs- und Zinszahlungen berücksichtigt werden. Gezahlte oder fällige Verzugszinsen sind nicht enthalten. **",

  // Repayment
  project_owner_dashboard_repayment_title: "Rückzahlungen / Tilgungsplan",
  project_owner_dashboard_repayment_planned_repayments: "Tilgungsplan",
  project_owner_dashboard_repayment_actual_repayments: "Rückzahlungen",
  project_owner_dashboard_repayment_period: "Periode",
  project_owner_dashboard_repayment_repayment: "Tilgung",
  project_owner_dashboard_repayment_interest: "Zinsen",
  project_owner_dashboard_repayment_penaltyInterest: "Verzugszinsen",
  project_owner_dashboard_repayment_planned_date: "Geplantes Datum",
  project_owner_dashboard_repayment_actual_date: "Datum",
  project_owner_dashboard_repayment_residual_loan: "Restschuld",

  // Investments
  project_owner_dashboard_investment_title: "Investitionen",
  project_owner_dashboard_investment_created_at: "Gezeichnet am",
  project_owner_dashboard_investment_paid_at: "Bezahlt am",
  project_owner_dashboard_investment_interest_rate: "Zinssatz",
  project_owner_dashboard_investment_contract_duration: "Laufzeit",
  project_owner_dashboard_investment_grace_period: "Tilgungsfreie Perioden",
  project_owner_dashboard_investment_initial_interest_rate: "Vorlaufzinssatz",
  project_owner_dashboard_investment_amount: "Betrag",
  project_owner_dashboard_investment_export: "Investitionen herunterladen",
  project_owner_dashboard_investment_hint: "In dieser Liste sind nur widerrufsfreie Investitionen abgebildet.",

  // Updates
  project_owner_dashboard_update_title: "Updates",

  // Yearlyfees
  project_owner_dashboard_yearlyfee_title: "Yearly fees",
  project_owner_dashboard_yearlyfee_title_description: "Yearly Fee Zahlungen",
  project_owner_dashboard_yearlyfee_period: "Periode",
  project_owner_dashboard_yearlyfee_invoice_number: "Rechnungsnr.",
  project_owner_dashboard_yearlyfee_planned_date: "Geplantes Datum",
  project_owner_dashboard_yearlyfee_invoice_amount_net: "Rechnungsbetrag Netto",
  project_owner_dashboard_yearlyfee_gross_amount_paid: "Bezahlt Brutto",
  project_owner_dashboard_yearlyfee_date_of_payment: "Zahlungsdatum"
};

export default de;
