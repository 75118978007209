import ITexts from "./ITexts";

const en: ITexts = {
  confirmEmail_title: "bettervest",
  confirmEmail_success: "Thank you for verifying your email address",
  confirmEmail_error: "Something went wrong. Please try again later to confirm your email address. The link might no longer be valid.",
  confirmEmail_no_token: "The link includes no token.",
  confirmEmail_loading: "Validating the token. One moment please."
};

export default en;
