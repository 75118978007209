import React, { useContext, useEffect } from "react";
import { ColProps } from "antd/lib/col";
import Title from "antd/lib/typography/Title";
import Form, { FormInstance } from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import { intlContext } from "translations/context";
import { EGender } from "types/NaturalPerson";
import NaturalPerson from "../../types/NaturalPerson";
import { IFormValuesName } from "../../types/IFormValues";

interface IProps {
  form: FormInstance<IFormValuesName>;
  myPerson: NaturalPerson | undefined;
}

const cols: { labelCol: ColProps; wrapperCol: ColProps } = {
  labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 }, xxl: { span: 4 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 12 }, xxl: { span: 10 } }
};

export default function StepName({ form, myPerson }: IProps): JSX.Element {
  const { translate } = useContext(intlContext);

  const { setFieldsValue } = form;

  useEffect(() => {
    if (myPerson != null) {
      const fields = mapPersonToFields(myPerson);
      setFieldsValue(fields);
    }
  }, [myPerson, setFieldsValue]);

  return (
    <Form name="DataCompletionMyNaturalPersonName" form={form} labelAlign="left" layout="horizontal" style={{ marginBottom: 0 }}>
      <Title level={3}>{translate("data_completion_name_title")}</Title>
      <Form.Item
        name="firstName"
        rules={[{ required: true, message: translate("data_completion_name_first_hint"), whitespace: true }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_name_first_label")}
        {...cols}
      >
        <Input placeholder={translate("data_completion_name_first_placeholder")} />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[{ required: true, message: translate("data_completion_name_last_hint"), whitespace: true }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_name_last_label")}
        {...cols}
      >
        <Input placeholder={translate("data_completion_name_last_placeholder")} />
      </Form.Item>
      <Form.Item
        name="gender"
        rules={[{ required: true, message: translate("data_completion_name_gender_hint") }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_name_gender_label")}
        {...cols}
      >
        <Select placeholder={Math.random() > 0.5 ? translate("data_completion_name_gender_male") : translate("data_completion_name_gender_female")}>
          <Select.Option value={EGender.male}>{translate("data_completion_name_gender_male")}</Select.Option>
          <Select.Option value={EGender.female}>{translate("data_completion_name_gender_female")}</Select.Option>
          <Select.Option value={EGender.diverse}>{translate("data_completion_name_gender_diverse")}</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  );
}

function mapPersonToFields(myPerson: NaturalPerson): IFormValuesName {
  return {
    firstName: myPerson.firstName,
    lastName: myPerson.lastName,
    gender: myPerson.gender || EGender.male
  };
}
