import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import { User } from "applications/renderAll";
import FormattedMessage from "components/FormattedMessage";
import { intlContext } from "translations/context";
import { NaturalPersonState } from "types/NaturalPerson";
import { LegalPersonState } from "types/LegalPersonState";
import DataCompletionModal from "applications/data-completion/views/MainModal";
import DataCompletionLegalPersonModal from "applications/data-completion-legal-person/views/MainModal";
import FelaProvider from "../../../fela/FelaProvider";
import State from "../../models/State";
import * as userInfoActions from "../../models/userInfo/actions";
import * as actions from "../models/actions";
import useLogoutApi from "../hooks/useLogoutApi";
import MyBettervestMenu from "./MyBettervestMenu";
import Popover from "antd/lib/popover";

interface IProps {
  currentUser: User;
}

export default function LoggedInView({ currentUser }: IProps): JSX.Element {
  const { dataCompletionModalVisible, hasProofedEmail } = useSelector((state: State) => ({
    dataCompletionModalVisible: state.userHandling?.dataCompletionModalVisible ?? false,
    hasProofedEmail: state.userInfo.userInfo?.currentUser.hasProofedEmail ?? false
  }));
  const dispatch = useDispatch();
  const { translate } = useContext(intlContext);
  const { loading: loadingLogout, handle: handleLogout } = useLogoutApi({
    onError: useCallback(() => message.error(translate("userhandling_logout_failed")), [translate]),
    onSuccess: useCallback(() => dispatch(userInfoActions.fetchUserInfo()), [dispatch])
  });

  const [hintVisibility, setHintVisibility] = useState<boolean>(false);

  if (currentUser.naturalPerson == null && currentUser.legalPerson == null) {
    return (
      <>
        <FormattedMessage id="userhandling_natural_person_not_found" />
        <Button style={{ marginLeft: 10 }} onClick={handleLogout} loading={loadingLogout}>
          <FormattedMessage id="userhandling_logout" />
        </Button>
      </>
    );
  }

  const email = currentUser.email;

  if (currentUser.naturalPerson) {
    const { state: naturalPersonState, firstName, lastName } = currentUser.naturalPerson;
    return (
      <div style={{ textAlign: "right" }}>
        <div style={{ marginBottom: 6 }}>
          <FormattedMessage id="userhandling_hello" /> {firstName} {lastName} {firstName == null && lastName == null && email}
          <Button style={{ marginLeft: 10 }} onClick={handleLogout} loading={loadingLogout}>
            <FormattedMessage id="userhandling_logout" />
          </Button>
        </div>
        {naturalPersonState !== NaturalPersonState.finished && naturalPersonState != null ? (
          <>
            <Button
              type="primary"
              onClick={() => {
                if (hasProofedEmail) {
                  dispatch(actions.setDataCompletionModalVisible(true));
                } else {
                  setHintVisibility(false);
                  dispatch(actions.setDataCompletionModalVisible(true)); // TODO: Remove it
                }
              }}
            >
              <FormattedMessage id="userhandling_data_completion_button" />
            </Button>
            {!hasProofedEmail && (
              <Popover
                visible={!hasProofedEmail && !dataCompletionModalVisible}
                placement={"bottomLeft"}
                content={translate("userhandling_register_confirm_link")}
              ></Popover>
            )}
            <FelaProvider>
              <DataCompletionModal visible={dataCompletionModalVisible} hide={() => dispatch(actions.setDataCompletionModalVisible(true))} />
            </FelaProvider>
          </>
        ) : (
          <MyBettervestMenu />
        )}
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "right" }}>
        <div style={{ marginBottom: 6 }}>
          <FormattedMessage id="userhandling_hello" /> {currentUser.legalPerson?.name}
          <Button style={{ marginLeft: 10 }} onClick={handleLogout} loading={loadingLogout}>
            <FormattedMessage id="userhandling_logout" />
          </Button>
        </div>
        {currentUser.legalPerson?.state !== LegalPersonState.finished && currentUser.legalPerson != null ? (
          <>
            <Button
              type="primary"
              onClick={() => {
                if (hasProofedEmail) {
                  dispatch(actions.setDataCompletionModalVisible(true));
                } else {
                  dispatch(actions.setDataCompletionModalVisible(true)); // TODO: Remove it
                  setHintVisibility(!hintVisibility);
                }
              }}
            >
              <FormattedMessage id="userhandling_data_completion_button" />
            </Button>
            {hintVisibility && (
              <Popover visible={hintVisibility} placement={"bottomLeft"} content={translate("userhandling_register_confirm_link")}></Popover>
            )}
            <FelaProvider>
              <DataCompletionLegalPersonModal
                visible={dataCompletionModalVisible}
                legalPersonId={currentUser.legalPerson.id}
                hide={() => dispatch(actions.setDataCompletionModalVisible(true))}
              />
            </FelaProvider>
          </>
        ) : (
          <MyBettervestMenu />
        )}
      </div>
    );
  }
}
