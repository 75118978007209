import ITexts from "./ITexts";

const en: ITexts = {
  project_owner_dashboard_error: "Error",
  project_owner_dashboard_error_description: `
    There has been an error.
    Please get in touch with your contact person at bettervest or
    our support at <a href="mailto:mail@bettervest.com">mail@bettervest.com</a> with the following error message:
  `,
  project_owner_dashboard_currently_not_available: "Currently not available",

  // MainView
  project_owner_dashboard_no_projects: "You currently have no projects.",
  project_owner_dashboard_project: "Project",
  project_owner_dashboard_select_project: "Project: ",

  // Overview
  project_owner_dashboard_overview_title: "Overview",
  project_owner_dashboard_overview_company_name: "Company name",
  project_owner_dashboard_overview_start_date: "Funding start date",
  project_owner_dashboard_overview_end_date: "Funding end date",
  project_owner_dashboard_overview_investiments: "Investments",
  project_owner_dashboard_overview_number_investments: "Number of investments",
  project_owner_dashboard_overview_amount_collected: "Amount collected",
  project_owner_dashboard_overview_revocation_free: "Revocation free investments",
  project_owner_dashboard_overview_revocation_free_amount: "Revocation free amount collected",
  project_owner_dashboard_overview_principal_to_pay: "Total principal to pay",
  project_owner_dashboard_overview_interest_to_pay: "Total interest to pay",
  project_owner_dashboard_overview_emission: "Emissions",
  project_owner_dashboard_overview_emission_description: "Emission:",

  // Outstanding
  project_owner_dashboard_outstanding_title: "Loan Status on Specifiable Date",
  project_owner_dashboard_outstanding_select_date: "Select a date: ",
  project_owner_dashboard_outstanding_paid_principal_title: "Paid Principal",
  project_owner_dashboard_outstanding_paid_interest_title: "Paid Interest",
  project_owner_dashboard_outstanding_due_principal_title: "Due principal repayment",
  project_owner_dashboard_outstanding_due_interest_title: "Due interest",
  project_owner_dashboard_outstanding_due_total_title: "Due principal repayment + Due interest",
  project_owner_dashboard_outstanding_outstanding_principal_title: "Outstanding principal",
  project_owner_dashboard_outstanding_outstanding_interest_title: "Outstanding Interest",
  project_owner_dashboard_outstanding_outstanding_total_title: "Outstanding Total",
  project_owner_dashboard_outstanding_paid_principal: `<b>Paid Principal:</b> Principal repayments made up to the selected date`,
  project_owner_dashboard_outstanding_paid_interest: `<b>Paid Interest:</b> Interest payments made up to the selected date`,
  project_owner_dashboard_outstanding_due_principal: `<b>Due principal repayment:</b> Repayment (over)due on the selected date`,
  project_owner_dashboard_outstanding_due_interest: `<b>Due interest:</b> (Over)due interest on the selected date`,
  project_owner_dashboard_outstanding_due_total: `<b>Due principal repayment + Due interest:</b> Repayment (over)due + (Over)due interest on the selected date`,
  project_owner_dashboard_outstanding_outstanding_principal: `<b>Outstanding principal:</b> Outstanding principal payments (due and not due) on the selected date`,
  project_owner_dashboard_outstanding_outstanding_interest: `<b>Outstanding Interest:</b> Outstanding interest payments (due and not due) on the selected date`,
  project_owner_dashboard_outstanding_outstanding_total: `<b>Outstanding Total:</b> Open scheduled payments (due and not due) on the selected date. Corresponds to the sum of outstanding repayments and outstanding interest payments.`,
  project_owner_dashboard_outstanding_outstanding_disclaimer:
    "** Please note that this overview includes only the planned principal and interest payments. Paid or accrued penalty interest is not included. **",

  // repayment
  project_owner_dashboard_repayment_title: "Repayments / Repayment Plan",
  project_owner_dashboard_repayment_planned_repayments: "Repayment Plan",
  project_owner_dashboard_repayment_actual_repayments: "Repayments made",
  project_owner_dashboard_repayment_period: "Period",
  project_owner_dashboard_repayment_repayment: "Principal",
  project_owner_dashboard_repayment_interest: "Interest",
  project_owner_dashboard_repayment_penaltyInterest: "Penalty Default Interest",
  project_owner_dashboard_repayment_planned_date: "Planned Date",
  project_owner_dashboard_repayment_actual_date: "Date",
  project_owner_dashboard_repayment_residual_loan: "Balance Amount",

  // investments
  project_owner_dashboard_investment_title: "Investments",
  project_owner_dashboard_investment_created_at: "Investment date",
  project_owner_dashboard_investment_paid_at: "Payment date",
  project_owner_dashboard_investment_interest_rate: "Interest rate",
  project_owner_dashboard_investment_contract_duration: "Contract duration",
  project_owner_dashboard_investment_grace_period: "Grace periods",
  project_owner_dashboard_investment_initial_interest_rate: "Initial interest rate",
  project_owner_dashboard_investment_amount: "Amount",
  project_owner_dashboard_investment_export: "Export to csv file",
  project_owner_dashboard_investment_hint: "In this list only revocation free investments are shown",

  // updates
  project_owner_dashboard_update_title: "Updates",

  // Yearly fees
  project_owner_dashboard_yearlyfee_title: "Yearly Fees",
  project_owner_dashboard_yearlyfee_title_description: "Yearly Fee Payments",
  project_owner_dashboard_yearlyfee_period: "Period",
  project_owner_dashboard_yearlyfee_invoice_number: "Invoice No.",
  project_owner_dashboard_yearlyfee_planned_date: "Planned Date",
  project_owner_dashboard_yearlyfee_invoice_amount_net: "Invoice Amount Net",
  project_owner_dashboard_yearlyfee_gross_amount_paid: "Gross Amount Paid",
  project_owner_dashboard_yearlyfee_date_of_payment: "Date of Payment"
};

export default en;
