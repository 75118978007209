import ITexts from "./ITexts";

const de: ITexts = {
  co2_saving: ` Tonnen CO<sub>2</sub> Eingespart`,
  project_number: "finanzierte Projekte",
  project_investors: "Investoren",
  project_interest_average: "durchschnittliche Rendite jährliche ",
  project_countries: "Länder",
  project_repaid_percent: "gezahlte Rendite",
  funding_volume: "Fundingvolumen gesamt",
  project_newJos: "New Jobs",
  project_housesEletrified: "Houses Eletrified"
};

export default de;
