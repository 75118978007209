import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import State from "../../models/State";
import * as actions from "../models/actions";
import invalidateAccessToken from "../graphql/invalidateAccessToken";

interface IParams {
  onError?: (err: any) => void;
  onSuccess?: () => void;
}

export default function useLogoutApi({ onError, onSuccess }: IParams) {
  const { loading } = useSelector((state: State) => ({
    loading: state.userHandling?.logoutLoading ?? false
  }));
  const dispatch = useDispatch();

  const handle = useCallback(() => {
    (async () => {
      dispatch(actions.setLogoutLoading(true));
      try {
        const succeeded = await invalidateAccessToken();
        if (succeeded && onSuccess) onSuccess();
        if (!succeeded && onError) onError(succeeded);
      } catch (err) {
        if (onError) onError(err);
      }
      dispatch(actions.setLogoutLoading(false));
    })();
  }, [dispatch, onError, onSuccess]);

  return { loading, handle };
}
