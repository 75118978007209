import ITexts from "./ITexts";

const de: ITexts = {
  // Main View
  my_company_intro: `Sie möchten im Namen eines Unternehmens auf bettervest.com investieren?
    <br/>  Hier können Sie Ihren Unternehmensaccount hinzufügen. Bitte geben Sie dafür zunächst den Unternehmensnamen und die Form des Unternehmens an. Anschließen füllen Sie bitte alle Felder mit den Unternehmensdaten aus, um das Unternehmensprofil zu vervollständigen.`,
  my_company_intro2:
    "Für die Registrierung eines Unternehmens benötigen wir außerdem weitere Dokumente wie zum Beispiel den Handelsregisterauszug. Nachdem Sie den Unternehmensaccount erstellt haben, schicken wir Ihnen eine E-Mail mit weiteren Informationen und benötigen Dokumenten zu. ",
  my_company_intro3: "Fehler",
  my_company_intro4: `Es konnte leider keine Antwort vom Server empfangen werden. Bitte schreiben Sie eine E-Mail an <a href="mailto:mail@bettervest.com?subject=Fehler beim Laden meiner Investitionen">mail@bettervest.com</a>.`
};

export default de;
