import { useContext } from "react";
import gql from "graphql-tag";
import { intlContext } from "translations/context";
import { NaturalPersonState } from "types/NaturalPerson";
import { apolloClient } from "utils";
import IStepObject from "../../types/IStepObject";
import { IDataCompletionNameInput } from "../../types/registerArgs";

export default function useStepName(): IStepObject<IDataCompletionNameInput> {
  const { translate } = useContext(intlContext);

  return {
    registerFn: dispatchNaturalPersonEvent,
    stepId: NaturalPersonState.name,
    title: translate("data_completion_name_title")
  };
}

const mutation = gql`
  mutation dispatchNaturalPersonEvent($naturalPersonId: ID, $payload: JSON!) {
    dispatchNaturalPersonEvent(event: { type: submitName, naturalPersonId: $naturalPersonId, payload: $payload })
  }
`;

async function dispatchNaturalPersonEvent(naturalPersonId: string, payload: IDataCompletionNameInput): Promise<void> {
  await apolloClient.cache.reset();
  const response = await apolloClient.mutate({
    mutation,
    variables: { naturalPersonId, payload },
    fetchPolicy: "no-cache"
  });
  if (response.errors) throw response.errors;
}
