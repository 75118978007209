import { ApolloClient, OperationVariables, MutationOptions } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { useState, useCallback } from "react";
import { GraphQLError } from "graphql";
import { baseEndpoint } from "config/baseEndpoint";

const cache = new InMemoryCache();

const graphqlEndpoint = `${baseEndpoint}/graphql`;
console.log(`Graphql Endpoint: ${graphqlEndpoint}`);
const link = new HttpLink({
  uri: graphqlEndpoint,
  credentials: "include"
});

export const apolloClient = new ApolloClient({
  cache,
  link,
  queryDeduplication: false
});

// Temporary solution until https://github.com/trojanowski/react-apollo-hooks/pull/93 is merged.
export function useMutation<TData = any, TVariables = OperationVariables>(
  defaultOptions?: MutationOptions<TData, TVariables>
): [
  (options: MutationOptions<TData, TVariables>) => void,
  { loading: boolean; data?: TData | undefined; errors?: readonly GraphQLError[] | undefined }
] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TData | undefined>(undefined);
  const [errors, setErrors] = useState<readonly GraphQLError[] | undefined>(undefined);

  const mutate = useCallback(
    (options: MutationOptions<TData, TVariables>) => {
      setLoading(true);
      apolloClient
        .mutate<TData, TVariables>({ ...defaultOptions, ...options })
        .then((response) => {
          if (response.errors != null) {
            setErrors(response.errors);
            setData(undefined);
          } else setData(response.data);
        })
        .catch((error) => {
          setErrors([error]);
          setData(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [defaultOptions]
  );

  return [mutate, { loading, data, errors }];
}
