import { useState, useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { apolloClient } from "utils";
import NaturalPerson from "../types/NaturalPerson";

export default function useMyPersonApi() {
  const { data, loading, refetch } = useQuery(query, { fetchPolicy: "no-cache" });
  const [myPerson, setMyPerson] = useState<NaturalPerson | undefined>(undefined);

  useEffect(() => {
    if (loading === false && data?.users?.[0]?.naturalPerson != null) {
      const user = data.users[0];
      const naturalPerson = user.naturalPerson;
      setMyPerson({ ...naturalPerson, recommendation: user.recommendation, subscribedToNewsletter: user.subscribedToNewsletter });
    }
  }, [data, loading, setMyPerson]);

  return {
    myPerson,
    loading,
    refetch: async () => {
      await apolloClient.cache.reset();
      return refetch();
    }
  };
}

const query = gql`
  query {
    users(where: { _Owner_id: { is: TRUE } }) {
      recommendation
      subscribedToNewsletter
      naturalPerson {
        id
        state

        firstName
        lastName
        gender

        phoneNumberPrefix
        phoneNumber
        addrCountryCode
        addrCountry
        addrCity
        addrPostalCode
        addrStreetName
        addrStreetNumber
        addrAddition

        nationality
        birthday
        birthPlaceText
        birthPlaceCountry

        verificationDocumentType
        verificationDocumentNumber
        verificationDocumentIssuingAgency
      }
    }
  }
`;
