import React, { useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Alert from "antd/lib/alert";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import FormattedMessage from "components/FormattedMessage";
import { intlContext } from "translations/context";
import State from "../../models/State";
import * as userInfoActions from "../../models/userInfo/actions";
import * as actions from "../models/actions";
import useLoginApi from "../hooks/useLoginApi";
import useForgotPasswordApi from "../hooks/useForgotPasswordApi";

export default function LoginModal(props: object): JSX.Element {
  const { visible, showForgotPassword } = useSelector((state: State) => ({
    visible: state.userHandling?.loginModalVisible ?? false,
    showForgotPassword: state.userHandling?.showForgotPassword ?? false
  }));
  const dispatch = useDispatch();
  const { translate } = useContext(intlContext);
  const [form] = Form.useForm();

  const { loading: loadingLogin, handle: handleLogin } = useLoginApi({
    form,
    onError: useCallback(
      (err) => {
        if (err != null && err.message != null && err.message.startsWith("Network error")) {
          message.error(<FormattedMessage id="server_is_unavailable" />, 15);
        } else message.error(translate("userhandling_login_failed"));
      },
      [translate]
    ),
    onSuccess: useCallback(() => {
      dispatch(userInfoActions.fetchUserInfo());
      dispatch(actions.setLoginModalVisible(false));
    }, [dispatch]),
    onWrongCredentials: useCallback(() => message.error(translate("userhandling_login_wrong_credentials")), [translate])
  });

  const { loading: loadingForgotPassword, handle: handleForgotPassword } = useForgotPasswordApi({
    form,
    onError: useCallback(
      (err) => {
        if (err != null && err.message != null && err.message.startsWith("Network error")) {
          message.error(<FormattedMessage id="server_is_unavailable" />, 15);
        } else message.error(translate("userhandling_login_failed"));
      },
      [translate]
    ),
    onSuccess: useCallback(() => {
      message.success("Falls ein Konto zu dieser Email Adresse existiert, haben wir Ihnen eine Email zum Zurücksetzen des Passworts zugesendet.");
      dispatch(actions.setLoginModalVisible(false));
      setTimeout(() => dispatch(actions.setShowForgotPassword(false)), 20); // Wait for modal close animation.
    }, [dispatch])
  });

  const handleSubmit = useCallback(() => {
    if (showForgotPassword === true) handleForgotPassword();
    else handleLogin();
  }, [handleForgotPassword, handleLogin, showForgotPassword]);

  return (
    <Form name="login-modal" form={form}>
      <Modal
        visible={visible}
        title={showForgotPassword ? translate("userhandling_forgotPassword") : translate("userhandling_login")}
        onCancel={() => {
          dispatch(actions.setLoginModalVisible(false));
          setTimeout(() => dispatch(actions.setShowForgotPassword(false)), 20); // Wait for modal close animation.
        }}
        footer={[
          <React.Fragment key="forgotPassword">
            {showForgotPassword === false && (
              <Button onClick={() => dispatch(actions.setShowForgotPassword(true))}>{translate("userhandling_forgotPassword")}?</Button>
            )}
          </React.Fragment>,
          <React.Fragment key="submit">
            {showForgotPassword === false && (
              <Button type="primary" onClick={handleSubmit} loading={loadingLogin}>
                {translate("userhandling_login")}
              </Button>
            )}
            {showForgotPassword === true && (
              <Button type="primary" onClick={handleSubmit} loading={loadingForgotPassword}>
                {translate("userhandling_forgotPassword_reset")}
              </Button>
            )}
          </React.Fragment>
        ]}
      >
        {showForgotPassword === false && (
          <>
            <Form.Item
              name="email"
              validateTrigger={[]}
              rules={[
                { type: "email", message: <FormattedMessage id="userhandling_email_hint_valid" /> },
                { required: true, message: <FormattedMessage id="userhandling_email_hint_required" /> }
              ]}
              style={{ marginBottom: 12 }}
            >
              <Input placeholder={translate("userhandling_email_placeholder")} onPressEnter={handleSubmit} />
            </Form.Item>
            <Form.Item
              name="password"
              validateTrigger={[]}
              rules={[{ required: true, message: <FormattedMessage id="setPassword_choosePassword_hint" /> }]}
            >
              <Input.Password placeholder={translate("setPassword_choosePassword_placeholder")} onPressEnter={handleSubmit} />
            </Form.Item>
          </>
        )}
        {showForgotPassword === true && (
          <>
            <Form.Item
              name="email"
              validateTrigger={[]}
              rules={[
                { type: "email", message: <FormattedMessage id="userhandling_email_hint_valid" /> },
                { required: true, message: <FormattedMessage id="userhandling_email_hint_required" /> }
              ]}
              noStyle
            >
              <Input placeholder={translate("userhandling_email_placeholder")} style={{ marginBottom: 10 }} onPressEnter={handleSubmit} />
            </Form.Item>
            <Alert
              type="warning"
              message={<FormattedMessage id="userhandling_login_security_alert_message" />}
              description={<FormattedMessage id="userhandling_login_security_alert_description" />}
            />
          </>
        )}
      </Modal>
    </Form>
  );
}
