import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "antd/lib/form";
import tenantId from "constants/tenantId";
import State from "../../models/State";
import * as actions from "../models/actions";
import login from "../graphql/loginWithCredentials";

interface IFormValues {
  email: string | undefined;
  password: string | undefined;
}

interface IParams {
  form: FormInstance<IFormValues>;
  onError?: (err: any) => void;
  onSuccess?: () => void;
  onWrongCredentials?: () => void;
}

export default function useLoginApi({ form, onError, onSuccess, onWrongCredentials }: IParams) {
  const { loading } = useSelector((state: State) => ({
    loading: state.userHandling?.loginLoading ?? false
  }));
  const dispatch = useDispatch();

  const handle = useCallback(async () => {
    try {
      const { email, password } = await form.validateFields();
      if (email == null || password == null) return;
      dispatch(actions.setLoginLoading(true));
      try {
        const succeeded = await login({ tenantId, email, password });
        if (succeeded && onSuccess) onSuccess();
        if (!succeeded && onWrongCredentials) onWrongCredentials();
      } catch (err) {
        console.log(`graphql.login.error: ${err}`);
        if (onError) onError(err);
      }
      dispatch(actions.setLoginLoading(false));
    } catch (errorInfo) {
      console.log({ errorInfo });
      return;
    }
  }, [dispatch, form, onError, onSuccess, onWrongCredentials]);

  return { loading, handle };
}
