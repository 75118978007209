import { useCallback } from "react";
import { FormInstance } from "antd/lib/form";
import { useDispatch, useSelector } from "react-redux";
import tenantId from "constants/tenantId";
import State from "../../models/State";
import * as actions from "../models/actions";
import initiateEmailProof from "../graphql/initiateEmailProof";

interface IFormValues {
  email: string | undefined;
}

interface IParams {
  form: FormInstance<IFormValues>;
  onError?: (err: any) => void;
  onSuccess?: () => void;
}

export default function useForgotPasswordApi({ form, onError, onSuccess }: IParams) {
  const { loading } = useSelector((state: State) => ({
    loading: state.userHandling?.forgotPasswordLoading ?? false
  }));
  const dispatch = useDispatch();

  const handle = useCallback(async () => {
    try {
      const { email } = await form.validateFields();
      if (email == null) return;
      dispatch(actions.setForgotPasswordLoading(true));
      try {
        await initiateEmailProof({ tenantId, email, info: "FORGOT_PASSWORD" });
        if (onSuccess) onSuccess();
      } catch (err) {
        console.error({ msg: "graphql.forgotPassword.error", err, email });
        if (onError) onError(err);
      }
      dispatch(actions.setForgotPasswordLoading(false));
    } catch (errorInfo) {
      console.log({ errorInfo });
      return;
    }
  }, [dispatch, form, onError, onSuccess]);

  return { loading, handle };
}
