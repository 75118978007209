import ITexts from "./ITexts";

const de: ITexts = {
  // Description
  project_list_item_go_to_project: "Zum Projekt",
  project_list_item_go_to_project_coming_soon: "Demnächst online",
  project_list_item_go_to_project_crowd_cube: "Eigenkapital",
  project_list_item_go_to_project_coming_soon_redirect: "Zur Kampagne"
};

export default de;
