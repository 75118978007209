import * as actionTypes from "./actionTypes";
import * as api from "./api";
import { UserInfo } from "./types";
import ActionCallbacks from "types/ActionCallbacks";
import { ThunkAction } from "redux-thunk";

function setLoading(loading: boolean) {
  return {
    type: actionTypes.USER_INFO_SET_LOADING,
    payload: loading
  };
}

export function setUserInfo(userInfo: UserInfo | null) {
  return {
    type: actionTypes.USER_INFO_SET_USER_INFO,
    payload: userInfo
  };
}

export function fetchUserInfo(callbacks?: ActionCallbacks): ThunkAction<Promise<void>, any, any, any> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const userInfo = await api.getUserInfo();

      if (userInfo != null) setIsLoggedIn(true);
      else setIsLoggedIn(false);

      dispatch(setUserInfo(userInfo));
      callbacks?.onSuccess?.();
    } catch (error) {
      setIsLoggedIn(false);
      callbacks?.onError?.(error);
    }
    dispatch(setLoading(false));
  };
}

function setLoadingLogin(loading: boolean) {
  return {
    type: actionTypes.USER_INFO_SET_LOADING_LOGIN,
    payload: loading
  };
}

export function login(email: string, password: string, callbacks?: ActionCallbacks): ThunkAction<Promise<void>, any, any, any> {
  return async (dispatch) => {
    dispatch(setLoadingLogin(true));
    try {
      const succeeded = await api.login(email, password);
      if (succeeded === false) throw new Error("Email und/oder Passwort ist falsch");
      callbacks?.onSuccess?.();
      dispatch(fetchUserInfo());
    } catch (error) {
      callbacks?.onError?.(error);
    } finally {
      dispatch(setLoadingLogin(false));
    }
  };
}

/** Hack to hide project attachments from search engine crawlers https://gitlab.com/bettervest/bettervest3/issues/394 */
function setIsLoggedIn(isLoggedIn: boolean): void {
  if ((window as any).setIsLoggedIn != null) (window as any).setIsLoggedIn(isLoggedIn);
}
