import rootReducer from "./reducers";

export function getUserHandlingModule() {
  return {
    id: "user-handling",
    reducerMap: {
      userHandling: rootReducer
    },
    initialActions: []
  };
}
