export const USER_HANDLING_LOGIN_SET_MODAL_VISIBLE = "USER_HANDLING_LOGIN_SET_MODAL_VISIBLE";
export const USER_HANDLING_LOGIN_SET_SHOW_FORGOT_PASSWORD = "USER_HANDLING_LOGIN_SET_SHOW_FORGOT_PASSWORD";

export const USER_HANDLING_REGISTER_SET_MODAL_VISIBLE = "USER_HANDLING_REGISTER_SET_MODAL_VISIBLE";
export const USER_HANDLING_REGISTER_SET_IS_LEGAL_PERSON_SELECTED = "USER_HANDLING_REGISTER_SET_IS_LEGAL_PERSON_SELECTED";
export const USER_HANDLING_REGISTER_SET_LOADING = "USER_HANDLING_REGISTER_SET_LOADING";
export const USER_HANDLING_REGISTER_SET_GENERAL_DOCUMENT_TEMPLATE_SET = "USER_HANDLING_REGISTER_SET_GENERAL_DOCUMENT_TEMPLATE_SET";
export const USER_HANDLING_SET_PRIVACY_INFO_VISIBLE = "USER_HANDLING_SET_PRIVACY_INFO_VISIBLE";
export const USER_HANDLING_SET_NEWSLETTER_INFO_VISIBLE = "USER_HANDLING_SET_NEWSLETTER_INFO_VISIBLE";
export const USER_HANDLING_REGISTER_SET_SHOW_SUCCESS_VIEW = "USER_HANDLING_REGISTER_SET_SHOW_SUCCESS_VIEW";

export const USER_HANDLING_SET_DATA_COMPLETION_MODAL_VISIBLE = "USER_HANDLING_SET_DATA_COMPLETION_MODAL_VISIBLE";

export const USER_HANDLING_SET_FORGOT_PASSWORD_LOADING = "USER_HANDLING_SET_FORGOT_PASSWORD_LOADING";
export const USER_HANDLING_SET_LOGIN_LOADING = "USER_HANDLING_SET_LOGIN_LOADING";
export const USER_HANDLING_SET_LOGOUT_LOADING = "USER_HANDLING_SET_LOGOUT_LOADING";
export const USER_HANDLING_SET_REGISTER_LOADING = "USER_HANDLING_SET_REGISTER_LOADING";
