import * as React from "react";

import { RendererProvider } from "react-fela";
import getFelaRenderer from "./getFelaRenderer";

const renderer = getFelaRenderer();

export default function FelaProvider(props: { children: React.ReactNode }): JSX.Element {
  return <RendererProvider renderer={renderer}>{props.children}</RendererProvider>;
}
