import { createRenderer, IRenderer, TPlugin, TEnhancer, IConfig } from "fela";
import webPreset from "fela-preset-web";
import friendlyPseudoClass from "fela-plugin-friendly-pseudo-class";
import monolithic from "fela-monolithic";

export default function getRenderer(): IRenderer {
  const plugins: TPlugin[] = [...webPreset, friendlyPseudoClass()];

  const enhancers: TEnhancer[] = [];

  if (process.env.NODE_ENV !== "production") {
    enhancers.push(monolithic());
  }

  const config: IConfig = {
    plugins,
    enhancers,
    selectorPrefix: "_"
  };

  return createRenderer(config);
}
