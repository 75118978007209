import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import Button from "antd/lib/button";
import { FormInstance } from "antd/lib/form";
import message from "antd/lib/message";
import FormattedMessage from "components/FormattedMessage";
import { intlContext } from "translations/context";
import { NaturalPersonState } from "types/NaturalPerson";
import { IFormValues } from "../types/IFormValues";
import IStepObject from "../types/IStepObject";
import useStepName from "../views/Step2Name/useStepName";
import useStepAddress from "../views/Step3Address/useStepAddress";
import useStepBirthData from "../views/Step4BirthData/useStepBirthData";
import useStepVerification from "../views/Step5Verification/useStepVerification";
import useStepFeedback from "../views/Step6Feedback/useStepFeedback";

export default function useStepManager(
  form: FormInstance<IFormValues>,
  naturalPersonId: string | null,
  state: NaturalPersonState,
  refetch: () => void
) {
  const { translate, allCountries } = useContext(intlContext);
  const name = useStepName();
  const address = useStepAddress();
  const birthData = useStepBirthData();
  const verification = useStepVerification();
  const feedback = useStepFeedback();
  const stepObjects: IStepObject[] = useMemo(() => [name, address, birthData, verification, feedback], [
    name,
    address,
    birthData,
    verification,
    feedback
  ]);

  const [step, setStep] = useState<number>(0);
  const [stateStep, setStateStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStep(mapStepIdToStep(state || null));
    setStateStep(mapStepIdToStep(state) || 0);
  }, [state]);

  const handleSubmit = useCallback(async () => {
    if (form == null) return;
    try {
      const values = await form.validateFields();
      setLoading(true);
      try {
        if (naturalPersonId == null) throw new Error("NATURAL_PERSON_ID_IS_NULL");
        if (values.addrCountryCode != null) values.addrCountry = allCountries.find(({ key }) => key === values.addrCountryCode)?.value;
        await stepObjects[step].registerFn(naturalPersonId, values);
        refetch();
        const nextStep = step + 1;
        if (nextStep <= stateStep) setStep(nextStep);
        message.success(translate("data_completion_registerFn_success"));
      } catch (error) {
        console.log(`Error during data completion: ${error}`);
        message.error(translate("data_completion_registerFn_error"));
      }
      setLoading(false);
    } catch (errorInfo) {
      return;
    }
  }, [allCountries, form, naturalPersonId, refetch, stepObjects, step, stateStep, translate]);

  const footer: JSX.Element[] = [];

  if (step > 0) {
    footer.push(
      <Button key="back" onClick={() => setStep(step - 1)}>
        <FormattedMessage id="data_completion_button_back" />
      </Button>
    );
  }

  if (step <= stepObjects.length - 2) {
    footer.push(
      <Button key="submit" type="primary" onClick={handleSubmit} loading={loading}>
        <FormattedMessage id="data_completion_button_save_and_next" />
      </Button>
    );
  } else if (step === stepObjects.length - 1) {
    footer.push(
      <Button key="submit-and-close" type="primary" onClick={handleSubmit} loading={loading}>
        <FormattedMessage id="data_completion_button_finalize" />
      </Button>
    );
  }

  return {
    step,
    setStep,
    maxAvailableStep: stateStep,
    stepObjects,
    footer
  };
}

function mapStepIdToStep(stepId: NaturalPersonState | null): number {
  if (stepId == null) return 0;
  switch (stepId) {
    case NaturalPersonState.name:
      return 0;
    case NaturalPersonState.address:
      return 1;
    case NaturalPersonState.birthData:
      return 2;
    case NaturalPersonState.verification:
      return 3;
    case NaturalPersonState.feedback:
      return 4;
    default:
      return 0;
  }
}
