import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import message from "antd/lib/message";
import Spin from "antd/lib/spin";
import State from "applications/models/State";
import { intlContext } from "translations/context";
import LoggedInView from "./LoggedInView";
import NotLoggedInView from "./NotLoggedInView";

interface Props {
  afterClose?: () => void;
  onlyRegister?: boolean;
}

export default function UserHandling({ afterClose, onlyRegister }: Props): JSX.Element {
  const { userInfoLoading, userInfo } = useSelector((state: State) => ({
    userInfoLoading: state.userInfo.loading,
    userInfo: state.userInfo.userInfo
  }));
  const { translate } = useContext(intlContext);

  useEffect(() => {
    if (onlyRegister === true && userInfo != null) {
      message.success(translate("userhandling_already_logged_in"));
      afterClose?.();
    }
  }, [afterClose, onlyRegister, translate, userInfo]);

  if (userInfoLoading === true) return <Spin />;
  if (userInfo == null) return <NotLoggedInView afterClose={afterClose} registerModalInitiallyVisible={onlyRegister} />;
  return <LoggedInView currentUser={userInfo.currentUser} />;
}
