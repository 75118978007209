import gql from "graphql-tag";

import { apolloClient } from "utils";

const mutationInitiateEmailProof = gql`
  mutation initiateEmailProof($email: String!, $tenantId: String!, $info: String!) {
    getUserIdentifier(username: $email, tenant: $tenantId, returnId: "$userIdentifier")
    initiateEmailProof(info: $info, userIdentifier: "$userIdentifier")
  }
`;

type TInitiateEmailProofInfo = "REGISTER" | "FORGOT_PASSWORD" | "EMAIL_PROOF";

interface IInitiateEmailProofInput {
  tenantId: string;
  email: string;
  info: TInitiateEmailProofInfo;
}

export default async function initiateEmailProof(variables: IInitiateEmailProofInput): Promise<boolean> {
  await apolloClient.cache.reset();
  const responseInitiateEmailProof = await apolloClient.mutate({
    mutation: mutationInitiateEmailProof,
    variables: {
      tenantId: variables.tenantId,
      email: variables.email.toLowerCase(),
      info: variables.info
    }
  });
  if (responseInitiateEmailProof.errors) throw responseInitiateEmailProof.errors;
  return responseInitiateEmailProof.data.initiateEmailProof != null;
}
