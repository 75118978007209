import React, { useState, useCallback } from "react";
import frenchkiss from "frenchkiss";
import { Parser } from "html-to-react";
import * as countriesLib from "i18n-iso-countries";
// tslint:disable:no-submodule-imports
import enCountries from "i18n-iso-countries/langs/en.json";
import deCountries from "i18n-iso-countries/langs/de.json";
import Cookies from "js-cookie";

import de from "./de";
import en from "./en";
import ITranslationCollection from "./ITranslationCollection";

interface IIntlContext {
  language: TLanguage;
  languages: TLanguage[];
  setLanguage: (language: TLanguage) => void;
  translate: (id: keyof ITranslationCollection) => string;
  countries: Array<{ key: string; value: string }>;
  allCountries: Array<{ key: string; value: string }>;
}

// Cookies.set("wp-wpml_current_language", "en");

const cookieLanguage = Cookies.get("wp-wpml_current_language") ?? "de";

console.log("language", cookieLanguage);

type TLanguage = "de" | "en" | "intl-id";

frenchkiss.set("de", de);
frenchkiss.set("en", en);
frenchkiss.set("intl-id", {});

const languages: TLanguage[] = ["de", "en", "intl-id"];
const userLanguage: TLanguage = cookieLanguage as TLanguage;
const initialLanguage: TLanguage = languages.includes(userLanguage) ? userLanguage : "de";
frenchkiss.locale(initialLanguage);

export const htmlToReactParser = new Parser();

const initialContextValue = {
  language: initialLanguage,
  languages,
  setLanguage: (languageParam: TLanguage) => {
    const newLanguage: TLanguage = languages.includes(languageParam) ? languageParam : "de";
    frenchkiss.locale(newLanguage);
  },
  translate: (key: keyof ITranslationCollection) => frenchkiss.t(key),
  countries: getCountries(initialLanguage),
  allCountries: getCountries(initialLanguage, false)
};

export const intlContext = React.createContext<IIntlContext>(initialContextValue);

export function IntlProvider(props: { children?: React.ReactNode }) {
  const [language, setLanguage] = useState(initialLanguage);

  const createTranslate = useCallback(() => {
    frenchkiss.locale(language);
    return (id: keyof ITranslationCollection) => frenchkiss.t(id);
  }, [language]);

  const contextValue: IIntlContext = {
    language,
    languages,
    setLanguage,
    translate: createTranslate(),
    countries: getCountries(language),
    allCountries: getCountries(language, false)
  };

  return <intlContext.Provider value={contextValue}>{props.children}</intlContext.Provider>;
}

function getCountries(language: TLanguage, excludeSomeCountries: boolean = true) {
  const languageCountriesMap = {
    de: deCountries,
    en: enCountries
  };
  if (languageCountriesMap[language] != null) countriesLib.registerLocale(languageCountriesMap[language]);
  else countriesLib.registerLocale(languageCountriesMap.de);

  const excludedCountries = ["USA", "JPN", "CAN", "AUS"];
  return Object.entries(countriesLib.getNames(language))
    .map(([key, value]) => ({ key: countriesLib.alpha2ToAlpha3(key), value }))
    .filter(({ key }) => (excludeSomeCountries === true ? !excludedCountries.includes(key) : true));
}
