import { createStore, IModuleStore, IExtension, IModule } from "redux-dynamic-modules-core";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import State from "./State";

console.log("redux-dynamic-modules.created-store");
console.log("redux-dynamic-modules.added-extension.thunk");
console.log("redux-dynamic-modules.added-extension.logging");

const store: IModuleStore<State> = createStore({
  extensions: [getThunkExtension(), getLoggingExtension()]
});

export default store;

export function getLoggingExtension(): IExtension {
  return {
    onModuleAdded: (module: IModule<any>) => console.log(`redux-dynamic-modules.added-module.${module.id}`),
    onModuleRemoved: (module: IModule<any>) => console.log(`redux-dynamic-modules.removed-module.${module.id}`)
  };
}
