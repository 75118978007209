import { useContext } from "react";
import gql from "graphql-tag";
import { intlContext } from "translations/context";
import { NaturalPersonState } from "types/NaturalPerson";
import { apolloClient } from "utils";
import IStepObject from "../../types/IStepObject";
import { IDataCompletionFeedbackInput } from "../../types/registerArgs";

export default function useStepFeedback(): IStepObject<IDataCompletionFeedbackInput> {
  const { translate } = useContext(intlContext);

  return {
    registerFn: dispatchDataCompletionSubmitFeedback,
    stepId: NaturalPersonState.feedback,
    title: translate("data_completion_feedback_title")
  };
}

const mutation = gql`
  mutation dispatchNaturalPersonEvent($naturalPersonId: ID, $payload: JSON!) {
    dispatchNaturalPersonEvent(event: { type: submitFeedback, naturalPersonId: $naturalPersonId, payload: $payload })
  }
`;

async function dispatchDataCompletionSubmitFeedback(naturalPersonId: string, args: IDataCompletionFeedbackInput): Promise<void> {
  await apolloClient.cache.reset();
  const response = await apolloClient.mutate({
    mutation,
    variables: {
      naturalPersonId,
      payload: {
        recommendation: args.recommendation,
        recommendationFreeText: args.recommendationFreeText || null,
        subscribedToNewsletter: args.subscribedToNewsletter === true
      }
    },
    fetchPolicy: "no-cache"
  });
  if (response.errors) throw response.errors;
  // eslint-disable-next-line
  if (location.reload != null) location.reload();
}
