import ITexts from "./ITexts";

const en: ITexts = {
  setPassword_title: "bettervest",
  setPassword_subtitle: "Set your password.",
  setPassword_currentPassword_hint: "Please enter your current password.",
  setPassword_currentPassword_placeholder: "Current password",
  setPassword_newPassword_hint: "Please enter your new password.",
  setPassword_newPassword_placeholder: "New Password",
  setPassword_choosePassword_hint: "Please chose your password.",
  setPassword_choosePassword_placeholder: "Password",
  setPassword_repeatPassword_hint: "Please repeat your password",
  setPassword_repeatPassword_placeholder: "Repeat password",
  setPassword_passwordsRequireToMatch_hint: "Passwords do not match.",
  setPassword_min_length_eight_chars_hint: "Minimum length are 8 characters",

  setPassword_success: "Successfully changed password",
  setPassword_error: "Something went wrong. Please try again later."
};

export default en;
