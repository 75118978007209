import gql from "graphql-tag";

import { apolloClient } from "utils";

const mutation = gql`
  mutation invalidateAccessToken {
    invalidateAccessToken
  }
`;

export default async function invalidateAccessToken(): Promise<boolean> {
  try {
    const response = await apolloClient.mutate({
      mutation
    });
    if (response.errors) console.log(`graphql.logout.error ${JSON.stringify(response.errors)}`);
    return response.data.invalidateAccessToken === true;
  } catch (err) {
    console.log(`graphql.logout.error ${err}`);
    return false;
  }
}
