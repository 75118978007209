import React, { useContext, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import Form, { FormInstance, RuleObject } from "antd/lib/form";
import Input from "antd/lib/input";
import Checkbox from "antd/lib/checkbox";
import Alert from "antd/lib/alert";
import Col from "antd/lib/col";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import State from "applications/models/State";
import FormattedMessage from "components/FormattedMessage";
import { baseEndpoint } from "config/baseEndpoint";
import { intlContext } from "translations/context";
import * as actions from "../models/actions";
import { ERegisterAs, RegisterFormValues } from "../hooks/useRegisterApi";
import Skeleton from "antd/lib/skeleton";

const endpoint = `${baseEndpoint}/registration/preview-document`;

export default function RegisterForm({ form }: { form: FormInstance<RegisterFormValues> }): JSX.Element {
  const { documentTemplateSet, generalDocumentsLoading, privacyInfoVisible, newsletterInfoVisible, isLegalPersonSelected } = useSelector(
    (state: State) => ({
      documentTemplateSet: state.userHandling?.generalDocumentTemplateSet ?? null,
      generalDocumentsLoading: state.userHandling?.generalDocumentsLoading ?? false,
      privacyInfoVisible: state.userHandling?.privacyInfoVisible ?? false,
      newsletterInfoVisible: state.userHandling?.newsletterInfoVisible ?? false,
      isLegalPersonSelected: state.userHandling?.isLegalPersonSelected ?? false
    })
  );
  const dispatch = useDispatch();
  const { translate } = useContext(intlContext);

  useEffect(() => {
    dispatch(actions.fetchGeneralDocumentTemplateSet());
  }, [dispatch]);

  const compareToFirstPassword = useCallback(
    async (rule: RuleObject, value: string) => {
      if (value && value !== form.getFieldValue("password")) return Promise.reject(new Error("not equal to first passord"));
    },
    [form]
  );

  const validatePasswordRequirements = useCallback(async (rule: RuleObject, value: string) => {
    if (value != null && value.length < 8) return Promise.reject(Error("below min length of 8 chars"));
  }, []);

  return (
    <Form
      name="register"
      form={form}
      layout={"horizontal"}
      onValuesChange={(changedValues) =>
        changedValues?.registerAs != null && dispatch(actions.setIsLegalPersonSelected(changedValues.registerAs === ERegisterAs.legalPerson))
      }
    >
      <Row style={{ marginBottom: 12 }}>
        <Col span={23}>
          <Form.Item
            name="email"
            validateTrigger={[]}
            rules={[
              { type: "email", message: <FormattedMessage id="userhandling_email_hint_valid" /> },
              { required: true, message: <FormattedMessage id="userhandling_email_hint_required" /> }
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input
              id="email-input"
              autoComplete="email"
              // disabled={isLegalPersonSelected}
              placeholder={`${translate("userhandling_email_placeholder")} *`}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col span={23}>
          <Form.Item
            name="password"
            validateTrigger={["onBlur"]}
            rules={[
              { required: true, message: <FormattedMessage id="setPassword_choosePassword_hint" /> },
              { validator: validatePasswordRequirements, message: <FormattedMessage id="setPassword_min_length_eight_chars_hint" /> }
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input.Password
              id="new-password-input"
              autoComplete="new-password"
              // disabled={isLegalPersonSelected}
              placeholder={`${translate("setPassword_choosePassword_placeholder")} *`}
            />
          </Form.Item>
        </Col>
        <Col span={1} style={{ textAlign: "right" }}>
          <Popover title={translate("userhandling_menu_password_requirements_title")} content={translate("userhandling_menu_password_requirements")}>
            <InfoCircleOutlined />
          </Popover>
        </Col>
      </Row>
      <Row>
        <Col span={23}>
          <Form.Item
            name="passwordRepeated"
            rules={[
              { required: true, message: <FormattedMessage id="setPassword_repeatPassword_hint" /> },
              {
                validator: compareToFirstPassword,
                message: <FormattedMessage id="setPassword_passwordsRequireToMatch_hint" />
              }
            ]}
          >
            <Input.Password
              id="new-password-repeat-input"
              // disabled={isLegalPersonSelected}
              placeholder={`${translate("setPassword_repeatPassword_placeholder")} *`}
            />
          </Form.Item>
        </Col>
      </Row>
      <FormattedMessage id="userhandling_register_company_info" />
      &nbsp;
      <InfoCircleOutlined
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          dispatch(actions.setIsLegalPersonSelected(!isLegalPersonSelected));
        }}
      />
      {isLegalPersonSelected && (
        <Alert
          type="success"
          message={translate("userhandling_register_newsletter_alert_message")}
          description={translate("userhandling_register_company_alert_description")}
        />
      )}
      <br />
      <br />
      <FormattedMessage id="userhandling_documents_intro" />:
      {generalDocumentsLoading === true ? (
        <Skeleton />
      ) : documentTemplateSet == null ? (
        <Alert
          type="error"
          message={<FormattedMessage id="userhandling_documents_error_message" />}
          description={<FormattedMessage id="userhandling_documents_error_description" />}
        />
      ) : (
        documentTemplateSet.documentTemplates
          .sort((a, b) => (a.position != null && b.position != null && a.position > b.position ? 1 : -1))
          .map(({ id, name, displayText }) => {
            return (
              <Form.Item
                key={id}
                name={`approvedDocumentTemplates.${id}`}
                rules={[
                  {
                    message: translate("userhandling_documents_hint"),
                    validator: async (rule: RuleObject, value: boolean, callback) => {
                      if (value !== true) return Promise.reject(new Error("false"));
                    }
                  }
                ]}
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox style={{ lineHeight: 1.5 }}>
                  {displayText != null && displayText.includes("{nameWithLink}") ? (
                    <>
                      <FormattedMessage id="userhandling_menu_documents_requirements_1" />
                      &nbsp;
                      <a
                        style={{ textDecoration: "underline" }}
                        href={`${endpoint}?documentTemplateId=${id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {name}
                      </a>
                      &nbsp;
                      <FormattedMessage id="userhandling_menu_documents_requirements_2" />
                    </>
                  ) : (
                    <a href={`${endpoint}?documentTemplateId=${id}`} target="_blank" rel="noopener noreferrer">
                      {name}
                    </a>
                  )}
                  &nbsp;
                  {name === "Datenschutzerklärung" && (
                    <>
                      <InfoCircleOutlined
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          dispatch(actions.setPrivacyInfoVisible(!privacyInfoVisible));
                        }}
                      />
                      {privacyInfoVisible && (
                        <Alert
                          type="success"
                          message={<FormattedMessage id="userhandling_register_dataprotection_alert_message" />}
                          description={<FormattedMessage id="userhandling_register_dataprotection_alert_description" />}
                          style={{ paddingTop: 6 }}
                        />
                      )}
                    </>
                  )}
                </Checkbox>
              </Form.Item>
            );
          })
      )}
      <Form.Item style={{ paddingTop: 12 }}>
        <Form.Item name="subscribedToNewsletter" valuePropName="checked" noStyle>
          <Checkbox style={{ lineHeight: 1.5 }}>
            <FormattedMessage id="userhandling_register_newsletter" />
            &nbsp;
            <InfoCircleOutlined
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                dispatch(actions.setNewsletterInfoVisible(!newsletterInfoVisible));
              }}
            />
          </Checkbox>
        </Form.Item>
        {newsletterInfoVisible && (
          <Alert
            type="success"
            message={translate("userhandling_register_newsletter_alert_message")}
            description={translate("userhandling_register_newsletter_alert_description")}
          />
        )}
      </Form.Item>
      *<FormattedMessage id="userhandling_register_required_field" />
    </Form>
  );
}
