import { useContext } from "react";
import gql from "graphql-tag";
import { intlContext } from "translations/context";
import { NaturalPersonState } from "types/NaturalPerson";
import { apolloClient } from "utils";
import IStepObject from "../../types/IStepObject";
import { IDataCompletionBirthDataInput } from "../../types/registerArgs";

export default function useStepBirthData(): IStepObject<IDataCompletionBirthDataInput> {
  const { translate } = useContext(intlContext);

  return {
    registerFn: dispatchNaturalPersonEvent,
    stepId: NaturalPersonState.birthData,
    title: translate("data_completion_birth_title")
  };
}

const mutation = gql`
  mutation dispatchNaturalPersonEvent($naturalPersonId: ID, $payload: JSON!) {
    dispatchNaturalPersonEvent(event: { type: submitBirthData, naturalPersonId: $naturalPersonId, payload: $payload })
  }
`;

async function dispatchNaturalPersonEvent(naturalPersonId: string, args: IDataCompletionBirthDataInput): Promise<void> {
  await apolloClient.cache.reset();
  const { birthday, birthPlaceText, birthPlaceCountry } = args;

  if (birthday == null || birthPlaceText == null || birthPlaceCountry == null) throw new Error("INPUT_IS_NULL");

  const response = await apolloClient.mutate({
    mutation,
    variables: { naturalPersonId, payload: { birthday: birthday.toISOString(true).substring(0, 10), birthPlaceText, birthPlaceCountry } },
    fetchPolicy: "no-cache"
  });
  if (response.errors) throw response.errors;
}
