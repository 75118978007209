import React, { useEffect } from "react";
import { ApolloProvider } from "react-apollo-hooks";
import { IModuleStore } from "redux-dynamic-modules-core";
import { DynamicModuleLoader } from "redux-dynamic-modules-react";
import { Provider } from "react-redux";
import ConfigProvider from "antd/lib/config-provider";
import deDE from "antd/es/locale-provider/de_DE";
import { getUserInfoModule } from "applications/models/userInfo/module";
import { apolloClient } from "utils";
import storeBidInCookies from "utils/adcell/storeBidInCookies";
import trackFirstParty from "utils/adcell/trackFirstParty";
import State from "../models/State";
import { getUserHandlingModule } from "./models/module";
import UserHandling from "./views";

interface IProps {
  afterClose?: () => void;
  onlyRegister?: boolean;
  store?: IModuleStore<State>;
}

export default function App({ afterClose, onlyRegister, store }: IProps): JSX.Element {
  useEffect(() => {
    trackFirstParty();
    storeBidInCookies();
  }, []);

  if (store == null) return <></>;

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={deDE}>
        <Provider store={store}>
          <DynamicModuleLoader modules={[getUserInfoModule(), getUserHandlingModule()]}>
            <UserHandling afterClose={afterClose} onlyRegister={onlyRegister} />
          </DynamicModuleLoader>
        </Provider>
      </ConfigProvider>
    </ApolloProvider>
  );
}
