import ITexts from "./ITexts";

const en: ITexts = {
  // Project List Main
  project_list_startpage_loading: "Loading current projects.",
  project_list_startpage_alert_message: "Note pursuant to Section 12 (2) of the Capital Investment Act (Vermögensanlagengesetz):",
  project_list_startpage_alert_description:
    "The purchase of this investment is associated with considerable risks and may lead to the complete loss of the investment.",
  project_list_startpage_alert_message_secu: "Notice according to Section 4 of the German Securities Prospectus Act:",
  project_list_startpage_alert_description_secu:
    "The acquisition of these securities is associated with considerable risks and can lead to the complete loss of the invested capital.",
  project_list_startpage_subordinatedLoan: "Subordinated loan",
  project_list_startpage_securedLoan: "Bonds",
  project_list_startpage_secu_deposit: "Deposit required",
  project_list_startpage_secu_over_25k: "Investments over €25,000 possible",
  project_list_startpage_secu_addition_securities: "additional securities",
  project_list_startpage_sub_no_deposit: "no deposit needed",
  project_list_startpage_sub_up_to_25k: "Investments up to €25,000 possible",
  project_list_startpage_sub_high_revenue: "high return opportunities",
  project_list_startpage_genussrecht: "Participation right",

  // Project Item Badge
  project_list_startpage_badge_soon: "Soon",

  // Project Item Conditions
  project_list_startpage_condition_interest: "Return p.a.",
  project_list_startpage_condition_duration: "Duration",
  project_list_startpage_condition_initialInterest: "Initial Interest",

  // progress bar
  project_list_startpage_progress_bar_requiredBudget: "Target Amount",
  project_list_startpage_progress_bar_requiredMinBudget: "Funding threshold",
  project_list_startpage_progress_bar_by: "by",
  project_list_startpage_progress_bar_investor: "Investor",
  project_list_startpage_progress_bar_investors: "Investors",

  // Project Time
  project_list_startpage_timer_is_completed: "is completed",
  project_list_startpage_timer_ends_in: "ends in",
  project_list_startpage_timer_day: "day",
  project_list_startpage_timer_days: "days",
  project_list_startpage_timer_and: "and",
  project_list_startpage_timer_hour: "hour",
  project_list_startpage_timer_hours: "hours",

  // Project List Overview
  project_list_startpage_securedLoan_overview: "Loan"
};

export default en;
