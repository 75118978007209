import ITexts from "./ITexts";

const en: ITexts = {
  userprofile_menu_personalData: "Personal data",
  userprofile_menu_password: "Password",
  userprofile_menu_account: "Bank details",
  userprofile_menu_deposit: "Securities account",
  userprofile_menu_email: "Email address",
  userprofile_menu_notification: "Notification",
  userprofile_menu_legalData: "Legal entity",

  userprofile_email_message_success: "Email has been sent to you.",
  userprofile_email_message_error: "Error sending email",
  userprofile_email_current_email_address: "Current Email address",
  userprofile_email_not_confirmed_message: "Email address not confirmed",
  userprofile_email_not_confirmed_description: "Please click the link in the email we sent you to confirm.",
  userprofile_email_change_email_address: "Change Email address",
  userprofile_email_alert_message:
    "If you have changed your email address, we will send you an e-mail notification to the new address confirming this change.",
  userprofile_email_newEmail_hint: "Please enter your new email address.",
  userprofile_email_newEmail_placeholder: "New email address",
  userprofile_email_password_hint: "Please enter your current password.",
  userprofile_email_password_placeholder: "Current password",

  userprofile_personalData_feedback_title: "Feedback",
  userprofile_personalData_feedback_recommendation_subtitle: "How do you know bettervest?",
  userprofile_personalData_feedback_recommendation_none: "No entry",
  userprofile_personalData_feedback_recommendation_internetSearch: "Internet Search Engine",
  userprofile_personalData_feedback_recommendation_friend: "Friend",

  userprofile_notification_alert_message: "Notice",
  userprofile_notification_alert_description: "Through the newsletter we can inform you about projects that are about to start.",
  userprofile_notification_newsletter_title: "Project Launch Newsletter",
  userprofile_notification_newsletter_subtitle: "Do you find news exciting? Then subscribe to our project newsletter.",
  userprofile_notification_newsletter_checkbox: "I would like to be informed about projects via the newsletter (email).",
  userprofile_notification_newsletter_alert:
    "By giving your consent, you agree to receive information about bettervest and about future investment projects. This information is sent by bettervest GmbH, Frankfurt am Main. Your consent is voluntary and can be revoked at any time. The revocation is made by requesting cancellation to the contact address given on the website or by clicking on the unsubscribe link in sent messages.",

  userprofile_password_message_success: "Password changed",
  userprofile_password_message_error: "Something went wrong. Please try again later.",
  userprofile_password_alert_message: "Change Password",
  userprofile_password_alert_description: "We'll send you an email with a link to set your new password.",
  userprofile_password_change_password_button: "Change Password",

  userprofile_personalData_address_title: "Registration Address",
  userprofile_personalData_address_country_label: "Country",
  userprofile_personalData_address_country_hint: "Please enter your country.",
  userprofile_personalData_address_country_placeholder: "Country",
  userprofile_personalData_address_city_label: "City",
  userprofile_personalData_address_city_hint: "Please enter the city.",
  userprofile_personalData_address_city_placeholder: "e.g. Frankfurt am Main",
  userprofile_personalData_address_postalCode_label: "Postal Code",
  userprofile_personalData_address_postalCode_hint: "Please enter the postal code.",
  userprofile_personalData_address_postalCode_placeholder: "e.g. 60487",
  userprofile_personalData_address_streetName_label: "Street",
  userprofile_personalData_address_streetName_hint: "Please enter the street name.",
  userprofile_personalData_address_streetName_placeholder: "e.g. sample street",
  userprofile_personalData_address_streetNumber_label: "Street Number",
  userprofile_personalData_address_streetNumber_hint: "Please enter the street number.",
  userprofile_personalData_address_streetNumber_placeholder: "e.g. 12a",
  userprofile_personalData_address_addition_label: "Addition",
  userprofile_personalData_address_addition_placeholder: "e.g. 4th floor",
  userprofile_personalData_address_address_hint: "Please enter your address.",
  userprofile_personalData_address_address_placeholder: "Street house number, city",
  userprofile_personalData_address_additional_placeholder: "Additional address information",
  userprofile_personalData_address_phoneNumber_label: "Phone Number",
  userprofile_personalData_address_phoneNumber_hint: "Please enter your phone number, e.g. +4915208753100",
  userprofile_personalData_address_phoneNumber_placeholder: "e.g. +4915208753100",
  userprofile_personalData_birth_title: "Birth Dates",
  userprofile_personalData_birth_date_label: "Date of birth",
  userprofile_personalData_birth_date_hint: "Please enter your birth date.",
  userprofile_personalData_birth_date_placeholder: "e.g. 01/01/1990",
  userprofile_personalData_birth_place_label: "Place of birth",
  userprofile_personalData_birth_place_hint: "Please enter your birthplace.",
  userprofile_personalData_birth_place_placeholder: "e.g. Frankfurt am Main",
  userprofile_personalData_birthData_nationality_label: "Nationality",
  userprofile_personalData_birthData_nationality_hint: "Please enter your nationality.",
  userprofile_personalData_birthData_nationality_placeholder: "Germany",
  userprofile_personalData_message_update_success: "Personal data changed",
  userprofile_personalData_message_update_error: "Something went wrong. Please try again later.",
  userprofile_personalData_name_title: "Name",
  userprofile_personalData_name_first_label: "First Name",
  userprofile_personalData_name_first_hint: "Please enter your first name.",
  userprofile_personalData_name_first_placeholder: "e.g. Max",
  userprofile_personalData_name_last_label: "Last Name",
  userprofile_personalData_name_last_hint: "Please enter your last name.",
  userprofile_personalData_name_last_placeholder: "e.g. Mustermann",
  userprofile_personalData_name_gender_label: "Gender",
  userprofile_personalData_name_gender_hint: "Please enter your gender.",
  userprofile_personalData_name_gender_placeholder: "e.g. male",
  userprofile_personalData_name_gender_male: "male",
  userprofile_personalData_name_gender_female: "female",
  userprofile_personalData_name_gender_diverse: "diverse",
  userprofile_personalData_verification_title: "Verification",
  userprofile_personalData_verification_type_label: "ID Type",
  userprofile_personalData_verification_type_hint: "Please enter the type of your ID document.",
  userprofile_personalData_verification_type_id: "Personal ID",
  userprofile_personalData_verification_type_passport: "Passport",
  userprofile_personalData_verification_number_label: "ID Number",
  userprofile_personalData_verification_number_hint_required: "Please enter the number of your document.",
  userprofile_personalData_verification_number_hint_valid: "The number you entered is invalid.",
  userprofile_personalData_verification_number_placeholder: "e.g. LX1234567",
  userprofile_personalData_verification_agency_label: "Issuing Authority",
  userprofile_personalData_verification_agency_hint: "Please provide the issuing authority.",
  userprofile_personalData_verification_agency_placeholder: "e.g. City of Frankfurt Issuing Authority",
  userprofile_personalData_tax_title: "Tax",
  userprofile_personalData_taxId_label: "Tax Identification Number",
  userprofile_personalData_taxId_placeholder: "e.g. 12345678912",
  userprofile_personalData_tax_number_hint_required: "Please enter your Tax Identification Number",

  // legal person
  userprofile_legalPerson_position_label: "Position in the company",
  userprofile_legalPerson_position_placeholder: "Managing Director",
  userprofile_legalPerson_companyname_label: "Company Name",
  userprofile_legalPerson_companyname_placeholder: "e.g. sample company",
  userprofile_legalPerson_legalform_label: "Legal structure",
  userprofile_legalPerson_legalform_placeholder: "e.g. GmbH",
  userprofile_legalPerson_foundingDate_label: "Date founded",
  userprofile_legalPerson_foundingDate_placeholder: "01/01/1999",
  userprofile_legalPerson_address_title: "Business address",
  userprofile_legalPerson_address_label: "Address",
  userprofile_legalPerson_address_placeholder: "e.g. 5 Sample Street, 12345 Sample City",
  userprofile_legalPerson_court_label: "Registrar",
  userprofile_legalPerson_court_placeholder: "e.g. District Court Sample City",
  userprofile_legalPerson_registerId_label: "Commercial Register Entry",
  userprofile_legalPerson_registerId_placeholder: "e.g. HRB 123",
  userprofile_legalPerson_taxId_label: "Tax ID",
  userprofile_legalPerson_taxId_placeholder: "e.g. 1234567890",

  // Account
  userprofile_account_alert_message: "You want to change your bank details?",
  userprofile_account_alert_description: `
     1. Please edit the stored data in the given template.
     <br />
     2. Please also complete <a href="https://api.bettervest.com/files/Aenderung_Stammdaten_Formblatt.pdf" target="_blank" rel="noopener noreferrer"><b> the following form </b> </ a> from our trustee Secupay AG and scan the form, and then send it by email<br>
     directly to: <a href="mailto:info@secupay.com">info@secupay.com</a> or by post to: Secupay AG, Goethestraße 6, 01896 Pulsnitz. <br>
     Secupay will then save the change and use it for future payments.
     <br /> <br />
     <i>
       However, if you have not yet made a transfer to the Secupay escrow account or do not have any account details in your profile
       data, you <b>do not</b> need to fill out this form.
     </i>
   `,
  userprofile_account_change_title: "Bank account"
};

export default en;
