import React from "react";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import Spin from "antd/lib/spin";
import Steps from "antd/lib/steps";
import FormattedMessage from "components/FormattedMessage";
import { NaturalPersonState } from "types/NaturalPerson";
import useStepManager from "../hooks/useStepManager";
import useMyPersonApi from "../hooks/useMyPersonApi";
import StepName from "./Step2Name/StepName";
import StepAddress from "./Step3Address/StepAddress";
import StepBirthData from "./Step4BirthData/StepBirthData";
import StepVerification from "./Step5Verification/StepVerification";
import StepFeedback from "./Step6Feedback/StepFeedback";

export default function MainModal(props: { visible?: boolean; hide?: () => void }): JSX.Element {
  const { visible, hide } = props;
  const { myPerson, loading, refetch } = useMyPersonApi();

  const state = myPerson != null ? myPerson.state : NaturalPersonState.none;
  const naturalPersonId = myPerson != null ? myPerson.id : null;
  const [form] = Form.useForm();
  const { step, setStep, maxAvailableStep, stepObjects, footer } = useStepManager(form, naturalPersonId, state, refetch);

  return (
    <Modal title={<FormattedMessage id="data_completion_modal_title" />} visible={visible} onCancel={hide} footer={footer} width="85%">
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Steps size="small" current={step} style={{ marginBottom: 10 }}>
            {stepObjects.map(({ title, stepId }, index) => {
              if (index > maxAvailableStep || index === step) return <Steps.Step key={stepId} title={title} />;
              return <Steps.Step key={stepId} title={title} onClick={() => setStep(index)} style={{ cursor: "pointer" }} />;
            })}
          </Steps>
          {step === 0 && <StepName form={form} myPerson={myPerson} />}
          {step === 1 && <StepAddress form={form} myPerson={myPerson} />}
          {step === 2 && <StepBirthData form={form} myPerson={myPerson} />}
          {step === 3 && <StepVerification form={form} myPerson={myPerson} />}
          {step === 4 && <StepFeedback form={form} myPerson={myPerson} />}
        </>
      )}
    </Modal>
  );
}
