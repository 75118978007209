import IAddress from "./IAddress";
import IBankAccount from "./IBankAccount";

export default interface NaturalPerson {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  state: NaturalPersonState;

  // Name
  firstName?: string;
  lastName?: string;
  gender?: EGender;

  // Address
  phoneNumberPrefix?: string;
  phoneNumber?: string;
  addrCountryCode?: string;
  addrCountry?: string;
  addrState?: string;
  addrCity?: string;
  addrPostalCode?: string;
  addrStreetName?: string;
  addrStreetNumber?: string;
  addrAddition?: string;
  address?: IAddress;

  // Birthdata
  nationality?: string;
  birthday?: string;
  birthPlaceText?: string;
  birthPlaceCountry?: string;

  // BankAccount
  bankAccount?: IBankAccount;

  // Verification
  verificationDocumentType?: EVerificationDocumentType;
  verificationDocumentNumber?: string;
  verificationDocumentIssuingAgency?: string;

  // Feedback
  recommendation?: string;
  recommendationFreeText?: string;
  subscribedToNewsletter?: boolean;

  // Tax
  taxId?: string;
}

export enum EGender {
  male = "male",
  female = "female",
  diverse = "diverse"
}

export enum EVerificationDocumentType {
  identityCard = "identityCard",
  passport = "passport"
}

export enum ERecommendation {
  financeConsultancy = "financeConsultancy",
  offlineMedia = "offlineMedia",
  onineMedia = "onlineMedia",
  socialMedia = "socialMedia",
  searchEngine = "searchEngine",
  triodos = "triodos",
  events = "events",
  recommendation = "recommendation",
  wdsMail = "wdsMail",
  articlePrint = "articlePrint",
  articleOnline = "articleOnline",
  forgot = "forgot",
  other = "other",
  washeldentunPodcast = "washeldentunPodcast",
  tvReport = "tvReport",
  podcast = "podcast",
  articleFinance = "articleFinance"
}

export enum NaturalPersonState {
  none = "none",
  name = "name",
  address = "address",
  birthData = "birthData",
  verification = "verification",
  feedback = "feedback",
  finished = "finished"
}

export enum ETechnologyType {
  solareBewasserung = "solareBewasserung",
  bhkw = "bhkw",
  biogas = "biogas",
  bricketsAusBiomasse = "bricketsAusBiomasse",
  eMobilitat = "eMobilitat",
  energieEffizienz = "energieEffizienz",
  energieeffizienteKochherde = "energieeffizienteKochherde",
  led = "led",
  solarMinigrids = "solarMinigrids",
  solarHomeSysteme = "solarHomeSysteme",
  solaranlagenZurEigenbedarfsdeckung = "solaranlagenZurEigenbedarfsdeckung",
  solareinspeiseanlagen = "solareinspeiseanlagen",
  wasserkraftanlagen = "wasserkraftanlagen",
  windanlage = "windanlage",
  wasseraufbereitung = "wasseraufbereitung",
  sonstiges = "sonstiges"
}

export enum EContinentType {
  africa = "africa",
  europe = "europe",
  amerika = "america",
  asia = "asia",
  oceania = "oceania"
}

export enum EReduceRiskType {
  spv = "Zweckgesellschaft",
  thirdPartyGuarantee = "Garantie einer dritten Partei",
  pledgeOfTheFinancedGoods = "Verpfändung der finanzierten Ware",
  pledgeOfCO2Certificates = "Verpfändung von CO2 Zertifikaten",
  pledgeOfLandProperty = "Verpfändung von Land / Immobilien",
  partialFailureGuarantee = "Teilausfallgarantie",
  equityShare30 = "Equityanteil mindestens 25%"
}
