import { ThunkAction } from "redux-thunk";
import ActionCallbacks from "types/ActionCallbacks";
import * as generalDocumentApi from "../api/generalDocument";
import DocumentTemplateSet from "../types.ts/DocumentTemplateSet";
import * as actionTypes from "./actionTypes";

export function setLoginModalVisible(visible: boolean) {
  return {
    type: actionTypes.USER_HANDLING_LOGIN_SET_MODAL_VISIBLE,
    payload: visible
  };
}

export function setShowForgotPassword(show: boolean) {
  return {
    type: actionTypes.USER_HANDLING_LOGIN_SET_SHOW_FORGOT_PASSWORD,
    payload: show
  };
}

export function setRegisterModalVisible(visible: boolean) {
  return {
    type: actionTypes.USER_HANDLING_REGISTER_SET_MODAL_VISIBLE,
    payload: visible
  };
}

export function setIsLegalPersonSelected(selected: boolean) {
  return {
    type: actionTypes.USER_HANDLING_REGISTER_SET_IS_LEGAL_PERSON_SELECTED,
    payload: selected
  };
}

function setLoading(loading: boolean) {
  return {
    type: actionTypes.USER_HANDLING_REGISTER_SET_LOADING,
    payload: loading
  };
}

function setGeneralDocumentTemplateSet(generalDocumentTemplateSet: DocumentTemplateSet | null) {
  return {
    type: actionTypes.USER_HANDLING_REGISTER_SET_GENERAL_DOCUMENT_TEMPLATE_SET,
    payload: generalDocumentTemplateSet
  };
}

export function setPrivacyInfoVisible(visible: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_PRIVACY_INFO_VISIBLE,
    payload: visible
  };
}

export function setNewsletterInfoVisible(visible: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_NEWSLETTER_INFO_VISIBLE,
    payload: visible
  };
}

export function setShowSuccessView(show: boolean) {
  return {
    type: actionTypes.USER_HANDLING_REGISTER_SET_SHOW_SUCCESS_VIEW,
    payload: show
  };
}

export function fetchGeneralDocumentTemplateSet(callbacks?: ActionCallbacks): ThunkAction<Promise<void>, any, any, any> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const generalDocumentTemplateSet = await generalDocumentApi.getGeneralDocumentTemplateSet();
      dispatch(setGeneralDocumentTemplateSet(generalDocumentTemplateSet));
      callbacks?.onSuccess?.();
    } catch (error) {
      callbacks?.onError?.(error);
    }
    dispatch(setLoading(false));
  };
}

export function setDataCompletionModalVisible(visible: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_DATA_COMPLETION_MODAL_VISIBLE,
    payload: visible
  };
}

export function setForgotPasswordLoading(loading: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_FORGOT_PASSWORD_LOADING,
    payload: loading
  };
}

export function setLoginLoading(loading: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_LOGIN_LOADING,
    payload: loading
  };
}

export function setLogoutLoading(loading: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_LOGOUT_LOADING,
    payload: loading
  };
}

export function setRegisterLoading(loading: boolean) {
  return {
    type: actionTypes.USER_HANDLING_SET_REGISTER_LOADING,
    payload: loading
  };
}
