import { combineReducers, AnyAction, Reducer } from "redux";
import * as actionTypes from "./actionTypes";
import { State, UserInfo } from "./types";

const loadingReducer: Reducer<boolean> = (state: boolean = false, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.USER_INFO_SET_LOADING: {
      return action.payload;
    }
    default:
      return state;
  }
};

const userInfoReducer: Reducer<UserInfo | undefined> = (state: UserInfo | null = null, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.USER_INFO_SET_USER_INFO:
      return action.payload ?? null;
    default:
      return state;
  }
};

const loadingLoginReducer: Reducer<boolean> = (state: boolean = false, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.USER_INFO_SET_LOADING_LOGIN: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default combineReducers<State>({
  loading: loadingReducer,
  userInfo: userInfoReducer,
  loadingLogin: loadingLoginReducer
});
