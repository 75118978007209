import React, { useState, useContext, useEffect } from "react";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import Form, { FormInstance } from "antd/lib/form";
import Select from "antd/lib/select";
import Title from "antd/lib/typography/Title";
import Checkbox from "antd/lib/checkbox";
import Alert from "antd/lib/alert";
import Input from "antd/lib/input";
import { intlContext } from "translations/context";
import { ERecommendation } from "types/NaturalPerson";
import { IFormValuesFeedback } from "../../types/IFormValues";
import NaturalPerson from "../../types/NaturalPerson";

interface IProps {
  form: FormInstance<IFormValuesFeedback>;
  myPerson: NaturalPerson | undefined;
}

export default function StepFeedback({ form, myPerson }: IProps): JSX.Element {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alreadySubscribedNewsletter, setAlreadySubscribedNewsletter] = useState(false);
  const { translate } = useContext(intlContext);

  const { setFieldsValue } = form;

  useEffect(() => {
    if (myPerson != null) {
      const fields = mapPersonToFields(myPerson);
      if (myPerson.subscribedToNewsletter === true) setAlreadySubscribedNewsletter(true);
      setFieldsValue(fields);
    }
  }, [myPerson, setFieldsValue]);

  return (
    <Form name="DataCompletionMyNaturalPersonFeedback" form={form} labelAlign="left" layout="horizontal" style={{ marginBottom: 0 }}>
      <Title level={3}>{translate("data_completion_feedback_title")}</Title>
      <Title level={4}>{translate("data_completion_feedback_recommendation_subtitle")}</Title>
      <Form.Item name="recommendation" initialValue={undefined} style={{ marginBottom: 6, width: "100%" }}>
        <Select style={{ width: "50%" }} placeholder={translate("data_completion_feedback_recommendation_placeholder")}>
          <Select.Option value={ERecommendation.tvReport}>{translate("data_completion_feedback_recommendation_option_tvReport")}</Select.Option>
          <Select.Option value={ERecommendation.podcast}>{translate("data_completion_feedback_recommendation_option_Podcast")}</Select.Option>
          <Select.Option value={ERecommendation.financeConsultancy}>
            {translate("data_completion_feedback_recommendation_option_financeConsultancy")}
          </Select.Option>
          <Select.Option value={ERecommendation.offlineMedia}>
            {translate("data_completion_feedback_recommendation_option_offlineMedia")}
          </Select.Option>
          <Select.Option value={ERecommendation.onineMedia}>{translate("data_completion_feedback_recommendation_option_onineMedia")}</Select.Option>
          <Select.Option value={ERecommendation.socialMedia}>{translate("data_completion_feedback_recommendation_option_socialMedia")}</Select.Option>
          <Select.Option value={ERecommendation.searchEngine}>
            {translate("data_completion_feedback_recommendation_option_searchEngine")}
          </Select.Option>
          <Select.Option value={ERecommendation.triodos}>{translate("data_completion_feedback_recommendation_option_triodos")}</Select.Option>
          <Select.Option value={ERecommendation.events}>{translate("data_completion_feedback_recommendation_option_events")}</Select.Option>
          <Select.Option value={ERecommendation.recommendation}>
            {translate("data_completion_feedback_recommendation_option_recommendation")}
          </Select.Option>
          <Select.Option value={ERecommendation.articlePrint}>
            {translate("data_completion_feedback_recommendation_option_articlePrint")}
          </Select.Option>
          <Select.Option value={ERecommendation.articleOnline}>
            {translate("data_completion_feedback_recommendation_option_articleOnline")}
          </Select.Option>
          <Select.Option value={ERecommendation.articleFinance}>
            {translate("data_completion_feedback_recommendation_option_articleFinanzen")}
          </Select.Option>
          <Select.Option value={ERecommendation.washeldentunPodcast}>
            {translate("data_completion_feedback_recommendation_option_washeldentunPodcast")}
          </Select.Option>
          <Select.Option value={ERecommendation.other}>{translate("data_completion_feedback_recommendation_option_other")}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="recommendationFreeText" style={{ width: "100%" }}>
        <Input placeholder={translate("data_completion_feedback_recommendation_freeText_placeholder")} />
      </Form.Item>
      {alreadySubscribedNewsletter !== true && (
        <>
          <Title level={4}>{translate("data_completion_feedback_newsletter_subtitle")}</Title>
          <Form.Item name="subscribedToNewsletter" valuePropName="checked">
            <Checkbox>{translate("data_completion_feedback_newsletter_checkbox")}</Checkbox>
            <InfoCircleOutlined onClick={() => setIsAlertVisible(!isAlertVisible)} />
          </Form.Item>
        </>
      )}
      {isAlertVisible && (
        <Alert
          type="info"
          message={translate("data_completion_feedback_newsletter_alert_message")}
          description={translate("data_completion_feedback_newsletter_alert_description")}
          closable
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </Form>
  );
}

function mapPersonToFields(myPerson: NaturalPerson): IFormValuesFeedback {
  return {
    recommendation: myPerson.recommendation || undefined,
    recommendationFreeText: myPerson.recommendationFreeText || "",
    subscribedToNewsletter: myPerson.subscribedToNewsletter || false
  };
}
