import React, { useContext, useEffect, forwardRef, useState } from "react";
import AutoComplete from "antd/lib/auto-complete";
import { intlContext } from "translations/context";

interface ICountryAutoCompleteProps {
  disabled?: boolean;
  excludeSomeCountries?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

function CountryAutoComplete(props: ICountryAutoCompleteProps, ref: any) {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSearchQuery(props.value || "");
  }, [props.value]);

  const { countries, allCountries } = useContext(intlContext);

  return (
    <AutoComplete
      ref={ref}
      style={{ width: "100%" }}
      value={searchQuery}
      onChange={(value) => setSearchQuery(value.toString())}
      onSelect={(event) => props.onChange && props.onChange(event.toString())}
      onBlur={() => setSearchQuery(props.value || "")}
      disabled={props.disabled === true}
      placeholder={props.placeholder || ""}
      filterOption={(inputValue, option) => {
        const upperCaseInputValue = inputValue.toUpperCase();
        const optionValue = (option?.value as string)?.toUpperCase();
        if (optionValue == null) return false;
        return optionValue.startsWith(upperCaseInputValue);
      }}
    >
      {allCountries.map(({ key, value }) => (
        <AutoComplete.Option
          key={key}
          value={key}
          disabled={props.excludeSomeCountries === true && countries.find((country) => country.key === key) == null}
        >
          {value}
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
}

export default forwardRef(CountryAutoComplete);
