import React, { lazy, Suspense } from "react";
import * as ReactDOM from "react-dom";
import { IModuleStore } from "redux-dynamic-modules-core";
import gql from "graphql-tag";
import Spin from "antd/lib/spin";
import LegalPersonState from "types/LegalPersonState";
import { NaturalPersonState } from "types/NaturalPerson";
import { apolloClient } from "utils";
import "./App.css";
import * as appNames from "./appNames";
import State from "./models/State";
import store from "./models/store";

// tslint:disable:variable-name
const AffiliateDashboardApp = lazy(() => import("./affiliate-dashboard/App"));
const ConfirmEmailApp = lazy(() => import("./confirm-email/App"));
const MyInvestmentsApp = lazy(() => import("./my-investments/App"));
const InvestmentApp = lazy(() => import("./investment/App"));
const InvestorDashboardApp = lazy(() => import("./investor-dashboard/App"));
const ProjectDocumentsApp = lazy(() => import("./project-documents/App"));
const ProjectFundedListApp = lazy(() => import("./project-funded-list/App"));
const ProjectListApp = lazy(() => import("./project-list/App"));
const ProjectOwnerDashboardApp = lazy(() => import("./project-owner-dashboard/App"));
const ProjectPageHeaderApp = lazy(() => import("./project-page-header/App"));
const ProjectPageV2App = lazy(() => import("./project-page-v2/App"));
const ProjectSideBarApp = lazy(() => import("./project-side-bar/App"));
const ProjectUpdatesApp = lazy(() => import("./project-updates/App"));
const SetPasswordApp = lazy(() => import("./set-password/App"));
const UserHandlingApp = lazy(() => import("./user-handling/App"));
const UserProfileApp = lazy(() => import("./user-profile/App"));
const UsersCanvassUsersApp = lazy(() => import("./users-canvass-users/App"));
const WaivePenaltyInterestApp = lazy(() => import("./waive-penalty-interest/App"));
const ProjectListOverview = lazy(() => import("./project-list-overview/App"));
const EigencrowdApp = lazy(() => import("./eigencrowd/App"));
const MyCompanyApp = lazy(() => import("./my-company/App"));
const ReminderDashboardApp = lazy(() => import("../pages/reminder-dashboard"));
const NewsletterSubscription = lazy(() => import("./newsletter-subscription/App"));
const PresseNewsletterSubscription = lazy(() => import("./presse-newsletter-subscription/App"));
const WebsiteHomeInfo = lazy(() => import("./website-home-info/App"));
const RvoProjectListApp = lazy(() => import("./rvo-project-list/App"));
const WebsiteHomeInfoImpact = lazy(() => import("./website-home-info-impact/App"));
const WebsiteHomeInfoGraphs = lazy(() => import("./website-home-info-graphs/App"));
const WebsiteHomeInfoRiskmitigation = lazy(() => import("./website-home-info-riskmitigation/App"));

const appsAndContainers: Array<{ App: React.LazyExoticComponent<(props: object) => JSX.Element>; className: string }> = [
  { App: AffiliateDashboardApp, className: appNames.affiliateDashboardApp },
  { App: ConfirmEmailApp, className: appNames.confirmEmailApp },
  { App: MyInvestmentsApp, className: appNames.myInvestmentsApp },
  { App: InvestmentApp, className: appNames.investmentApp },
  { App: InvestorDashboardApp, className: appNames.investorDashboardApp },
  { App: ProjectDocumentsApp, className: appNames.projectDocumentsApp },
  { App: ProjectFundedListApp, className: appNames.projectFundedListApp },
  { App: ProjectListApp, className: appNames.projectListApp },
  { App: ProjectOwnerDashboardApp, className: appNames.projectOwnerDashboardApp },
  { App: ProjectPageHeaderApp, className: appNames.projectPageHeaderApp },
  { App: ProjectPageV2App, className: appNames.projectPageV2App },
  { App: ProjectSideBarApp, className: appNames.projectSideBarApp },
  { App: ProjectUpdatesApp, className: appNames.projectUpdatesApp },
  { App: SetPasswordApp, className: appNames.setPasswordApp },
  { App: UserHandlingApp, className: appNames.userHandlingApp },
  { App: UserProfileApp, className: appNames.userProfileApp },
  { App: UsersCanvassUsersApp, className: appNames.usersCanvassUsersApp },
  { App: WaivePenaltyInterestApp, className: appNames.waivePenaltyInterestApp },
  { App: ProjectListOverview, className: appNames.projectListOverview },
  { App: EigencrowdApp, className: appNames.eigencrowdApp },
  { App: MyCompanyApp, className: appNames.myCompanyApp },
  { App: ReminderDashboardApp, className: appNames.reminderDashboardApp },
  { App: NewsletterSubscription, className: appNames.newsletterSubscription },
  { App: PresseNewsletterSubscription, className: appNames.presseNewsletterSubscription },
  { App: WebsiteHomeInfo, className: appNames.websiteHomeInfo },
  { App: RvoProjectListApp, className: appNames.rvoProjectListApp },
  { App: WebsiteHomeInfoImpact, className: appNames.websiteHomeInfoImpact },
  { App: WebsiteHomeInfoGraphs, className: appNames.websiteHomeInfoGraphs },
  { App: WebsiteHomeInfoRiskmitigation, className: appNames.websiteHomeInfoRiskmitigation }
];

export default async function renderAll(): Promise<void> {
  const userInfo: UserInfo | null = await getUserInfo();
  appsAndContainers.forEach(({ App, className }) => render(App, className, userInfo));
}

function render(
  App: React.LazyExoticComponent<(props: { userInfo?: UserInfo | null; store: IModuleStore<State> }) => JSX.Element>,
  className: string,
  userInfo: UserInfo | null
): void {
  const containerCollection = document.getElementsByClassName(className);
  // tslint:disable-next-line:prefer-for-of
  for (let index = 0; index < containerCollection.length; index += 1) {
    const container = containerCollection[index];
    try {
      if (container.localName === "div") {
        ReactDOM.render(
          <Suspense
            fallback={
              <div>
                <Spin />
              </div>
            }
          >
            <App userInfo={userInfo} store={store} />
          </Suspense>,
          container
        );
      }
    } catch (error) {
      console.error({ msg: "Failed to render App ${}", container, error });
    }
  }
}

export interface UserInfo {
  currentUser: User;
  principalUsers: User[];
}

export interface User {
  id: string;
  email?: string;
  name?: string;
  legalPerson?: LegalPerson | null;
  naturalPerson?: NaturalPerson | null;
}

export interface LegalPerson {
  id: string;
  state: LegalPersonState;
  name?: string | null;
}

export interface NaturalPerson {
  id: string;
  state: NaturalPersonState;
  firstName?: string | null;
  lastName?: string | null;
}

async function getUserInfo(): Promise<UserInfo | null> {
  try {
    const response = await apolloClient.query({ query: QUERY });
    if (response.errors != null || response.data == null || response.data.users == null || response.data.users[0] == null) return null;
    const gqlUser = response.data.users[0];
    const currentUser: User = {
      id: gqlUser.id,
      email: gqlUser.email,
      name: gqlUser.name,
      legalPerson: gqlUser.legalPerson,
      naturalPerson: gqlUser.naturalPerson
    };
    const principalUsers: User[] = gqlUser.agentAuthorizedRepresents.map(({ principalUser: gqlPrincipalUser }) => ({
      id: gqlPrincipalUser.id,
      legalPerson: gqlPrincipalUser.legalPerson,
      naturalPerson: gqlPrincipalUser.naturalPerson
    }));
    return { currentUser, principalUsers };
  } catch (error) {
    return null;
  }
}

const QUERY = gql`
  query {
    users(where: { _Owner_id: { is: TRUE } }) {
      id
      email
      name
      naturalPerson {
        id
        state
        firstName
        lastName
      }
      legalPerson {
        id
        state
        name
      }
      agentAuthorizedRepresents {
        principalUser {
          id
          naturalPerson {
            id
            state
            firstName
            lastName
          }
          legalPerson {
            id
            state
            name
          }
        }
      }
    }
  }
`;
