import { AnyAction, Reducer } from "redux";
import * as actionTypes from "./actionTypes";
import { State } from "./types";

const initialState: State = {
  loginModalVisible: false,
  showForgotPassword: false,
  registerModalVisible: false,
  isLegalPersonSelected: false,
  generalDocumentsLoading: false,
  generalDocumentTemplateSet: null,
  privacyInfoVisible: false,
  newsletterInfoVisible: false,
  registerShowSuccessView: false,
  dataCompletionModalVisible: false,
  forgotPasswordLoading: false,
  loginLoading: false,
  logoutLoading: false,
  registerLoading: false
};

const reducer: Reducer<State> = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.USER_HANDLING_LOGIN_SET_MODAL_VISIBLE: {
      return { ...state, loginModalVisible: action.payload };
    }
    case actionTypes.USER_HANDLING_LOGIN_SET_SHOW_FORGOT_PASSWORD: {
      return { ...state, showForgotPassword: action.payload };
    }
    case actionTypes.USER_HANDLING_REGISTER_SET_MODAL_VISIBLE: {
      return { ...state, registerModalVisible: action.payload };
    }
    case actionTypes.USER_HANDLING_REGISTER_SET_IS_LEGAL_PERSON_SELECTED: {
      return { ...state, isLegalPersonSelected: action.payload };
    }
    case actionTypes.USER_HANDLING_REGISTER_SET_LOADING: {
      return { ...state, generalDocumentsLoading: action.payload };
    }
    case actionTypes.USER_HANDLING_REGISTER_SET_GENERAL_DOCUMENT_TEMPLATE_SET: {
      return { ...state, generalDocumentTemplateSet: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_PRIVACY_INFO_VISIBLE: {
      return { ...state, privacyInfoVisible: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_NEWSLETTER_INFO_VISIBLE: {
      return { ...state, newsletterInfoVisible: action.payload };
    }
    case actionTypes.USER_HANDLING_REGISTER_SET_SHOW_SUCCESS_VIEW: {
      return { ...state, registerShowSuccessView: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_DATA_COMPLETION_MODAL_VISIBLE: {
      return { ...state, dataCompletionModalVisible: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_FORGOT_PASSWORD_LOADING: {
      return { ...state, forgotPasswordLoading: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_LOGIN_LOADING: {
      return { ...state, loginLoading: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_LOGOUT_LOADING: {
      return { ...state, logoutLoading: action.payload };
    }
    case actionTypes.USER_HANDLING_SET_REGISTER_LOADING: {
      return { ...state, registerLoading: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
