import ITexts from "./ITexts";

const de: ITexts = {
  userhandling_login: "Anmelden",
  userhandling_forgotPassword: "Passwort vergessen",
  userhandling_register: "Registrieren",
  userhandling_register_title: "Registrieren",
  userhandling_natural_person_not_found: "Wir konnten Ihren Benutzer leider nicht finden.",
  userhandling_hello: "Hallo",
  userhandling_data_completion_button: "Bitte vervollständigen Sie Ihr Profil",
  userhandling_logout: "Abmelden",
  userhandling_logout_failed: "Abmelden ist schief gelaufen.",
  userhandling_login_failed: "Etwas ist schief gelaufen.",
  userhandling_login_wrong_credentials: "Falsche E-Mail-Adresse oder falsches Passwort",
  userhandling_password_placeholder: "Passwort",
  userhandling_forgotPassword_reset: "Passwort zurücksetzen",
  userhandling_firstName: "Vorname",
  userhandling_firstName_placeholder: "Max",
  userhandling_lastName: "Nachname",
  userhandling_lastName_placeholder: "Mustermann",
  userhandling_data_completion_email_confirmation: "Bitte klicken Sie auf den Bestätigungslink in der E-Mail, die wir Ihnen soeben gesandt haben",

  // Login
  userhandling_already_logged_in: "Sie sind bereits angemeldet.",
  userhandling_login_security_alert_message: "Hinweis",
  userhandling_login_security_alert_description:
    "Bitte beachten Sie dass bei der Eingabe einer Email Adresse, welche keinem Konto bei uns zugewiesen ist, aus Sicherheits- und Datenschutzgründen keine Fehlermeldung ausgegeben wird.",

  // Register Modal
  userhandling_registerAs: "Registrieren als",
  userhandling_registerAs_naturalPerson: "Privatperson",
  userhandling_registerAs_legalPerson: "Unternehmen",
  userhandling_registerAs_legalPerson_alert_message: "Hinweis",
  userhandling_registerAs_legalPerson_alert_description:
    "Sie wollen sich als Unternehmen bei bettervest registrieren? Dann schreiben Sie uns eine Email an",
  userhandling_register_legalPerson_alert_mail_subject: "Als Unternehmen bei bettervest.com registrieren",
  userhandling_register_message_success: "Wir haben Ihnen eine E-Mail zur Bestätigung geschickt.",
  userhandling_register_message_error: "Etwas ist schief gelaufen. Eventuell existiert bereits ein Konto mit dieser E-Mail-Adresse?",
  userhandling_register_almostDone_title: "Fast geschafft!",
  userhandling_register_almostDone_step1: "Auf Bestätigungslink in der Email klicken, die wir Ihnen soeben gesandt haben.",
  userhandling_register_almostDone_step2: "Anschließend können Sie Ihr Passwort festlegen.",
  userhandling_email: "E-Mail",
  userhandling_email_hint_valid: "Bitte geben Sie eine gültige E-Mail-Adresse an.",
  userhandling_email_hint_required: "Bitte geben Sie Ihre E-Mail-Adresse an.",
  userhandling_email_placeholder: "E-Mail",
  userhandling_appropriationHint: `Ich habe die <a target="_blank" rel="noopener noreferrer" href="https://api.bettervest.com/files/agbs.pdf#page=10">statusbezogenen Informationen</a> und die <a href="https://api.bettervest.com/files/agbs.pdf#page=11" rel="nofollow" target="_blank">Informationen über Zuwendungen</a> gelesen und ausgedruckt und/oder gespeichert.`,
  userhandling_generalBusinessTermsHint: `Die <a target="_blank" rel="noopener noreferrer" href="https://api.bettervest.com/files/agbs.pdf">Allgemeinen Geschäftsbedingungen</a> habe ich gelesen und stimme ihnen zu.`,
  userhandling_dataProtectionHint: `Ich habe die <a target="_blank" rel="noopener noreferrer" href="https://api.bettervest.com/files/datenschutz.pdf">Datenschutzhinweise</a> gelesen und willige in die Datenverarbeitung und -speicherung durch die bettervest GmbH ein.`,
  userhandling_register_dataprotection_alert_message: "Hinweis",
  userhandling_register_dataprotection_alert_description: `
    Mit der Einwilligung erklären Sie sich damit einverstanden, dass die bettervest GmbH, Frankfurt am Main die von Ihnen im Rahmen der Registrierung eingegebenen Daten zum Zweck der Durchführung der Registrierung und Möglichkeit, in Projekte zu investieren, speichert und verarbeitet.
    Ihre E-Mail-Adresse und, wenn vorhanden, Telefonnummer werden als Kontaktweg für Informationen und Rückfragen zum Bearbeitungsstatus genutzt.
    Bitte beachten Sie auch, dass die Kommunikation per E-Mail potenziell unsicher sein kann.
  `,
  userhandling_register_newsletter: "Ich möchte per Newsletter (E-Mail) über Projekte und Neuigkeiten informiert werden.",
  userhandling_register_company_info: "Sie möchten sich als Unternehmen registrieren?",
  userhandling_register_newsletter_alert_message: "Hinweis",
  userhandling_register_newsletter_alert_description:
    "Mit der Einwilligung erklären Sie sich damit einverstanden, Informationen über bettervest und über zukünftige Investitionsprojekte zu erhalten. Der Versand dieser Informationen erfolgt durch die bettervest GmbH, Frankfurt am Main. Die Einwilligung ist freiwillig und kann jederzeit widerrufen werden. Der Widerruf erfolgt durch einen Abbestellwunsch an die auf der Website genannte Kontaktadresse oder durch einen Klick auf den Abbestelllink in den zugesandten Nachrichten.",
  userhandling_register_company_alert_description:
    "Wenn Sie im Namen eines Unternehmens investieren wollen, legen Sie bitte zuerst einen Account als Privatperson an. Registrieren Sie sich dafür bitte mit Ihrer persönlichen E-Mailadresse. Sie können dann über den Privataccount einen Unternehmensaccount hinzufügen: Unter MEIN BETTERVEST -> Mein Unternehmen",
  userhandling_register_required_field: "Pflichtfeld",
  userhandling_documents_intro: "Ich habe folgende Dokumente gelesen und ausgedruckt und/oder gespeichert und stimme ihnen zu",
  userhandling_documents_error_message: "Fehler",
  userhandling_documents_error_description: "Dokumente konnten nicht geladen werden. Bitte laden Sie die Seite neu.",
  userhandling_documents_hint: "Pflichtfeld",
  userhandling_register_confirm_link: "Bitte klicken Sie auf den Bestätigungslink in der E-Mail, die wir Ihnen soeben gesandt haben",

  // My Bettervest Menu
  userhandling_menu_title: "MEIN BETTERVEST",
  userhandling_menu_my_profile: "Mein Profil",
  userhandling_menu_my_investments: "Meine Investitionen",
  userhandling_menu_my_benefits: "Meine Vorteile",
  userhandling_menu_my_projects: "Meine Projekte",
  userhandling_menu_my_company: "Mein Unternehmen",

  userhandling_proofEmail_success: "Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse.",
  userhandling_proofEmail_error: "Etwas ist schief gelaufen. Bitte klicken Sie später erneut auf den Link zur Bestätigung.",

  userhandling_menu_password_requirements: "Mindestlänge: 8 Zeichen",
  userhandling_menu_password_requirements_title: "Passworanforderungen",

  userhandling_menu_documents_requirements_1: "Ich habe die",
  userhandling_menu_documents_requirements_2: "zur Kenntnis genommen"
};

export default de;
