import ITexts from "./ITexts";

const de: ITexts = {
  affiliate_dashboard_userhandling_hello: "Hallo",
  affiliate_dashboard_error: "Fehler",
  affiliate_dashboard_error_description: `
    Es ist ein Fehler aufgetreten.
    Bitte kontaktieren Sie Ihren Ansprechpartner bei bettervest oder
    unseren Support unter <a href="mailto:mail@bettervest.com">mail@bettervest.com</a> mit folgender Fehlermeldung:
  `,
  affiliate_dashboard_currently_not_available: "Momentan noch nicht verfügbar",

  // MainView
  affiliate_dashboard_no_projects: "Sie haben zur Zeit keine Affiliate-Verträge.",
  affiliate_dashboard_project: "Affiliate-Vertrag",
  affiliate_dashboard_select_project: "Affiliate-Vertrag auswählen",

  // Overview
  affiliate_dashboard_overview_title: "Dokumentieren",
  affiliate_dashboard_data_title: "Daten",

  // Repayment
  affiliate_dashboard_repayment_title: "Rückzahlungen / Tilgungsplan",
  affiliate_dashboard_repayment_planned_repayments: "Tilgungsplan",
  affiliate_dashboard_repayment_actual_repayments: "Rückzahlungen",
  affiliate_dashboard_repayment_period: "Periode",
  affiliate_dashboard_repayment_repayment: "Tilgung",
  affiliate_dashboard_repayment_interest: "Zinsen",
  affiliate_dashboard_repayment_penaltyInterest: "Verzugszinsen",
  affiliate_dashboard_repayment_planned_date: "Geplantes Datum",
  affiliate_dashboard_repayment_actual_date: "Datum",
  affiliate_dashboard_repayment_residual_loan: "Restschuld",

  // Investments
  affiliate_dashboard_investment_title: "Investitionen",

  // Updates
  affiliate_dashboard_update_title: "Updates"
};

export default de;
