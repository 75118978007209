const en = {
  co2_saving: `TONS CO<sub>2</sub> SAVED`,
  project_number: "financed Projects",
  funding_volume: "Funding-volume",
  project_newJos: "New Jobs",
  project_housesEletrified: "Houses Eletrified",
  project_investors: "Investors",
  project_interest_average: "Interest average",
  project_countries: "Countries",
  project_repaid_percent: "Repaid Amount"
};

export default en;
