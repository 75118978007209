import ITexts from "./ITexts";

const de: ITexts = {
  // Project List Main
  project_list_startpage_loading: "Aktuelle Projekte werden geladen.",
  project_list_startpage_alert_message: "Hinweis gemäß § 12 Abs. 2 Vermögensanlagengesetz:",
  project_list_startpage_alert_description:
    "Der Erwerb dieser Vermögensanlage ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.",
  project_list_startpage_alert_message_secu: "Hinweis gemäß §4 Wertpapierprospektgesetz:",
  project_list_startpage_alert_description_secu:
    "Der Erwerb dieser Wertpapiere ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen. ",
  project_list_startpage_subordinatedLoan: "Nachrangdarlehen",
  project_list_startpage_securedLoan: "Anleihe",
  project_list_startpage_secu_deposit: "Depot notwendig",
  project_list_startpage_secu_over_25k: "Investitionen über 25.000 € möglich",
  project_list_startpage_secu_addition_securities: "zusätzliche Sicherheiten",
  project_list_startpage_sub_no_deposit: "kein Depot notwendig",
  project_list_startpage_sub_up_to_25k: "Investitionen bis 25.000 € möglich",
  project_list_startpage_sub_high_revenue: "hohe Rendite-Chancen",
  project_list_startpage_genussrecht: "Genussrecht",

  // Project Item Badge
  project_list_startpage_badge_soon: "Bald",

  // Project Item Conditions
  project_list_startpage_condition_interest: "Rendite p.a.",
  project_list_startpage_condition_duration: "Laufzeit",
  project_list_startpage_condition_initialInterest: "Vorlaufzins",

  // Progress Bar
  project_list_startpage_progress_bar_requiredBudget: "Zielbetrag",
  project_list_startpage_progress_bar_requiredMinBudget: "Fundingschwelle",
  project_list_startpage_progress_bar_by: "von",
  project_list_startpage_progress_bar_investor: "Investor",
  project_list_startpage_progress_bar_investors: "Investoren",

  // Project Time
  project_list_startpage_timer_is_completed: "ist beendet",
  project_list_startpage_timer_ends_in: "endet in",
  project_list_startpage_timer_day: "Tag",
  project_list_startpage_timer_days: "Tagen",
  project_list_startpage_timer_and: "und",
  project_list_startpage_timer_hour: "Stunde",
  project_list_startpage_timer_hours: "Stunden",

  // Project List Overview
  project_list_startpage_securedLoan_overview: "Anleihe"
};

export default de;
