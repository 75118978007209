import ITexts from "./ITexts";

const de: ITexts = {
  project_funded_list_loading: "Projekte werden geladen...",
  project_funded_list_empty: "Wir konnten keine vermittelten Projekte laden.",
  project_funded_list_completed: "Finanziert",
  project_funded_list_paid: "Getilgt",
  project_funded_list_insolvencyProceedings: "Ausfall",
  project_funded_list_co2savings: "t CO₂ Einsparung",
  project_funded_list_year: "Jahr",
  project_funded_list_years: "Jahre",
  project_funded_list_contractDuration: "Laufzeit",
  project_funded_list_interestRate: "Rendite p.a.",
  project_funded_list_goto: "Zum Projekt"
};

export default de;
