// needs to be executed once
export default function trackFirstParty() {
  console.log({ msg: "AdCell Track First Party" });
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://t.adcell.com/js/trad.js";

  document.body.appendChild(script);

  setTimeout(() => {
    (window as any)?.Adcell?.Tracking?.track();
  }, 1000);
}
