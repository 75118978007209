import gql from "graphql-tag";
import { apolloClient } from "utils";

const mutation = gql`
  mutation LOGIN($tenantId: String!, $email: String!, $password: String!) {
    beginTransaction
    getUserIdentifier(username: $email, tenant: $tenantId, returnId: "$userIdentifier")
    proofPassword(userIdentifier: "$userIdentifier", password: $password, returnId: "$passwordProofToken")
    login(authFactorProofTokens: "$passwordProofToken") {
      refreshToken
      accessToken
      tokenMeta {
        userId
        issuedAt
        expiresAt
        providerSet
      }
    }
    commitTransaction
  }
`;

export interface ILoginInput {
  tenantId: string;
  email: string;
  password: string;
}

export default async function login(variables: ILoginInput): Promise<boolean> {
  const response = await apolloClient.mutate({
    mutation,
    variables: {
      ...variables,
      email: variables.email.toLowerCase()
    }
  });
  if (response.errors) throw response.errors;
  return response.data.login !== null;
}
