import ITexts from "./ITexts";

const de: ITexts = {
  // General
  invest_wizzard_general_error_message: "Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.",
  invest_wizzard_general_register_now: "JETZT REGISTRIEREN",
  invest_wizzard_general_complete_data_first: "Bitte vervollständigen Sie zunächst Ihr Profil",
  invest_wizzard_general_invest_now: "JETZT INVESTIEREN",
  invest_wizzard_general_continue_investing: "INVESTITION FORTSETZEN",
  invest_wizzard_general_last_edited: "Zuletzt bearbeitet am",
  invest_wizzard_general_last_edited_at: "um",
  invest_wizzard_general_last_edited_clock: "Uhr",

  // Card Title
  invest_wizzard_card_title_investor_information: "Investoreninformation",
  invest_wizzard_card_title_contract: "Vertrag",
  invest_wizzard_card_title_investor_survey: "Angemessenheitsprüfung",
  invest_wizzard_card_title_finalization: "Abschluss",

  // Errors
  invest_wizzard_errors_project_not_found: "Projekt wurde nicht gefunden.",
  invest_wizzard_errors_emission_not_open: "Emission wurde nicht gefunden.",
  invest_wizzard_errors_project_not_investable: "Zur Zeit kann in dieses Projekt nicht investiert werden.",
  invest_wizzard_errors_user_has_no_person: "Benutzerdaten sind fehlerhaft.",
  invest_wizzard_errors_person_data_is_unfinished: "Benutzerdaten sind unvollständig.",
  invest_wizzard_errors_natural_person_is_not_old_enough: "Investitionen sind erst ab einem Alter von 18 Jahren möglich.",
  invest_wizzard_errors_email_is_not_proven: "Bitte bestätigen Sie zuerst Ihre E-Mail-Adresse.",
  invest_wizzard_errors_failed_to_create_a_prepayment_transaction:
    "Wir können leider secupay zur Zeit nicht erreichen. Bitte versuchen Sie es später erneut.",
  invest_wizzard_errors_amount_does_not_fit_in: "Der von Ihnen gewählte Investitionsbetrag ist leider für dieses Projekt nicht mehr verfügbar.",
  invest_wizzard_errors_other: "Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.",

  // Step2 Investor Information
  invest_wizzard_step2_alert_message: "Wir haben Ihnen eine E-Mail mit Investoreninformationen zugesandt.",
  invest_wizzard_step2_alert_description: `
    Bitte lesen Sie diese Informationen sorgfältig und in Ruhe durch und setzen anschließend den Investitionsprozess fort. <br />
    Wir empfehlen diese Informationen an einem sicheren Ort zu speichern, an dem Sie sie später problemlos wiederfinden. <br />
    Durch Klick auf „Weiter zum nächsten Schritt“ bestätigen Sie, dass Sie sich eingehend mit diesen Informationen auseinandersetzen werden, bevor
    Sie eine verbindliche Zeichnung abgeben. <br />
  `,
  invest_wizzard_step2_continue: "WEITER ZUM NÄCHSTEN SCHRITT",

  // Step3 Contract
  invest_wizzard_step3_investment_not_found_error_alert_message: "Fehler",
  invest_wizzard_step3_investment_not_found_error_alert_description: "Die Investition konnte nicht geladen werden. Bitte laden Sie die Seite neu.",
  invest_wizzard_step3_already_invested_1: "Sie haben dieses Jahr bereits",
  invest_wizzard_step3_already_invested_2: "in Projekte dieses Emittenten investiert. Sie können noch maximal",
  invest_wizzard_step3_already_invested_3: "investieren.",
  invest_wizzard_step3_available_assets_intro:
    "Da Sie insgesamt mehr als EUR 1.000 in Vermögensanlagen dieses Emittenten investieren möchten, sind wir gesetzlich verpflichtet, von Ihnen die folgende Bestätigung einzuholen:",
  invest_wizzard_step3_available_assets_hint: "Dieser Hinweis muss bestätigt werden.",
  invest_wizzard_step3_available_assets_text_1: `
    Hiermit bestätige ich, dass ich ENTWEDER über ein <b>frei verfügbares Vermögen</b> in Form von Bankguthaben und Finanzinstrumenten von
    mindestens <b>100.000 Euro</b> verfüge, ODER dass der Gesamtbetrag, den ich in Vermögensanlagen dieses Emittenten investieren möchte,
    den zweifachen Betrag meines durchschnittlichen <b>monatlichen Nettoeinkommens</b>
  `,
  invest_wizzard_step3_available_assets_text_over10000_1: `
    Hiermit bestätige ich, dass der Gesamtbetrag, den ich in Vermögensanlagen dieses Emittenten investieren möchte,
    den zweifachen Betrag meines durchschnittlichen <b>monatlichen Nettoeinkommens</b>
  `,
  invest_wizzard_step3_available_assets_text_2: "nicht übersteigt.",
  invest_wizzard_step3_alert_message: "Hinweis gemäß § 12 Abs. 2 Vermögensanlagengesetz:",
  invest_wizzard_step3_alert_description:
    "Der Erwerb dieser Vermögensanlage ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.",
  invest_wizzard_step3_document_intro: "Ich habe folgende Dokumente gelesen und ausgedruckt und/oder gespeichert und stimme ihnen zu",
  invest_wizzard_step3_document_hint: "Dies muss bestätigt werden",
  invest_wizzard_step3_data_entry_intro_1:
    "Durch die Eingabe der folgenden persönlichen Daten bestätige ich, dass ich den Warnhinweis auf der ersten Seite des Vermögensanlagen-Informationsblatts (oben eingeblendet) zur Kenntnis genommen habe.",
  invest_wizzard_step3_data_entry_intro_2: `
    Dieser Prozess und das Ausmaß der Daten, die wir dabei abfragen müssen, sowie eine <b>eigenständige Eingabe</b> der Profildaten sind <b>gesetzlich vorgegeben</b>.
  `,
  invest_wizzard_step3_data_from_profile_load: "DATEN AUS DEM PROFIL LADEN",
  invest_wizzard_step3_data_from_profile: "** Daten aus dem Profil",
  invest_wizzard_step3_data_from_profile_hide: "AUSBLENDEN",
  invest_wizzard_step3_legal_person_part_title: "Daten in Bezug auf die Gesellschaft",
  invest_wizzard_step3_agent_part_title: "Daten in Bezug auf den Vertretungsberechtigten",
  invest_wizzard_step3_companyname_placeholder: "Name der Gesellschaft",
  invest_wizzard_step3_companyname_hint: "Name der Gesellschaft stimmt nicht überein.",
  invest_wizzard_step3_foundingdate_placeholder: "Datum der Gründung",
  invest_wizzard_step3_foundingdate_hint: "Datum der Gründung stimmt nicht überein.",
  invest_wizzard_step3_registerid_placeholder: "Registernummer",
  invest_wizzard_step3_registerid_hint: "Registernummer stimmt nicht überein.",
  invest_wizzard_step3_court_placeholder: "Register",
  invest_wizzard_step3_court_hint: "Register stimmt nicht überein.",
  invest_wizzard_step3_address_street_name_placeholder: "Straße",
  invest_wizzard_step3_address_street_name_hint: "Straße stimmt nicht überein.",
  invest_wizzard_step3_address_street_number_placeholder: "Nr.",
  invest_wizzard_step3_address_street_number_hint: "Hausnummer stimmt nicht überein.",
  invest_wizzard_step3_address_postal_code_placeholder: "PLZ",
  invest_wizzard_step3_address_postal_code_hint: "Postleitzahl stimmt nicht überein.",
  invest_wizzard_step3_address_city_placeholder: "Ort",
  invest_wizzard_step3_address_city_hint: "Ort stimmt nicht überein.",
  invest_wizzard_step3_companyName_placeholder: "Name der Gesellschaft",
  invest_wizzard_step3_companyName_hint: "Name der Gesellschaft stimmt nicht überein.",
  invest_wizzard_step3_email_placeholder: "E-Mail",
  invest_wizzard_step3_email_hint: "E-Mail-Adresse stimmt nicht überein.",
  invest_wizzard_step3_birthplace_placeholder: "Geburtsort",
  invest_wizzard_step3_birthplace_hint: "Geburtsort stimmt nicht überein.",
  invest_wizzard_step3_birthday_placeholder: "Geburtsdatum",
  invest_wizzard_step3_birthday_hint: "Geburtstag stimmt nicht überein.",
  invest_wizzard_step3_verification_document_number_placeholder: "Nummer des Personalausweises oder Reisepass",
  invest_wizzard_step3_verification_document_number_hint: "Nummer des Personalausweises oder Reisepass stimmt nicht überein.",
  invest_wizzard_step3_verification_issuing_agency_placeholder: "Ausstellende Behörde",
  invest_wizzard_step3_verification_issuing_agency_hint: "Ausstellende Behörde stimmt nicht überein.",
  invest_wizzard_step3_please_sign_here: "Bitte hier unterschreiben",
  invest_wizzard_step3_first_name_placeholder: "Vorname",
  invest_wizzard_step3_first_name_hint: "Vorname stimmt nicht überein.",
  invest_wizzard_step3_last_name_placeholder: "Familienname",
  invest_wizzard_step3_last_name_hint: "Familienname stimmt nicht überein.",
  invest_wizzard_step3_place_placeholder: "Ort",
  invest_wizzard_step3_place_hint: "Aktueller Ort ist notwendig.",
  invest_wizzard_step3_date_placeholder: "Datum",
  invest_wizzard_step3_date_hint: "Bitte das aktuelle Datum zum Unterschreiben eintragen.",
  invest_wizzard_step3_continue: "ZUM NÄCHSTEN SCHRITT",
  invest_wizzard_step3_address_alert: "Geben Sie Ihre Adresse bitte genau so an, wie in Ihrem Profil hinterlegt",

  // Step4 Investor Survey
  invest_wizzard_step4_continue: "ZUM NÄCHSTEN SCHRITT",
  invest_wizzard_step4_intro: `
  Als Vermittler von Vermögensanlagen sind wir <b>gesetzlich verpflichtet</b>, Informationen über Ihre Erfahrungen und Kenntnisse in
  Bezug auf Geschäfte mit bestimmten Arten von Finanzanlagen und über Ihre Ausbildung und beruflichen Tätigkeiten einzuholen. Die
  Erteilung der nachfolgenden Angaben ist für Sie <b>freiwillig</b> und liegt in Ihrem eigenen Interesse.
  `,
  invest_wizzard_step4_appropriate_hint: "Vielen Dank für Ihre Angaben.",
  invest_wizzard_step4_appropriateness_not_assessable_hint: `
    Wir sind <b>gesetzlich verpflichtet</b>, Sie darauf hinzuweisen, dass uns <b>keine ausreichenden Informationen</b> vorliegen, um
    beurteilen zu können, ob Sie über ausreichende Kenntnisse und Erfahrungen verfügen, um die
    <b>Risiken des Geschäfts angemessen beurteilen zu können</b>.
  `,
  invest_wizzard_step4_appropriateness_not_assessable_checkbox: "Hinweis zur Kenntnis genommen.",
  invest_wizzard_step4_appropriateness_not_assessable_checkbox_hint: "Hinweis muss akzeptiert werden, um fortzufahren.",
  invest_wizzard_step4_inappropriate_hint: `
    Wir sind <b>gesetzlich verpflichtet</b>, Sie darauf hinzuweisen, dass Sie auf Grundlage der von Ihnen mitgeteilten Kenntnisse und
    Erfahrungen das beabsichtigte Geschäft nicht angemessen beurteilen können.
  `,
  invest_wizzard_step4_inappropriate_checkbox: "Hinweis zur Kenntnis genommen.",
  invest_wizzard_step4_inappropriate_checkbox_hint: "Hinweis muss akzeptiert werden, um fortzufahren.",
  invest_wizzard_step4_title: "Ausbildung und berufliche Erfahrung",
  invest_wizzard_step4_current_occupation: "Gegenwärtiger Beruf",
  invest_wizzard_step4_current_other_occupation_palceholder: "Gegenwärtiger Beruf",
  invest_wizzard_step4_previous_occupation: "Relevanter früherer Beruf",
  invest_wizzard_step4_occupation_other: "- anderer Beruf -",
  invest_wizzard_step4_occupation_asset_manager: "Asset Manager",
  invest_wizzard_step4_occupation_bank_employee: "Bankangestellter",
  invest_wizzard_step4_occupation_finance_consultant: "Finanzberater",
  invest_wizzard_step4_occupation_finance_broker: "Finanzmakler",
  invest_wizzard_step4_occupation_finance_intermediary: "Finanzvermittler",
  invest_wizzard_step4_occupation_investment_banker: "Investment Banker",
  invest_wizzard_step4_occupation_head_of_controlling: "Leitende Controllingfunktion",
  invest_wizzard_step4_occupation_head_of_finance: "Leitende Funktion Finanzabteilung",
  invest_wizzard_step4_occupation_tax_accountant: "Steuerberater",
  invest_wizzard_step4_educational_degree: "Höchster Bildungsabschluss",
  invest_wizzard_step4_educational_degree_not_assigned: "- bitte auswählen -",
  invest_wizzard_step4_educational_degree_none: "Ich habe (noch) keinen Abschluss",
  invest_wizzard_step4_educational_degree_abitur: "Abitur",
  invest_wizzard_step4_educational_degree_university: "Hochschulabschluss",
  invest_wizzard_step4_educational_degree_doctor: "Doktor",
  invest_wizzard_step4_subordinatedloan: "Nachrangdarlehen",
  invest_wizzard_step4_openfundsandetf: "Offene Fonds und ETFs",
  invest_wizzard_step4_bond: "Anleihen",
  invest_wizzard_step4_share: "Aktien",
  invest_wizzard_step4_closedfundsandparticipationright: "Geschlossene Fonds und Genussrechte",
  invest_wizzard_step4_equity: "Stille Beteiligungen und echtes Eigenkapital",
  invest_wizzard_step4_warrants: "Optionen und Optionsscheine",
  invest_wizzard_step4_experience_period: "Optionen und Optionsscheine",
  invest_wizzard_step4_experience_period_up_to_three_years: "bis zu 3 Jahre",
  invest_wizzard_step4_experience_period_more_than_three_years: "mehr als 3 Jahre",
  invest_wizzard_step4_experience_frequency_one_to_five_per_year: "1-5 pro Jahr",
  invest_wizzard_step4_experience_frequency_six_to_fifteen_per_year: "6-15 pro Jahr",
  invest_wizzard_step4_experience_frequency_more_than_fifteen_per_year: "> 15 pro Jahr",
  invest_wizzard_step4_experience_size_less_than_five_thousand: "< 5.000 €",
  invest_wizzard_step4_experience_size_five_to_ten_thousand: "5.000 € - 10.000 €",
  invest_wizzard_step4_experience_size_more_than_ten_thousand: "> 10.000 €",
  invest_wizzard_step4_experience_type_independent: "eigenständig",
  invest_wizzard_step4_experience_type_investment_consultancy: "mit Anlageberatung",
  invest_wizzard_step4_experience_type_investment_management: "durch Vermögensverwaltung",

  // Step5 Finalization
  invest_wizzard_step5_title: "Übersicht",
  invest_wizzard_step5_amount: "Investitionsbetrag",
  invest_wizzard_step5_quote: "Zitat",
  invest_wizzard_step5_quote_placeholder: "Persönliche Nachricht eingeben ...",
  invest_wizzard_step5_quote_publish: "Zitat öffentlich posten",
  invest_wizzard_step5_quote_anonymously: "Anonym posten",
  invest_wizzard_step5_invest_now_bindingly: "JETZT ZAHLUNGSPFLICHTIG INVESTIEREN",
  invest_wizzard_step5_amount_does_not_fit_in_message: "Hinweis",
  invest_wizzard_step5_amount_does_not_fit_in_description:
    "Der von Ihnen gewählte Investitionsbetrag ist leider für dieses Projekt nicht mehr verfügbar.",

  // Step6 Transfer Infos
  invest_wizzard_step6_generic_error_alert: "Etwas ist schiefgelaufen",
  invest_wizzard_step6_success_message: "Sie haben die Investition erfolgreich abgeschlossen.",
  invest_wizzard_step6_intro: "Bitte nehmen Sie folgende Überweisung vor",
  invest_wizzard_step6_owner: "Kontoinhaber",
  invest_wizzard_step6_iban: "IBAN",
  invest_wizzard_step6_bic: "BIC",
  invest_wizzard_step6_bankname: "Bank",
  invest_wizzard_step6_purpose: "Verwendungszweck",
  invest_wizzard_step6_amount: "Betrag",
  invest_wizzard_step6_outro_sent_everything_by_mail: "Wir haben Ihnen alles auch nochmal per E-Mail zugeschickt."
};

export default de;
