import React, { useContext, CSSProperties } from "react";
import { intlContext, htmlToReactParser } from "../translations/context";
import ITranslationCollection from "../translations/ITranslationCollection";

interface IProps {
  id: keyof ITranslationCollection;
  parseHtml?: boolean;
  style?: CSSProperties;
}

export default function FormattedMessage({ id, parseHtml, style }: IProps): JSX.Element {
  const { translate } = useContext(intlContext);
  const localizedText = translate(id);
  const text = parseHtml === true ? htmlToReactParser.parse(localizedText) : localizedText;
  return <span style={style}>{text}</span>;
}
