import ITexts from "./ITexts";

const de: ITexts = {
  setPassword_title: "bettervest",
  setPassword_subtitle: "Legen Sie Ihr Passwort fest.",
  setPassword_currentPassword_hint: "Bitte tragen Sie hier Ihr aktuelles Passwort ein.",
  setPassword_currentPassword_placeholder: "Aktuelles Passwort",
  setPassword_newPassword_hint: "Bitte tragen Sie hier Ihr neues Passwort ein.",
  setPassword_newPassword_placeholder: "Neues Passwort",
  setPassword_choosePassword_hint: "Bitte wähle Sie Ihr Passwort.",
  setPassword_choosePassword_placeholder: "Passwort",
  setPassword_repeatPassword_hint: "Bitte wiederholen Sie Ihr Passwort.",
  setPassword_repeatPassword_placeholder: "Passwort Wiederholung",
  setPassword_passwordsRequireToMatch_hint: "Die Passwörter müssen übereinstimmen.",
  setPassword_min_length_eight_chars_hint: "Mindestlänge 8 Zeichen",

  setPassword_success: "Ihr neues Passwort wurde gespeichert",
  setPassword_error: "Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut."
};

export default de;
