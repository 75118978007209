import { programmId, eventIdInvest, eventIdRegister } from "constants/tracking";
import Decimal from "decimal.js";
import Cookies from "js-cookie";
import { getQueryStringValue } from "./storeBidInCookies";

export enum TrackingEvent {
  invest = "invest",
  register = "register"
}

// needs to be executed on each conversion
export default function trackConversion(type: TrackingEvent, reference: string, amountInCt?: number) {
  const bid = Cookies.get("bid") ?? getQueryStringValue("bid") ?? null;
  console.log({ msg: "AdCell Track Event", type, reference, bid, amountInCt });

  if (bid == null || typeof bid !== "string") {
    console.log(`Error: bid is neither found in the cookies nor in the url query. No tracking will be conducted.`);
    return;
  }

  const eventId = type === TrackingEvent.invest ? eventIdInvest : type === TrackingEvent.register ? eventIdRegister : null;

  if (eventId == null) {
    console.log(`Error: TrackingEvent "${type}" is unkown.`);
    return;
  }

  if (reference == null || typeof reference !== "string") {
    console.log(`Error: Reference must be of type string "${reference}".`);
    return;
  }

  if (type === TrackingEvent.invest && (amountInCt == null || typeof amountInCt !== "number")) {
    console.log(`Error: AmountInCt must be of type number "${amountInCt}" for the invest event.`);
    return;
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    type === TrackingEvent.register
      ? `https://t.adcell.com/t/track.js?pid=${programmId}&eventid=${eventId}&referenz=${reference}&bid=${bid}`
      : `https://t.adcell.com/t/track.js?pid=${programmId}&eventid=${eventId}&referenz=${reference}&betrag=${new Decimal(amountInCt ?? 0)
          .dividedBy(100)
          .toFixed(2)}&bid=${bid}`;
  script.async = true;
  document.body.appendChild(script);

  const noscript = document.createElement("noscript");
  const img = document.createElement("img");
  img.border = "0";
  img.width = 1;
  img.height = 1;
  img.src =
    type === TrackingEvent.register
      ? `https://t.adcell.com/t/track?pid=${programmId}&eventid=${eventId}&referenz=${reference}&bid=${bid}`
      : `https://t.adcell.com/t/track?pid=${programmId}&eventid=${eventId}&referenz=${reference}&betrag=${new Decimal(amountInCt ?? 0)
          .dividedBy(100)
          .toFixed(2)}&bid=${bid}`;
  noscript.appendChild(img);

  document.body.appendChild(script);
  document.body.appendChild(noscript);
}
