import ITexts from "./ITexts";

const de: ITexts = {
  // Description
  project_side_bar_characteristics: "Merkmale",
  project_side_bar_requiredBudget: "Fundingziel",
  project_side_bar_company_value: "Bewertung",
  project_side_bar_contractDuration: "Laufzeit",
  project_side_bar_securedLoan_duration: "Ursprungslaufzeit",
  project_side_bar_securedLoan_dueDate: "Fälligkeit",
  project_side_bar_interest: "Zinssatz p.a. bis zu",
  project_side_bar_earlyBirdBonus: "Early-Bird-Bonus p.a.",
  project_side_bar_initialInterest: "Vorlaufzins p.a.",
  project_side_bar_gracePeriods: "Tilgungsfrei",
  project_side_bar_year: "Jahr",
  project_side_bar_years: "Jahre",
  project_side_bar_genussrecht_description: "Beteiligung"
};

export default de;
