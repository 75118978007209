import ITexts from "./ITexts";

const de: ITexts = {
  // Main View
  my_investments_main_open_data_completion_button: "Bitte vervollständigen Sie zunächst Ihr Profil",
  my_investments_main_not_logged_in_alert_message: "Bitte einloggen / anmelden",
  my_investments_error_occurred_alert_message: "Fehler",
  my_investments_error_occurred_alert_description: `Es konnte leider keine Antwort vom Server empfangen werden. Bitte schreiben Sie eine E-Mail an <a href="mailto:mail@bettervest.com?subject=Fehler beim Laden meiner Investitionen">mail@bettervest.com</a>.`,

  // Earnings Report
  my_investments_earnings_report_title: "Erträgnisaufstellung",
  my_investments_earnings_report_title_2:
    "Hier können Sie Ihre Steuerbescheinigungen für 2023 herunterladen, welche von unserem Zahlungsdienstleister Secupay zur Verfügung gestellt wurden. Bitte beachten Sie, dass Secupay den Steuerabzug erst ab März 2023 durchgeführt hat: ",
  my_investments_earnings_report_alert_message:
    "Die Erträgnisaufstellung wird automatisch am 01.01. des Folgejahres der ersten Auszahlung hier zum Download als PDF zur Verfügung gestellt.",
  my_investments_earnings_report_alert_message2:
    "Aufgrund des 2023 erstmalig durchgeführten Steuerabzug durch unseren Treuhänder Secupay, überarbeiten wir gerade die Darstellung der Erträgnisaufstellung. Die bisher zum Download bereitgestellte Erträgnisaufstellung 2023 ist nicht mehr verfügbar, da sie den Steuerabzug nicht korrekt widergespiegelt hat.",
  my_investments_earnings_report_alert_message3:
    "Seit 2023 ist unser Zahlungsdienstleister Secupay AG bei Zinszahlungen verpflichtet, vor Auszahlung an die Crowd-Investor*innen Steuern abzuführen. Sie erhalten am Ende des ersten   Quartal des Folgejahres eine Steuerbescheinigung über die abgeführten Steuern von Secupay.",
  my_investments_earnings_report_title_sub: "Es liegen aktuell keine Erträgnisaufstellungen vor",
  // Statistics Row
  my_investments_statistics_title: "Überblick",
  my_investments_statistics_count: "Anzahl Investitionen",
  my_investments_statistics_investmentSum: "Investitionssumme",
  my_investments_statistics_avgAmount: "Durchschnittlicher Betrag",
  my_investments_statistics_interestSum: "Ausgeschüttete Zinsen",
  my_investments_statistics_residualLoan: "Restdarlehen",
  my_investments_statistics_title_2: "Rückzahlungen",
  my_investments_statistics_title_info: "Diese Zahlen sind ohne Gewähr.",
  my_investments_statistics_investment_sum: "Investitionssumme gesamt",
  my_investments_statistics_investment_headline1: "Vollständig getilgte Projekte",
  my_investments_statistics_investment_headline2: "Projekte ohne Verzug (Verzug weniger als 90 Tage)",
  my_investments_statistics_investment_headline3: "Projekte in Verzug",
  my_investments_statistics_investment_headline4: "Insolvente Projekte",
  my_investments_statistics_principal_repaid: "Erhaltene Tilgung",
  my_investments_statistics_interest_repaid: "Erhaltene Zinsen **",
  my_investments_statistics_interest_total: "Zinsen gesamt über Laufzeit *",
  my_investments_statistics_principal_open: "Offener Darlehensbetrag",
  my_investments_statistics_losts: "Verlust",
  my_investments_statistics_row_alert_1: "* inkl. erhaltene Zinsen",
  my_investments_statistics_row_alert_2: "** inklusive Verzugszinsen",

  // Investment Table
  my_investments_investment_table_title: "Liste der Investitionen",
  my_investments_investment_table_options_current: "Laufende Investitionen",
  my_investments_investment_table_options_repaid: "Getilgte Investitionen",
  my_investments_investment_table_options_cancelled: "Stornierte Investitionen",
  my_investments_investment_table_options_insolvent: "Insolvenzen",
  my_investments_investment_table_options_all: "Alle Investitionen",
  my_investments_investment_table_column_date_title: "Datum",
  my_investments_investment_table_column_projectName_title: "Projekt",
  my_investments_investment_table_column_duration_title: "Laufzeit",
  my_investments_investment_table_column_interestRate_title: "Rendite regulär",
  my_investments_investment_table_column_initialInterestRate_title: "Vorlaufzins",
  my_investments_investment_table_column_amount_title: "Betrag",
  my_investments_investment_table_column_action_title: "Aktion",
  my_investments_investment_table_column_action_details: "Details",
  my_investments_investment_table_column_action_repayments: "Rückzahlungen",
  my_investments_investment_table_column_action_project_updates: "Umsetzung",
  my_investments_investment_table_options_current_info: "Es kann bis zu 8 Tage dauern, bis eine Auszahlung angezeigt wird",
  my_investments_investment_table_options_current_description2:
    "Dies hängt damit zusammen, dass wir zunächst die Rückläufer aus den Überweisungen unseres Zahlungsdienstleisters abwarten müssen.",

  // Investment Detail
  my_investments_investment_detail_title: "Projektinhaber",
  my_investments_investment_detail_email_link: "E-Mail an den Projektinhaber",
  my_investments_investment_detail_email_subject: "Fragen zum Projekt bei bettervest",
  my_investments_investment_detail_project: "Projekt",
  my_investments_investment_detail_timeline_title: "Status der Investition",
  my_investments_investment_detail_timeline_submitted: "Einreichung der Investition",
  my_investments_investment_detail_timeline_paid: "Eingang der Zahlung beim Treuhänder",
  my_investments_investment_detail_timeline_revocationFree: "Widerrufsfrist abgelaufen",
  my_investments_investment_detail_timeline_active: "Projektstart / Start der Verzinsung zum",
  my_investments_investment_detail_timeline_active_secured: "Depoteinbuchung",
  my_investments_investment_detail_timeline_cancelled: "Investition storniert",
  my_investments_investment_detail_timeline_repaid: "Darlehen getilgt",
  my_investments_investment_detail_timeline_insolvent: "Projekt insolvent",
  my_investments_investment_detail_payin_title: "Zahlungsinformationen",
  my_investments_investment_detail_payin_owner: "Inhaber",
  my_investments_investment_detail_payin_iban: "IBAN",
  my_investments_investment_detail_payin_bic: "BIC",
  my_investments_investment_detail_payin_bankname: "Bank",
  my_investments_investment_detail_payin_amount: "Betrag",
  my_investments_investment_detail_payin_purpose: "Verwendungszweck",
  my_investments_investment_detail_waivePenaltyInterest_title: "Verzicht auf Verzugszins",
  my_investments_investment_detail_waivePenaltyInterest_text: "Ich verzichte auf alle künftigen Verzugszinsen",
  my_investments_investment_detail_waivePenaltyInterest_optOut_1: `
    Durch die Rücknahme des Verzichts auf Verzugszinsen erhalten Sie bei zukünftigen Rückzahlungen für das Projekt "`,
  my_investments_investment_detail_waivePenaltyInterest_optOut_2: `" wieder Verzugszinsen,
    die für den vollen Verzugszeitraum berechnet werden. Die Rücknahme des Verzichts auf Verzugszinsen hat keine Auswirkungen auf zurückliegende Zahlungen.
    <br />
    <br />
    Wenn Sie die Rücknahme des Verzichts auf zukünftige Verzugszinsen bestätigen möchten, klicken Sie bitte auf „Bestätigen“.
    Sie erhalten im Anschluss eine E-Mail von bettervest mit einem Bestätigungsschreiben.
    <br />
    <br />
    Sie können dem Verzicht auf Verzugszinsen jederzeit auf <a href="https://www.bettervest.com/de/meine-investitionen/" rel="nofollow" target="_blank">https://www.bettervest.com/de/meine-investitionen/</a> im Bereich „Details“ der entsprechenden Investition erneut zustimmen.
    Der Verzicht wirkt sich ebenso nur auf Zahlungen aus, die zeitlich nach der Zustimmung zum Verzicht liegen.
  `,
  my_investments_investment_detail_waivePenaltyInterest_optOut_toast: "Wir haben Ihnen eine E-Mail zur Bestätigung zugeschickt.",
  my_investments_investment_detail_waivePenaltyInterest_optIn_1: `
    Wenn Sie dem Verzicht auf Verzugszinsen zustimmen, erhalten Sie bei zukünftigen Rückzahlungen für das Projekt "`,
  my_investments_investment_detail_waivePenaltyInterest_optIn_2: `" keine Verzugszinsen.
    Der Verzicht auf Verzugszinsen hat keine Auswirkungen auf zurückliegende Zahlungen.
    <br />
    <br />
    Wenn Sie dem Verzicht auf zukünftige Verzugszinsen zustimmen möchten, klicken Sie bitte auf „Bestätigen“.
    Sie erhalten im Anschluss eine E-Mail von bettervest mit einem Bestätigungsschreiben.
    <br />
    <br />
    Sie können den Verzicht jederzeit auf <a href="https://www.bettervest.com/de/meine-investitionen/" rel="nofollow" target="_blank">https://www.bettervest.com/de/meine-investitionen/</a> im Bereich „Details“ der entsprechenden Investition zurücknehmen.
    Die Rücknahme wirkt sich ebenso nur auf Zahlungen aus, die zeitlich nach der Rücknahme liegen.
  `,
  my_investments_investment_detail_waivePenaltyInterest_optIn_toast: "Wir haben Ihnen eine E-Mail zur Bestätigung zugeschickt.",
  my_investments_investment_detail_reportings_title: "Reportings",
  my_investments_investment_detail_documents_title: "Dokumente",
  my_investments_investment_detail_links_title: "Links",
  my_investments_investment_detail_links_to_project_page: "Zur Projektseite",
  my_investments_investment_detail_send_as_gift_1: "Dieses Investment wurde an ",
  my_investments_investment_detail_send_as_gift_2: " verschenkt",

  // Investment Repayments
  my_investments_investment_repayments_title: "Rückzahlungen",
  my_investments_investment_repayments_secured:
    "Die Bedingungen der anstehenden Zins- und Tilgungszahlungen entnehmen Sie bitte ihrem Wertpapierkaufvertrag. Da die Verwahrung und Verwaltung Ihrer Wertpapiere über ein Wertpapierdepot erfolgt, werden die tatsächlichen Zahlungsdaten hier nicht dargestellt. Bei Rückfragen kontaktieren Sie bitte den Emittenten oder die depotführende Bank.",
  my_investments_investment_repayments_empty_message: "Die Rückzahlungstabelle steht spätestens zum Projektstart zur Verfügung.",
  my_investments_investment_repayments_can_take_eight_days: "Es kann bis zu 8 Tage dauern, bis eine Auszahlung angezeigt wird.",
  my_investments_investment_repayments_column_period_title: "Periode",
  my_investments_investment_repayments_column_date_title: "Auszahlungsdatum",
  my_investments_investment_repayments_column_repayment_title: "Tilgung",
  my_investments_investment_repayments_column_interest_title: "Zinsen",
  my_investments_investment_repayments_column_taxKEST_title: "Quellensteuer",
  my_investments_investment_repayments_column_taxCAPI_title: "Kapitalertragssteuer",
  my_investments_investment_repayments_column_taxSOLI_title: "Solidaritätszuschlag",
  my_investments_investment_repayments_column_taxKIST_title: "Kirchensteuer",
  my_investments_investment_repayments_column_amount_title: "Auszahlung",
  my_investments_investment_repayments_column_residualLoan_title: "Restdarlehen",
  my_investments_investment_repayments_subject: "Betreff",
  my_investments_investment_repayments_others_irregular_payment: "Sonderzahlung",
  my_investments_investment_repayments_others_partial_payment: "Teilausschüttung",
  my_investments_investment_repayments_others_planned_for: "geplant war",
  my_investments_investment_repayments_others_delayed: "im Verzug",
  my_investments_investment_repayments_others_info_via_mail: "Info per E-Mail",
  my_investments_investment_repayments_tax_deduction:
    "Seit 2023 ist unser Zahlungsdienstleister Secupay AG bei Zinszahlungen verpflichtet, vor Auszahlung an die Crowd-Investor*innen Steuern abzuführen. Sie erhalten am Ende des Jahres eine Steuerbescheinigung über die abgeführten Steuern von Secupay.",

  // Project Updates
  my_investments_investment_project_updates_title: "Umsetzung"
};

export default de;
