import Cookies from "js-cookie";

export default function storeBidInCookies() {
  const bid = getQueryStringValue("bid");
  if (bid == null) return;
  Cookies.set("bid", bid);
  console.log({ msg: "AdCell set bid cookie", bid });
}

export function getQueryStringValue(key: string): string | undefined {
  return decodeURIComponent(
    // eslint-disable-next-line
    window.location.search.replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&")}(?:\\=([^&]*))?)?.*$`, "i"), "$1")
  );
}
