import React, { useContext, useEffect } from "react";
import moment from "moment";
import Form, { FormInstance } from "antd/lib/form";
import Input from "antd/lib/input";
import Title from "antd/lib/typography/Title";
import { intlContext } from "translations/context";
import NaturalPerson from "../../types/NaturalPerson";
import { IFormValuesBirthData } from "../../types/IFormValues";
import { ColProps } from "antd/lib/col";
import DateTextEdit from "components/edits/DateTextEdit";

interface IProps {
  form: FormInstance<IFormValuesBirthData>;
  myPerson: NaturalPerson | undefined;
}

const cols: { labelCol: ColProps; wrapperCol: ColProps } = {
  labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 }, xxl: { span: 4 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 12 }, xxl: { span: 10 } }
};

export default function StepBirthData({ form, myPerson }: IProps): JSX.Element {
  const { translate } = useContext(intlContext);

  const { setFieldsValue } = form;

  useEffect(() => {
    if (myPerson != null) {
      const fields = mapPersonToFields(myPerson);
      setFieldsValue(fields);
    }
  }, [myPerson, setFieldsValue]);

  return (
    <Form name="DataCompletionMyNaturalPersonBirthData" form={form} labelAlign="left" layout="horizontal" style={{ marginBottom: 0 }}>
      <Title level={3}>{translate("data_completion_birth_title")}</Title>
      <Form.Item
        name="birthday"
        rules={[
          { required: true, message: translate("data_completion_birth_date_hint") },
          {
            validator: (rule, value: moment.Moment | undefined, callback) => {
              if (value == null) return callback();
              if (value.isAfter(moment().subtract(18, "years"))) return callback("age");
              callback();
            },
            message: "Sie müssen mindestens 18 Jahre alt sein."
          }
        ]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_birth_date_label")}
        {...cols}
      >
        <DateTextEdit placeholder={`${translate("invest_wizzard_step3_date_placeholder")}*`} />
      </Form.Item>
      <Form.Item
        name="birthPlaceText"
        rules={[{ required: true, message: translate("data_completion_birth_place_hint"), whitespace: true }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_birth_place_label")}
        {...cols}
      >
        <Input placeholder={translate("data_completion_birth_place_placeholder")} />
      </Form.Item>
      <Form.Item
        name="birthPlaceCountry"
        rules={[{ required: true, message: translate("data_completion_birth_place_country_hint"), whitespace: true }]}
        style={{ marginBottom: 6 }}
        label={translate("data_completion_birth_place_country_label")}
        {...cols}
      >
        <Input placeholder={translate("data_completion_birth_place_country_placeholder")} />
      </Form.Item>
    </Form>
  );
}

function mapPersonToFields(myPerson: NaturalPerson): IFormValuesBirthData {
  const { birthday } = myPerson;
  return {
    birthday: birthday != null ? moment(birthday) : null,
    birthPlaceText: myPerson.birthPlaceText,
    birthPlaceCountry: myPerson.birthPlaceCountry
  };
}
