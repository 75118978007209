// import ITexts from "./ITexts";

const en = {
  userhandling_login: "Log in",
  userhandling_forgotPassword: "Forgot password",
  userhandling_register: "Register",
  userhandling_register_title: "Registration",
  userhandling_natural_person_not_found: "Sorry, we could not find your user",
  userhandling_hello: "Hello",
  userhandling_data_completion_button: "Please complete your profile",
  userhandling_logout: "Logout",
  userhandling_logout_failed: "Logout failed",
  userhandling_login_failed: "Sign in went wrong.",
  userhandling_login_wrong_credentials: "Wrong Credentials",
  userhandling_password_placeholder: "Password",
  userhandling_forgotPassword_reset: "Reset password",
  userhandling_firstName: "First name",
  userhandling_firstName_placeholder: "Eric",
  userhandling_lastName: "Last name",
  userhandling_lastName_placeholder: "Exampleman",
  userhandling_menu_my_company: "My company",

  // Register Modal
  userhandling_registerAs: "Register as",
  userhandling_registerAs_naturalPerson: "Private Person",
  userhandling_registerAs_legalPerson: "Company",
  userhandling_registerAs_legalPerson_alert_message: "userhandling_registerAs_legalPerson_alert_message",
  userhandling_registerAs_legalPerson_alert_description: "userhandling_registerAs_legalPerson_alert_description",
  userhandling_register_legalPerson_alert_mail_subject: "userhandling_register_legalPerson_alert_mail_subject",
  userhandling_register_message_success: "We have sent you a confirmation email",
  userhandling_register_message_error: "Something went wrong. Maybe an account with this email address already exists?",
  userhandling_register_almostDone_title: "userhandling_register_almostDone_title",
  userhandling_register_almostDone_step1: "userhandling_register_almostDone_step1",
  userhandling_register_almostDone_step2: "userhandling_register_almostDone_step2",
  userhandling_email: "E-Mail",
  userhandling_email_hint_valid: "userhandling_email_hint_valid",
  userhandling_email_hint_required: "userhandling_email_hint_required",
  userhandling_email_placeholder: "E-Mail",
  userhandling_appropriationHint: "userhandling_appropriationHint",
  userhandling_generalBusinessTermsHint: "userhandling_generalBusinessTermsHint",
  userhandling_dataProtectionHint: `I have read the <a target="_blank" rel="noopener noreferrer" href="https://api.bettervest.com/files/datenschutz.pdf">Data Protection Hints</a> and agree to the processing of my data by bettervest GmbH.`,
  userhandling_register_newsletter: "I would like to be informed about projects and latest news via the newsletter",
  userhandling_register_newsletter_alert_message: "Notice",
  userhandling_register_newsletter_alert_description:
    "With the consent you agree to receive information about bettervest and about future investment projects. This information is sent by bettervest GmbH, Frankfurt am Main. Consent is voluntary and can be revoked at any time. The revocation takes place by sending a request to unsubscribe to the contact address given on the website or by clicking on the unsubscribe link in sent messages.",
  userhandling_register_required_field: "required field",
  userhandling_documents_hint: "required",

  // My Bettervest Menu
  userhandling_menu_title: "MY BETTERVEST",
  userhandling_menu_my_profile: "My profile",
  userhandling_menu_my_investments: "My investments",
  userhandling_menu_my_benefits: "My benefits",
  userhandling_menu_my_projects: "My projects",

  userhandling_register_confirm_link: "Please click on the confirmation link in the email we just sent you",

  userhandling_proofEmail_success: "Thank you for verifying your email address",
  userhandling_proofEmail_error: "Something went wrong. Please try to confirm your email address later again.",
  userhandling_register_company_info: "You would like to register as a company?",
  userhandling_documents_intro: "I have read the following documents and either printed or saved them and can confirm",
  userhandling_register_company_alert_description:
    "If you want to invest on behalf of a company, please create an account as a private person first. Please register with your personal e-mail address. You can then add a company account via the private account: Under MY BETTERVEST -> My company",

  userhandling_menu_password_requirements: "Minimum: 8 characters",
  userhandling_menu_password_requirements_title: "Password requirements",

  userhandling_menu_documents_requirements_1: "I have read and understood the",
  userhandling_menu_documents_requirements_2: "",

  userhandling_register_dataprotection_alert_message: "Notice",
  userhandling_register_dataprotection_alert_description: `
  With your consent, you agree that bettervest GmbH, Frankfurt am Main, stores and processes the data you entered during registration for the purpose of carrying out the registration and the opportunity to invest in projects.
  Your e-mail address and, if available, telephone number will be used as a contact channel for information and queries about the processing status.
  Please also note that communication via email can be potentially insecure.`
};

export default en;
