import ITexts from "./ITexts";

const en: ITexts = {
  affiliate_dashboard_userhandling_hello: "Hello",
  affiliate_dashboard_error: "Error",
  affiliate_dashboard_error_description: `
    There has been an error.
    Please get in touch with your contact person at bettervest or
    our support at <a href="mailto:mail@bettervest.com">mail@bettervest.com</a> with the following error message:
  `,
  affiliate_dashboard_currently_not_available: "Currently not available",

  // MainView
  affiliate_dashboard_no_projects: "You currently have no affiliate contracts.",
  affiliate_dashboard_project: "Affiliate Agreement",
  affiliate_dashboard_select_project: "Select Affiliate Agreement",

  // Overview
  affiliate_dashboard_overview_title: "Document",
  affiliate_dashboard_data_title: "Data",

  // repayment
  affiliate_dashboard_repayment_title: "Repayments / Repayment Schedule",
  affiliate_dashboard_repayment_planned_repayments: "Repayment Schedule",
  affiliate_dashboard_repayment_actual_repayments: "Repayments",
  affiliate_dashboard_repayment_period: "Period",
  affiliate_dashboard_repayment_repayment: "Repayment",
  affiliate_dashboard_repayment_interest: "Interest",
  affiliate_dashboard_repayment_penaltyInterest: "Interest on arrears",
  affiliate_dashboard_repayment_planned_date: "Planned date",
  affiliate_dashboard_repayment_actual_date: "Date",
  affiliate_dashboard_repayment_residual_loan: "Residual Loan",

  // investments
  affiliate_dashboard_investment_title: "Investments",

  // updates
  affiliate_dashboard_update_title: "Updates"
};

export default en;
